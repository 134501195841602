import { FC, ReactNode } from 'react';
import { useDraggable } from '@dnd-kit/core';

import styles from './drag-item.module.css';

interface DragItemProps {
  fromSide?: number,
  templateId: string,
  templateName: string,
  name: string,
  sideIndex: number,
  children: ReactNode
}

const DragItem: FC<DragItemProps> = ({ fromSide, templateId, templateName, name, sideIndex, children }) => {
  const { attributes, listeners, setNodeRef: dragRef, isDragging } = useDraggable({
    id: `${templateId}_${sideIndex}`,
    data: { fromSide, templateId, sideIndex, templateName, name }
  });
  const style = {
    opacity: isDragging ? 0 : 1
  };

  return (
    <div className={styles.wrapper}>
      <div
        className={styles.children}
        style={style}
        {...listeners}
        {...attributes}
        ref={dragRef}
      >
        {!isDragging && children}
      </div>
    </div>
  );
};

export default DragItem;
