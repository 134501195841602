import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, Modal } from '@forma/forma-ui-kit';
import classNames from 'classnames';
import { GridItem } from 'components/ItemsGrid';

import { ISharedTemplatesItem } from 'interfaces/sharedTemplates.interface';

import styles from './static-templates-grid-item.module.css';

interface StaticTemplatesGridItemProps extends ISharedTemplatesItem {
  searchValue?: string,
  onClickAdd: (id: string) => Promise<string|null>
}

const StaticTemplatesGridItem: FC<StaticTemplatesGridItemProps> = ({ id, name, previews, searchValue, onClickAdd }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [ addedName, setAddedName ] = useState<string|null>(null);
  const [ isOver, setIsOver ] = useState<boolean>(false);

  const handleClickAdd = async () => {
    if (addedName) return;
    setIsOver(false);
    const result = await onClickAdd(id);
    setAddedName(result);
  };

  const handleClickUse = async () => {
    setIsOver(false);
    if (!addedName) {
      const result = await onClickAdd(id);
      navigate(`/fill-document/${result}`);
    } else {
      navigate(`/fill-document/${addedName}`);
    }
  };

  const searchIndex = searchValue ? name.toLowerCase().indexOf(searchValue.toLowerCase()) : -1;
  const endIndex = searchValue ? searchIndex + searchValue.length : -1;

  return (
    <GridItem className={styles.root}>
      <div
        className={classNames(styles.container, isOver && styles.hover)}
        onMouseEnter={() => setIsOver(true)}
        onMouseLeave={() => setIsOver(false)}
      >
        <Button
          viewStyle="tertiary"
          size="extraSmall"
          className={classNames(styles.addButton, addedName && styles.active)}
          onClick={handleClickAdd}
          fullWidth
        >
          {addedName ? t('added_to_my_templates') : t('add_to_my_templates')}
        </Button>
        <div className={styles.imageContainer}>
          {previews?.length ? (
            <img className={styles.image} src={previews[0].url} alt={name} />
          ) : (
            <img className={styles.noImage} src="/icons/no-image.svg" width={64} height={64} alt="" />
          )}
          <div className={styles.buttons}>
            <Button
              viewStyle="primary"
              className={styles.imageButton}
              onClick={handleClickUse}
              fullWidth
            >
              {t('use_template')}
            </Button>
            <Modal
              control={
                <Button
                  viewStyle="tertiary"
                  className={styles.imageButton}
                  fullWidth
                >
                  {t('preview')}
                </Button>
              }
              titleClassName={styles.modalTitle}
              title={
                <>
                  {name}
                  <Button
                    viewStyle="primary"
                    onClick={handleClickUse}
                  >
                    {t('use_this_template')}
                  </Button>
                </>
              }
            >
              <div>
                {previews.map(({ url }) => (
                  <img className={styles.modalImage} width="100%" src={url} alt={name} key={url} />
                ))}
              </div>
            </Modal>
          </div>
        </div>
      </div>
      <div className={styles.name}>
        {searchValue && searchIndex !== -1 ? <>
          {name.slice(0, searchIndex)}<span className="accent-text">{name.slice(searchIndex, endIndex)}</span>{name.slice(endIndex)}
        </> : name}
      </div>
    </GridItem>
  );
};

export default StaticTemplatesGridItem;
