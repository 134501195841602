import { FC, ReactNode } from 'react';

import styles from './create-template-button.module.css';

interface CreateTemplateButtonProps {
  label?: ReactNode
  name: ReactNode,
  description?: ReactNode,
  icon: string,
  iconWidth?: number,
  onClick: () => void
}

const CreateTemplateButton: FC<CreateTemplateButtonProps> = ({ label, name, description, icon, iconWidth = 83, onClick }) => {
  return (
    <div className={styles.root} onClick={onClick}>
      {label && <span className={styles.label}>{label}</span>}
      <img className={styles.icon} src={icon} width={iconWidth} alt="" />
      <span className={styles.title}>{name}</span>
      {description && <span className={styles.description}>{description}</span>}
    </div>
  );
};

export default CreateTemplateButton;
