import { FC } from 'react';
import { Button } from '@forma/forma-ui-kit';

import { ITemplateVariation } from 'interfaces/templates.interface';

import styles from './variations-list.module.css';

interface VariationsListProps {
  items: ITemplateVariation[],
  onClickItem: (item: ITemplateVariation) => void
}

const VariationsList: FC<VariationsListProps> = ({ items, onClickItem }) => {
  return (
    <div className={styles.items}>
      {items.map((item) => (
        <div className={styles.item} key={item.id}>
          <Button
            className={styles.button}
            viewStyle="secondary"
            onClick={() => onClickItem(item)}
            size="small"
            fullWidth
            shadow
          >
            {item.title}
          </Button>
        </div>
      ))}
    </div>
  );
};

export default VariationsList;
