import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { ButtonsContainer, LoadingButton, Modal } from '@forma/forma-ui-kit';
import FillDocument from 'views/FillDocument';
import copyToClipboard from 'helpers/copyToClipboard';

import { IRendersItem, TFormatDocument } from 'interfaces/templates.interface';

import {
  useRenderTemplateMutation,
  useLazyDownloadRenderedTemplateQuery,
  ITemplateRenderData,
  useRenderHtmlMutation
} from 'store/templates/templatesApi';
import { addNotification } from 'store/notifications/notificationsSlice';
import { useGetCounteragentsQuery } from 'store/counteragents/counteragentsApi';
import {
  useAddProductMutation,
  useGetProductsCategoriesQuery,
  useGetProductsQuery
} from 'store/products/productsApi';

import styles from './ModalChangeDocument.module.css';
import { ReactSVG } from 'react-svg';

interface ModalChangeDocumentProps {
  open: boolean,
  onClose: () => void,
  selectedTemplate: IRendersItem
}

const ModalChangeDocument: FC<ModalChangeDocumentProps> = ({ open, onClose, selectedTemplate }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [ searchParams ] = useSearchParams();
  const debug = searchParams.get('debug');

  const [ rerenderTemplate, { isLoading: isRerenderDownloading } ] = useLazyDownloadRenderedTemplateQuery();
  const [ renderTemplate, { isLoading: isDownloadLoading } ] = useRenderTemplateMutation();
  const [ renderHtml ] = useRenderHtmlMutation();

  const [isFillingValid, setIsFillingValid] = useState<boolean>(false);
  const { data: categories } = useGetProductsCategoriesQuery();
  const [ addProduct, { isLoading: isAddLoading } ] = useAddProductMutation();

  const isMounted = useRef(false);
  const [ sidesData, setSidesData ] = useState<ITemplateRenderData['sideValues']>([]);;
  const [isChangedData, inChangeData] = useState<boolean>(false);
  const [name, setName] = useState<string>(selectedTemplate?.name);

  useEffect(() => {
    if (sidesData.length === 0) return;
    isMounted.current = true;
  }, [sidesData]);

  const handleClickRender = async (
    name: string,
    sideValues: ITemplateRenderData['sideValues'],
  ) => {
    renderHtml({ id: selectedTemplate.template.id, name, sideValues }).then(result => {
      if ('data' in result) {
        copyToClipboard(result.data);
        dispatch(addNotification({ content: t('copied'), type: 'SUCCESS' }));
      }
    }).catch(e => {});
  };

  const handleClickDownload = async (
    name: string,
    sideValues: ITemplateRenderData['sideValues'],
    format: TFormatDocument = 'pdf',
    isChanged: boolean
  ) => {
    if (!isChanged) {
      rerenderTemplate({ id: selectedTemplate.template.id, rerenderId: selectedTemplate.id, format }).unwrap().then(url => {
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = `${name}.${format}`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      }).catch(e => {});
    } else {
      const data: ITemplateRenderData = {
        id: selectedTemplate.template.id,
        name: name,
        sideValues
      };

      renderTemplate({ ...data, format }).unwrap().then(url => {
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = `${name}.${format}`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      }).catch(e => {});
    }
  };

  const handleAddProduct = async (data: { [key: string]: string }) => {
    const res = await addProduct(data);
    if ('data' in res) {
      dispatch(addNotification({ content: t('product_service_was_added'), type: 'SUCCESS' }));
      return res.data;
    }
    return null;
  };

  const sides = useMemo(() => selectedTemplate?.sides.map((data, index) => ({ ...data, id: data.id ?? index+1+'' })), [selectedTemplate]);
  const valueSides = useMemo(() => selectedTemplate.sideValues?.reduce((acc, data, index) => ({
    ...acc,
    [sides[index].id]: {
      ...data,
      id: sides[index].id,
      tableValues: data.tableValues?.length > 0 ? data.tableValues : null,
      // isValid: true,
      tattrErrors: {}
    }
  }), {}), [selectedTemplate, sides]);

  const handleChangeData = (data: ITemplateRenderData['sideValues']) => {
    setSidesData(data);
    if (isMounted.current) inChangeData(true);
  };

  return <Modal
    size="large"
    open={open}
    onClose={onClose}
  >
    <div className={styles.modalChangeDocument}>
      <ButtonsContainer className={styles.buttons}>
        <LoadingButton
          type="submit"
          className={styles.button}
          viewStyle="tertiary"
          size="small"
          onClick={() => handleClickDownload(name, sidesData, 'pdf', isChangedData)}
          disabled={!isFillingValid}
          isLoading={isRerenderDownloading}
          icon={<ReactSVG src="/icons/download.svg" />}
        >
          {t('download_pdf')}
        </LoadingButton>
        <LoadingButton
          type="submit"
          className={styles.button}
          viewStyle="tertiary"
          size="small"
          onClick={() => handleClickDownload(name, sidesData, 'docx', isChangedData)}
          disabled={!isFillingValid}
          isLoading={isRerenderDownloading}
          icon={<ReactSVG src="/icons/download.svg" />}
        >
          {t('download_docx')}
        </LoadingButton>
      </ButtonsContainer>
      <FillDocument
        name={selectedTemplate?.name}
        sides={sides}
        onClickDownload={handleClickDownload}
        onClickRender={debug ? handleClickRender : undefined}
        isDownloadLoading={isDownloadLoading || isRerenderDownloading}
        valueSides={valueSides}
        counteragentsQueryHook={useGetCounteragentsQuery}
        productsQueryHook={useGetProductsQuery}
        categories={categories}
        onAddProduct={handleAddProduct}
        isAddLoading={isAddLoading}
        showNameInput
        setIsFillingValid={setIsFillingValid}
        setSidesDataForInnerRenderButton={handleChangeData}
        setNameFileForInnerRender={setName}
        showWordButton={true}
      />
    </div>
  </Modal>;
};

export default ModalChangeDocument;