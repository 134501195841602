import { FC } from 'react';
import { PaginationProps, PaginationWrapper } from '@forma/forma-ui-kit';
import MyDocumentsHeaderTable from './MyDocumentsHeaderTable';
import { MyDocumentsListTable } from './MyDocumentsListTable';

import { IRendersItem } from 'interfaces/templates.interface';

import styles from './my-documents-table.module.css';

interface MyDocumentsTableProps {
  items?: IRendersItem[],
  filters: { [key: string]: string|number },
  onChangeSearch: (value: string) => void,
  pagination: PaginationProps,
  onChangePacksFilter: (value: string) => void,
  onChangeDate: (fromAt: string, toAt: string) => void
}

const MyDocumentsTable: FC<MyDocumentsTableProps> = ({ items, filters, onChangeSearch, pagination, onChangePacksFilter, onChangeDate }) => {
  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <MyDocumentsHeaderTable
          onChangeSearch={onChangeSearch}
          onChangePacksFilter={onChangePacksFilter}
          onChangeDate={onChangeDate}
        />
      </div>
      <div className={styles.content}>
        <PaginationWrapper {...pagination}>
          <MyDocumentsListTable items={items} />
        </PaginationWrapper>
      </div>
    </div>
  );
};

export default MyDocumentsTable;
