import { ColontitulData, PositionType } from 'interfaces/templates.interface';
import { IVariableItem } from 'interfaces/variables.interface';

export const SKELETON_CLASS = 'skeleton-loader';

export const case_items = [
  // 'I',
  'R',
  'D',
  // 'V',
  'T'
  //  'P'
];

export const date_items = [
  'A', 'B', 'C', 'D', 'E'
];

export const contragent_types: { [key: string]: 'E'|'IP'|'P' } = {
  entity: 'E', individual: 'IP', person: 'P'
};

export const contragent_default_variables: {
  P: { id: string, required: boolean }[],
  IP: { id: string, required: boolean }[],
  E: { id: string, required: boolean }[],
} = {
  P: [
    { id: '101', required: true },
    { id: '102', required: true },
    { id: '103', required: true },
    { id: '322', required: false }
  ],
  IP: [
    { id: '304', required: true },
    { id: '2006', required: false },
    { id: '101', required: false },
    { id: '102', required: false },
    { id: '103', required: false },
    { id: '306', required: false },
    { id: '307', required: false },
    { id: '308', required: false },
    { id: '314', required: false },
    { id: '316', required: false },
    { id: '317', required: false },
    { id: '321', required: false },
    { id: '2001', required: false },
  ],
  E: [
    { id: '304', required: true },
    { id: '2006', required: false },
    { id: '302', required: false },
    { id: '305', required: false },
    { id: '306', required: false },
    { id: '307', required: false },
    { id: '308', required: false },
    { id: '314', required: false },
  ]
};

export const files_icons: { [key: string]: string } = {
  '.bmp': '/icons/file_types/bmp.svg',
  '.csv': '/icons/file_types/csv.svg',
  '.dg2': '/icons/file_types/dg2.svg',
  '.dgr': '/icons/file_types/dgr.svg',
  '.doc': '/icons/file_types/doc.svg',
  '.docx': '/icons/file_types/docx.svg',
  '.forma': '/icons/file_types/forma.svg',
  '.gif': '/icons/file_types/gif.svg',
  '.jpg': '/icons/file_types/jpg.svg',
  '.jpeg': '/icons/file_types/jpg.svg',
  '.key': '/icons/file_types/keynote.svg',
  '.keynote': '/icons/file_types/keynote.svg',
  '.numbers': '/icons/file_types/numbers.svg',
  '.pages': '/icons/file_types/pages.svg',
  '.pdf': '/icons/file_types/pdf.svg',
  '.png': '/icons/file_types/png.svg',
  '.ppt': '/icons/file_types/ppt.svg',
  '.pptx': '/icons/file_types/pptx.svg',
  '.tif': '/icons/file_types/tif.svg',
  '.tiff': '/icons/file_types/tif.svg',
  '.txt': '/icons/file_types/txt.svg',
  '.xls': '/icons/file_types/xls.svg',
  '.xlsx': '/icons/file_types/xlsx.svg',
  '.zip': '/icons/file_types/zip.svg',
  'other': '/icons/file_types/other.svg'
};

export const categories_colors: { [key: string]: { background: string, color: string } } = {
  red: { background: '#FF9696', color: '#000000' },
  orange: { background: '#FFA88C', color: '#000000' },
  yellow: { background: '#FFEE94', color: '#000000' },
  khaki: { background: '#D5D781', color: '#000000' },
  lightgreen: { background: '#BBE3A3', color: '#000000' },
  turquoise: { background: '#94D4D4', color: '#000000' },
  lightblue: { background: '#A7E2FC', color: '#000000' },
  purple: { background: '#C8D4FF', color: '#000000' },
  lightpurple: { background: '#E4CFFF', color: '#000000' },
  green: { background: '#79BE70', color: '#FFFFFF' },
  pink: { background: '#FFCBF0', color: '#000000' },
  whiteb: { background: '#FFFFFF', color: '#000000' },
  whiteg: { background: '#FFFFFF', color: '#79BE70' },
  darkgray: { background: '#969C9E', color: '#FFFFFF' },
  lightgray: { background: '#C6CBCD', color: '#FFFFFF' }
};

export const currencies: { [key: string]: { code: string, symbol: string }} = {
  ru: {
    code: 'ru-RU',
    symbol: 'RUB'
  },
  en: {
    code: 'en-US',
    symbol: 'USD'
  },
};

interface IPaymentMethodsItem {
  id: string,
  icons?: string[],
  tattrsBase?: IVariableItem[],
  tattrsFull?: IVariableItem[]
}

export const paymentMethods: IPaymentMethodsItem[] = [
  {
    id: 'card',
    icons: [ '/icons/payments/mastercard.svg', '/icons/payments/mir.svg', '/icons/payments/visa.svg' ]
  },
  {
    id: 'transfer',
    tattrsBase: [
      {
        id: 'companyname', // nonstandard
        lang: 'ru',
        errorMessage: 'Укажите полное наименование на русском языке',
        charset: 'REDS',
        inputTooltip: 'Укажите полное наименование на русском языке',
        inputText: 'Введите название или ИНН для поиска',
        inputWidth: 642, // nonstandard
        labelWidth: 642, // nonstandard
        labelLineCount: 1,
        type: 'string',
        name: 'Наименование организации или ИП'
      },
      {
        id: 'phone', // nonstandard
        lang: 'ru',
        inputText: '+7 XXX XXX XX XX',
        inputWidth: 200,
        labelWidth: 200,
        labelLineCount: 1,
        mask: '+7-XXX-XXX-XX-XX',
        regexp: '^\\+7-\\d{3}-\\d{3}-\\d{2}-\\d{2}',
        type: 'phone',
        name: 'Номер телефона'
      }
    ],
    tattrsFull: [
      {
        id: 'companyname', // nonstandard
        lang: 'ru',
        errorMessage: 'Укажите полное наименование на русском языке',
        charset: 'REDS',
        inputTooltip: 'Укажите полное наименование на русском языке',
        inputText: 'Введите название или ИНН для поиска',
        inputWidth: 864, // nonstandard
        labelWidth: 864, // nonstandard
        labelLineCount: 1,
        type: 'string',
        name: 'Наименование организации или ИП'
      },
      {
        id: 'address', // nonstandard
        lang: 'ru',
        errorMessage: 'Укажите юридический адрес',
        inputTooltip: 'Укажите юридический адрес',
        name: 'Юридический адрес',
        type: 'address',
        labelLineCount: 1,
        inputWidth: 864, // nonstandard
        labelWidth: 864, // nonstandard
        inputText: 'Москва, Новинский б-р, д. 9, офис 11'
      },
      {
        id: 'inn', // nonstandard
        lang: 'ru',
        errorMessage: 'Для ИП - 12 цифр, для Юридического лица - 10 цифр',
        inputTooltip: 'Для ИП - 12 цифр, для Юридического лица - 10 цифр',
        inputText: 'Введите 10 или 12 цифр',
        inputWidth: 200,
        labelWidth: 200,
        labelLineCount: 1,
        maxLength: 12,
        minLength: 10,
        type: 'string',
        name: 'ИНН Юр. лица / ИП',
        regexp: '^(\\d{10}|\\d{12})$' // nonstandard
      },
      {
        id: 'ogrn', // nonstandard
        lang: 'ru',
        errorMessage: 'ОГРН состоит из 13 или 15 цифр', // nonstandard
        inputTooltip: 'Основной государственный регистрационный номер состоит из 13 или 15 цифр', // nonstandard
        name: 'ОГРН',
        type: 'string',
        charset: 'D',
        maxLength: 15, // nonstandard
        minLength: 13, // nonstandard
        // length: 13, nonstandard
        labelLineCount: 1,
        labelWidth: 200,
        inputWidth: 200,
        inputText: '1197777777777',
        regexp: '^(\\d{13}|\\d{15})$' // nonstandard
      },
      {
        id: 'kpp', // nonstandard
        lang: 'ru',
        name: 'КПП',
        type: 'string',
        charset: 'D',
        length: 9,
        labelLineCount: 1,
        inputWidth: 200, // nonstandard
        labelWidth: 200, // nonstandard
        inputText: 'Введите КПП',
        inputTooltip: 'КПП состоит из 9 цифр',
        errorMessage: 'КПП состоит из 9 цифр'
      }
    ]
  }
];

// R: 'Кириллица',
// E: 'Латинница',
// D: 'Цифры',
// S: 'Символы'

export const productTattrs = [
  {
    id: 'name',
    lang: 'ru',
    errorMessage: 'Укажите полное наименование',
    charset: 'REDS',
    inputText: 'Введите название',
    inputWidth: 492,
    labelWidth: 492,
    labelLineCount: 1,
    type: 'string',
    name: 'Название',
    regexp: '^[a-zA-Zа-яА-Я0-9Ёё«»<>№—“`‚"‘’“”„–‒ .?,"`~\'![\\]{\\}()@#^$%:;&%*+|/_\\-\\=\\\\]+$',
  },
  {
    id: 'vendorCode',
    lang: 'ru',
    errorMessage: 'Укажите артикул',
    charset: 'EDS',
    inputText: 'Артикул',
    inputWidth: 200,
    labelWidth: 200,
    labelLineCount: 1,
    type: 'string',
    name: 'Артикул',
    required: false
  },
  {
    id: 'category',
    lang: 'ru',
    errorMessage: 'Введите название категории',
    charset: 'REDS',
    inputText: 'Категория',
    inputWidth: 200,
    labelWidth: 200,
    labelLineCount: 1,
    type: 'list',
    values: [ 'Мебель', 'Электроника', 'Услуги', 'Прочее' ],
    searchable: true,
    name: 'Категория',
    required: false
  },
  {
    id: 'unit',
    lang: 'ru',
    errorMessage: 'Укажите еденицы измерения',
    charset: 'REDS',
    inputText: 'шт.',
    inputWidth: 150,
    labelWidth: 150,
    labelLineCount: 1,
    type: 'list',
    values: [ 'шт.', 'л', 'кг', 'м', 'г' ],
    searchable: true,
    name: 'Еденица измерения',
    required: false
  },
  {
    id: 'description',
    lang: 'ru',
    errorMessage: 'Введите описание',
    charset: 'REDS',
    inputText: 'Введите описание',
    inputWidth: 492,
    labelWidth: 492,
    labelLineCount: 1,
    type: 'string',
    name: 'Описание',
    required: false,
    /* eslint-disable */
    regexp: '^[a-zA-Zа-яА-Я0-9Ёё«»<>№—“`‚"‘’“”„–‒ .?,"`~\'!\[\\]\{\\}\()@#^$%:;&%*+|/_\\-\\=\\\\]+$',
  },
  {
    id: 'price',
    lang: 'ru',
    errorMessage: 'Введите цену числом',
    charset: 'DS',
    inputText: '₽',
    inputWidth: 200,
    labelWidth: 200,
    labelLineCount: 1,
    type: 'money',
    name: 'Цена',
    required: false
  },
  {
    id: 'VAT',
    lang: 'ru',
    errorMessage: 'Введите число',
    charset: 'DS',
    inputText: '%',
    inputWidth: 200,
    labelWidth: 200,
    labelLineCount: 1,
    type: 'decimal',
    name: '% НДС',
    required: false
  },
  {
    id: 'discountPercent',
    lang: 'ru',
    errorMessage: 'Введите число',
    charset: 'DS',
    inputText: '%',
    inputWidth: 200,
    labelWidth: 200,
    labelLineCount: 1,
    type: 'decimal',
    name: 'Скидка на позицию товара в %',
    required: false

  },
  {
    id: 'discountCost',
    lang: 'ru',
    errorMessage: 'Введите число',
    charset: 'DS',
    inputText: '₽',
    inputWidth: 200,
    labelWidth: 200,
    labelLineCount: 1,
    type: 'money',
    name: 'Скидка на позицию товара в деньгах',
    required: false
  },
];

export const organizationTattrs: IVariableItem[] = [
  {
    id: 'companyname', // nonstandard
    lang: 'ru',
    errorMessage: 'Укажите полное наименование на русском языке',
    charset: 'REDS',
    inputTooltip: 'Укажите полное наименование на русском языке',
    inputText: 'Введите название или ИНН для поиска',
    inputWidth: 864, // nonstandard
    labelWidth: 864, // nonstandard
    labelLineCount: 1,
    type: 'string',
    name: 'Полное наименование организации или ИП'
  },
  {
    id: 'address', // nonstandard
    lang: 'ru',
    errorMessage: 'Укажите юридический адрес',
    inputTooltip: 'Укажите юридический адрес',
    name: 'Юридический адрес',
    type: 'address',
    labelLineCount: 1,
    inputWidth: 864, // nonstandard
    labelWidth: 864, // nonstandard
    inputText: 'Москва, Новинский б-р, д. 9, офис 11'
  },
  {
    id: 'inn', // nonstandard
    lang: 'ru',
    errorMessage: 'Для ИП - 12 цифр, для Юридического лица - 10 цифр',
    inputTooltip: 'Для ИП - 12 цифр, для Юридического лица - 10 цифр',
    inputText: 'Введите 10 или 12 цифр',
    inputWidth: 200,
    labelWidth: 200,
    labelLineCount: 1,
    maxLength: 12,
    minLength: 10,
    type: 'string',
    name: 'ИНН Юр. лица / ИП',
    regexp: '^(\\d{10}|\\d{12})$' // nonstandard
  },
  // {
  //   id: 'ogrn', // nonstandard
  //   lang: 'ru',
  //   errorMessage: 'ОГРН состоит из 13 или 15 цифр', // nonstandard
  //   inputTooltip: 'Основной государственный регистрационный номер состоит из 13 или 15 цифр', // nonstandard
  //   name: 'ОГРН',
  //   type: 'string',
  //   charset: 'D',
  //   maxLength: 15, // nonstandard
  //   minLength: 13, // nonstandard
  //   // length: 13, nonstandard
  //   labelLineCount: 1,
  //   labelWidth: 200,
  //   inputWidth: 200,
  //   inputText: '1197777777777',
  //   regexp: '^(\\d{13}|\\d{15})$' // nonstandard
  // },
  // {
  //   id: 'kpp', // nonstandard
  //   lang: 'ru',
  //   name: 'КПП',
  //   type: 'string',
  //   charset: 'D',
  //   length: 9,
  //   labelLineCount: 1,
  //   inputWidth: 200, // nonstandard
  //   labelWidth: 200, // nonstandard
  //   inputText: 'Введите КПП',
  //   inputTooltip: 'КПП состоит из 9 цифр',
  //   errorMessage: 'КПП состоит из 9 цифр'
  // },
  // {
  //   id: 'email', // nonstandard
  //   lang: 'ru',
  //   name: 'Email',
  //   type: 'email',
  //   labelLineCount: 1,
  //   inputWidth: 200,
  //   labelWidth: 200,
  //   inputText: 'ivan@mail.ru',
  //   inputTooltip: 'Введите email в формате name@domain.ext',
  //   errorMessage: 'Не корректный формат email'
  // },
  // {
  //   id: 'phone', // nonstandard
  //   lang: 'ru',
  //   inputText: '+7 XXX XXX XX XX',
  //   inputWidth: 200,
  //   labelWidth: 200,
  //   labelLineCount: 1,
  //   mask: '+7-XXX-XXX-XX-XX',
  //   regexp: '^\\+7-\\d{3}-\\d{3}-\\d{2}-\\d{2}',
  //   type: 'phone',
  //   name: 'Номер телефона'
  // }
];

export const colontitulPositionList: PositionType[] = [
  'top-left',
  'top-center',
  'top-right',
  'bottom-left',
  'bottom-center',
  'bottom-right'
];

export const colontitulDefaultData: ColontitulData['data'] = {
  type: 'text',
  text: '',
  startPage: 1,
  displayOnFirstPage: true
};

export const miniToolbar = {
  toolbar: {
    items: ['bold', 'italic', 'underline', 'fontBackgroundColor'],
  },
};
