import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { LoadingButton, Input, Tags } from '@forma/forma-ui-kit';

import { categories_colors } from 'data/mock';
import { IAttachmentCategoriesItem } from 'interfaces/attachments.interface';

import styles from './categories-select.module.css';

const categories_examples = [
  'importants', 'contract', 'invoice', 'additional_agreement'
];

interface CategoriesSelectProps {
  items?: IAttachmentCategoriesItem[],
  selected: string[],
  inColumns?: boolean,
  onCreate: (data: { name: string, color: string }) => void,
  onSelect: (id: string) => void,
  onDeselect: (id: string) => void,
  isLoading: boolean
}

const CategoriesSelect: FC<CategoriesSelectProps> = ({ items, selected = [], inColumns, onCreate, onSelect, onDeselect, isLoading }) => {
  const { t } = useTranslation();
  const [ newName, setName ] = useState<string>('');
  const [ newColor, setColor ] = useState<string>('');

  const handleClickCreate = () => {
    onCreate({ name: newName, color: newColor });
    setName('');
    setColor('');
  };

  const handleClickCategory = (id: string) => {
    selected.includes(id) ? onDeselect(id) : onSelect(id);
  };

  return (
    <div className={styles.root}>
      <div className={styles.row}>
        <div className={styles.heading}>
          <div className={classNames(styles.title, inColumns && styles.fullWidth)}>
            {t('used_categories')}:
          </div>
          <Tags
            noWrapper
            items={items?.map(({ id, name, color }) => (
              {
                name: name,
                color: categories_colors[color]?.color,
                backgroundColor: categories_colors[color]?.background,
                active: selected.includes(id),
                onClick: () => handleClickCategory(id)
              }
            ))}
          />
        </div>
      </div>
      <div className={styles.title}>{t('create_new_category')}:</div>
      <div className={classNames(styles.row, inColumns && styles.wrap)}>
        <div>
          <Input
            name="category_name"
            className={styles.input}
            value={newName}
            placeholder={t('enter_category_name')}
            onChange={e => setName(e.target.value)}
            maxLength={32}
          />
          <div className={styles.example}>
            <span className={styles.exampleLabel}>{t('for_example')}:</span>
            <Tags
              noWrapper
              items={categories_examples.map(key => (
                {
                  name: t(key),
                  onClick: () => setName(t('key')),
                  size: 'small'
                }
              ))}
            />
          </div>
          <LoadingButton
            isLoading={isLoading}
            className={styles.buttonCreate}
            viewStyle="tertiary"
            size="small"
            disabled={!(newName.length && newColor)}
            onClick={handleClickCreate}
          >
            {t('create_category')}
          </LoadingButton>
        </div>
        <div>
          <div className={styles.subtitle}>{t('select_color_for_category')}</div>
          <Tags
            className={styles.colors}
            items={Object.keys(categories_colors).map(key => (
              {
                name: t('contract'),
                color: categories_colors[key]?.color,
                backgroundColor: categories_colors[key]?.background,
                active: newColor === key,
                onClick: () => setColor(key)
              }
            ))}
          />
        </div>
      </div>
    </div>
  );
};

export default CategoriesSelect;
