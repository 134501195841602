import { FC } from 'react';
import { ReactSVG } from 'react-svg';
import { Button } from '@forma/forma-ui-kit';

import styles from './edit-button.module.css';

interface EditButtonProps {
  active: boolean,
  onClickEdit: () => void,
  onClickSubmit: () => void
}

const EditButton: FC<EditButtonProps> = ({ active, onClickEdit, onClickSubmit }) => {
  return (
    <Button
      className={styles.button}
      viewStyle={active ? 'primary' : 'tertiary'}
      iconClassName={styles.icon}
      icon={active ? <ReactSVG src="/icons/tick.svg" wrapper="span" /> : <ReactSVG src="/icons/edit.svg" wrapper="span" />}
      onClick={active ? onClickSubmit : onClickEdit}
    />
  );
};

export default EditButton;
