import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';
import { useNavigate, useSearchParams, useParams } from 'react-router-dom';
import { getFromTo } from '@forma/forma-ui-kit';
import PageTitle from 'components/PageTitle';
import { AdminLayout } from 'components/Layouts';
import SafeDocumentsList from 'views/SafeDocumentsList';
import DocumentModal from 'views/SafeDocumentsList/DocumentModal';
import copyToClipboard from 'helpers/copyToClipboard';

import { addNotification } from 'store/notifications/notificationsSlice';
import {
  useGetAttachmentsTreeQuery,
  useGetAttachmentCategoriesQuery,
  useCreateAttachmentCategoryMutation,
  useAddAttachmentMutation,
  useGetAttachmentByIdQuery,
  useUpdateAttachmentMutation,
  useLazyDownloadAttachmentQuery,
  useLazyDownloadAttachmentsArchiveQuery,
  useRemoveAttachmentMutation,
} from 'store/attachments/attachmentsApi';
import { selectUserPermissions } from 'store/user/userSlice';

const SafeDocuments = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [ searchParams, setSearchParams ] = useSearchParams();
  const [ params, setParams ] = useState({ ...getFromTo(1) });
  const page = searchParams.get('page');

  const userPermissions = useSelector(selectUserPermissions);

  const { data: attachment, isFetching: isAttachmentLoading } = useGetAttachmentByIdQuery(id, { skip: !id });
  const { data: attachments, isFetching: isPageLoading, error: pageError } = useGetAttachmentsTreeQuery({ ...params, ...getFromTo(page) });
  const { data: categories } = useGetAttachmentCategoriesQuery();
  const [ addAttachment, { isLoading: isAddLoading, isSuccess: isAddSuccess } ] = useAddAttachmentMutation();
  const [ removeAttachment ] = useRemoveAttachmentMutation();
  const [ updateAttachment ] = useUpdateAttachmentMutation();
  const [ createCategory, { isLoading: isCreateCategoryLoading } ] = useCreateAttachmentCategoryMutation();

  const [ downloadAttachment, { isFetching: isDownloadLoading } ] = useLazyDownloadAttachmentQuery();
  const [ downloadAttachmentsArchive, { isFetching: isDownloadArchiveLoading } ] = useLazyDownloadAttachmentsArchiveQuery();

  useEffect(() => {
    if (pageError) {
      if (pageError.status === 402) navigate('/subscribe');
      else if (pageError.status !== 'FETCH_ERROR' && pageError.name !== 'AbortError') navigate('/404');
    }
    // eslint-disable-next-line
  }, [pageError]);

  useEffect(() => {
    if (userPermissions) {
      const canViewAttachments = userPermissions.includes('attachments_view');
      if (!canViewAttachments) {
        navigate('/');
      }
    }
    // eslint-disable-next-line
  }, [userPermissions]);

  const handleChangePage = (page: number) => {
    setSearchParams(prev => ({ ...Object.fromEntries(prev.entries()), page: String(page) }));
  };

  const handleChangeSearch = (value: string) => {
    setParams(prev => ({ ...prev, name: value }));
    handleChangePage(1);
  };

  const handleChangeFilter = (params: { [key: string]: string }) => {
    setParams(prev => ({ ...prev, ...params }));
    handleChangePage(1);
  };

  const handleAddDocument = async (params: { [key: string]: any }) => {
    const formData = new FormData();
    for (const key of Object.keys(params)) {
      if (params[key]) formData.append(key, params[key]);
    }

    const res = await addAttachment(formData);
    if (res && !res.error) {
      dispatch(addNotification({ content: t('file_uploaded_successfully'), type: 'SUCCESS' }));
    }
  };

  const handleRemove = async (id: string) => {
    const res = await removeAttachment(id);
    if (res && !res.error) {
      dispatch(addNotification({ content: t('file_deleted_successfully'), type: 'ERROR' }));
      navigate('/safe');
    }
  };

  const handleOutOfDate = () => {
    updateAttachment({ id, inactive: format(new Date(), 'yyyy-MM-dd') });
  };

  const handleCopyLink = (id: string) => {
    const origin = window.location.origin;
    const res = copyToClipboard(`${origin}/safe/${id}`);
    if (res === 'success') dispatch(addNotification({ content: t('link_copied'), type: 'SUCCESS' }));
  };

  const handleChangeAttachment = (data: { [ key: string ]: string|string[] }) => {
    updateAttachment({ id: attachment.id, ...data });
  };

  const handleDowloadFiles = (ids: string[], fileName?: string|null) => {
    if (ids.length === 1) {
      downloadAttachment(ids[0]).unwrap().then((url: string) => {
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = fileName ?? 'Document';
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      }).catch(() => {});
    } else {
      downloadAttachmentsArchive({ ids: ids }).unwrap().then((url: string) => {
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      }).catch(() => {});
    }
  };

  return (
    <AdminLayout
      title={t('safe')}
    >
      <PageTitle>{t('site_name') + ' – ' + t('safe')}</PageTitle>
      <SafeDocumentsList
        active={id}
        items={attachments?.attachments}
        onChangeSearch={handleChangeSearch}
        onChangeFilter={handleChangeFilter}
        onClickCopy={handleCopyLink}
        onClickDownload={handleDowloadFiles}
        onAddDocument={handleAddDocument}
        isAddLoading={isAddLoading}
        isAddSuccess={isAddSuccess}
        isDownloadLoading={isDownloadLoading || isDownloadArchiveLoading}
        categories={{
          items: categories,
          onCreate: createCategory,
          isLoading: isCreateCategoryLoading
        }}
        pagination={{
          isLoading: isPageLoading,
          onChange: handleChangePage,
          count: attachments?.count ?? 0,
          page: page
        }}
      />
      <DocumentModal
        open={!!id}
        onClose={() => navigate('/safe')}
        isLoading={isAttachmentLoading}
        onClickCopy={handleCopyLink}
        onChangeData={handleChangeAttachment}
        onClickDownload={handleDowloadFiles}
        isDownloadLoading={isDownloadLoading || isDownloadArchiveLoading}
        onRemove={handleRemove}
        onOutOfDate={handleOutOfDate}
        categoriesAll={{
          items: categories,
          onCreate: createCategory,
          isLoading: isCreateCategoryLoading
        }}
        {...attachment}
      />
    </AdminLayout>
  );
};

export default SafeDocuments;