import { FC, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { DndContext, DragEndEvent, MouseSensor, useSensor, useSensors } from '@dnd-kit/core';
import { Modal, Input } from '@forma/forma-ui-kit';
import Grid from './Grid';
import SidePanel, { SidePanelPlaceholder } from './SidePanel';

import { ITemplatesListSides, ITemplatesPack } from 'interfaces/templates.interface';

import styles from './templates-pack-fill.module.css';

interface SortSideItem {
  templateId: string,
  sideIndex: number
}

interface SortSide {
  name: string,
  sideIndices: SortSideItem[]
}

interface TemplatesPackFillProps {
  pack?: ITemplatesPack,
  isLoading: boolean,
  isSidesLoading: boolean,
  templateName: string,
  templatesSides?: ITemplatesListSides,
  onSave: (name: string, sides: SortSide[]) => void,
  isSaveLoading: boolean
}

interface DragItemData {
  fromSide?: number,
  templateId: string,
  templateName: string,
  name: string,
  sideIndex: number,
}

const parsePackSides = (templatesSides: ITemplatesListSides, pack: ITemplatesPack): SortSide[] => {
  const sides: SortSide[] = pack.sides.map(({ name }) => ({ name, sideIndices: [] }));
  pack.templates.forEach(({ id, sideIndices }) => {
    // сторона в документе - сторона в пакете
    sideIndices.forEach((packSideNumber, sideIndex) => {
      if (templatesSides && templatesSides[id] && templatesSides[id].sides[sideIndex]) {
        sides[packSideNumber-1].sideIndices.push({ templateId: id, sideIndex });
      }
    });
  });
  return sides;
};

const parseTemplatesSides = (templatesSides: ITemplatesListSides, pack?: ITemplatesPack): SortSideItem[] => {
  if (!Object.keys(templatesSides).length) return [];
  return Object.values(templatesSides).reduce((acc: SortSideItem[], { id, sides }) => ([
    ...acc,
    ...sides.reduce((acc: SortSideItem[], current, index) => (
      pack?.templates.findIndex(template => (template.id === id && template.sideIndices.includes(index))) === -1 ?
        acc : [ ...acc, { templateId: id, sideIndex: index } ]
    ), [])
  ]), []);
};

const parseTemplateSidesEditTemplates = (templatesSides: ITemplatesListSides, pack?: ITemplatesPack): SortSideItem[] => {
  if (!Object.keys(templatesSides).length) return [];
  return Object.values(templatesSides).reduce((acc: SortSideItem[], current) => {
    const templateExist = pack?.templates.some((elemPack) => elemPack.id === current.id);
    if (!templateExist) {
      return [
        ...acc,
        ...current.sides.map((_, indexSide) => ({ templateId: current.id, sideIndex: indexSide }))
      ];
    }
    return acc;
  }, []);
};

const TemplatesPackFill: FC<TemplatesPackFillProps> = ({
  pack, isLoading, isSidesLoading, templateName, templatesSides, onSave, isSaveLoading
}) => {
  const { t } = useTranslation();
  const [ isShowRename, setShowRename ] = useState(false);
  const [ name, setName ] = useState('');
  const [ sidesToFill, setSidesToFill ] = useState<SortSideItem[]>([]);
  const [ sides, setSides ] = useState<SortSide[]>([]);

  useEffect(() => {
    setName(templateName);
  }, [templateName]);

  useEffect(() => {
    if (!templatesSides) return;
    if (pack) {
      setSidesToFill((() => parseTemplateSidesEditTemplates(templatesSides, pack)));
      setSides(() => parsePackSides(templatesSides, pack));
    } else {
      setSidesToFill(() => parseTemplatesSides(templatesSides));
      setSides([{ name: `${t('side')} 1`, sideIndices: [] }]);
    }
    // eslint-disable-next-line
  }, [pack, templatesSides]);

  const sensors = useSensors(useSensor(MouseSensor, {
    activationConstraint: { distance: 3 }
  }));

  const handleDropToSide = ({ active, over }: DragEndEvent) => {
    const side = active.data.current as DragItemData;
    if (!active?.data.current || !over?.data.current || active.data.current.fromSide === over.data.current.index) return;

    const dropSideIndex = over?.data.current?.index;

    setSidesToFill(prev => {
      const next = [ ...prev ];

      const searchIndex = next.findIndex(({ templateId, sideIndex }) => (
        templateId === side.templateId && sideIndex === side.sideIndex
      ));

      if (searchIndex !== -1) next.splice(searchIndex, 1);

      return next;
    });

    setSides(prev => {
      const next = [ ...prev ];

      const searchIndex = next[dropSideIndex].sideIndices.findIndex(({ templateId, sideIndex }) => (
        templateId === side.templateId && sideIndex === side.sideIndex
      ));

      if (searchIndex === -1) next[dropSideIndex].sideIndices.push({
        templateId: side.templateId,
        sideIndex: side.sideIndex
      });

      if (side.fromSide || side.fromSide === 0) {
        const searchIndex = next[side.fromSide].sideIndices.findIndex(({ templateId, sideIndex }) => (
          templateId === side.templateId && sideIndex === side.sideIndex
        ));

        if (searchIndex !== -1) next[side.fromSide].sideIndices.splice(searchIndex, 1);
      }

      return next;
    });
  };

  const handleAddSide = () => {
    setSides(prev => [ ...prev, { name: `${t('side')} ${prev.length+1}`, sideIndices: [] }]);
  };

  const handleRemoveSide = (index: number) => {
    let deleted: SortSide[] = [];
    setSides(prev => {
      const next = [ ...prev ];
      deleted = next.splice(index, 1);
      return next;
    });
    if (deleted && deleted[0].sideIndices) setSidesToFill(prev => [ ...prev, ...deleted[0].sideIndices ]);
  };

  const handleRenameSide = (index: number, name: string) => {
    setSides(prev => {
      const next = [ ...prev ];
      next[index] = { ...next[index] };
      next[index].name = name;
      return next;
    });
  };

  const handleSave = () => {
    if (templateName.length < 3) setShowRename(true);
    else onSave(templateName, sides);
  };

  return (
    <div className={styles.root} id="templates_pack_fill">
      <DndContext
        onDragEnd={handleDropToSide}
        sensors={sensors}
      >
        <div className={styles.content}>
          <h1 className={styles.title}>{t('distribute_sides')}</h1>
          <div className={styles.grid}>
            <Grid
              isLoading={isLoading}
              sides={sidesToFill}
              templatesSides={templatesSides}
            />
          </div>
        </div>
        <div className={styles.side}>
          {!(isLoading || isSidesLoading) ? (
            <SidePanel
              sides={sides}
              templatesSides={templatesSides}
              onSave={handleSave}
              isSaveLoading={isSaveLoading}
              onAddSide={handleAddSide}
              onRemoveSide={handleRemoveSide}
              onRenameSide={handleRenameSide}
            />
          ) : (
            <SidePanelPlaceholder />
          )}
        </div>
      </DndContext>

      <Modal
        size="small"
        open={isShowRename}
        onClose={() => setShowRename(false)}
        title={t('save_templates_pack')}
        buttons={[
          {
            id: 'modal_save_pack_button',
            children: t('save'),
            viewStyle: 'primary',
            onClick: () => onSave(name, sides),
            disabled: name.length < 3
          },
          {
            children: t('cancel'),
            viewStyle: 'tertiary'
          }
        ]}
      >
        <div className={styles.renameModal}>
          <Input
            id="rename_templates_pack_input"
            name="pack_name"
            type="text"
            placeholder={t('templates_pack_name')}
            onChange={e => setName(e.target.value)}
            showClearButton
            value={name}
          />
        </div>
      </Modal>
    </div>
  );
};

export default TemplatesPackFill;
