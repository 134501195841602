import { FC, useState } from 'react';
import { useDroppable } from '@dnd-kit/core';
import { ReactSVG } from 'react-svg';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Button, RemoveModal } from '@forma/forma-ui-kit';
import DragItem from '../DragItem';
import { PackSideItem } from '../PackSideItem';

import { ITemplatesListSides } from 'interfaces/templates.interface';

import styles from './side-block.module.css';

interface SortSideItem {
  templateId: string,
  sideIndex: number
}

interface SideBlockProps {
  index: number,
  name: string,
  templatesSides?: ITemplatesListSides,
  sideIndices: SortSideItem[],
  onRemove: (index: number) => void,
  onRename: (index: number, templateName: string) => void,
}

const SideBlock: FC<SideBlockProps> = ({ index, name = '', templatesSides, sideIndices = [], onRemove, onRename }) => {
  const { t } = useTranslation();
  const [ isShow, setIsShow ] = useState(true);
  const [ isShowRename, setShowRename ] = useState(false);
  const [ isShowModalRemove, setShowModalRemove ] = useState(false);
  const [ tmpName, setTmpName ] = useState(name);

  const { setNodeRef: dropRef, isOver } = useDroppable({
    id: 'side_' + index,
    data: { index }
  });

  const handleClickCancelName = () => {
    setShowRename(false);
    setTmpName(name);
  };

  const handleClickSaveName = () => {
    setShowRename(false);
    onRename(index, tmpName);
  };

  return (
    <div className={styles.block}>
      <div className={styles.header}>
        {isShowRename ? (
          <input
            className={styles.nameInput}
            value={tmpName}
            onChange={e => setTmpName(e.target.value)}
            maxLength={48}
          />
        ) : (
          <div className={styles.title}>
            {name}
          </div>
        )}

        {isShowRename ? (
          <div className={styles.buttons}>
            <Button
              className={classNames(styles.button, styles.edit)}
              viewStyle="text"
              title={t('submit')}
              onClick={handleClickSaveName}
              icon={<ReactSVG className={styles.buttonIcon} src="/icons/tick.svg" wrapper="span" />}
            />
            <Button
              className={classNames(styles.button, styles.edit)}
              viewStyle="text"
              title={t('cancel')}
              onClick={handleClickCancelName}
              icon={<ReactSVG className={styles.buttonIcon} src="/icons/close.svg" wrapper="span" />}
            />
          </div>
        ) : (
          <div className={styles.buttons}>
            <Button
              className={classNames(styles.button, styles.edit)}
              viewStyle="text"
              title={t('edit')}
              onClick={() => setShowRename(true)}
              icon={<ReactSVG className={styles.buttonIcon} src="/icons/edit.svg" wrapper="span" />}
            />
            <Button
              className={classNames(styles.button, styles.delete)}
              viewStyle="text"
              title={t('delete')}
              onClick={() => setShowModalRemove(true)}
              icon={<ReactSVG className={styles.buttonIcon} src="/icons/trash.svg" wrapper="span" />}
            />
            <Button
              className={classNames(styles.button, styles.toggler, isShow && styles.open)}
              viewStyle="text"
              title={isShow ? t('hide') : t('show')}
              onClick={() => setIsShow(!isShow)}
              icon={<ReactSVG className={styles.buttonIcon} src="/icons/dropup.svg" wrapper="span" />}
            />
          </div>
        )}
      </div>
      <div className={classNames(styles.content, isOver && styles.hover)} ref={dropRef}>
        {isShow ? (
          sideIndices.length ? (
            <div className={styles.list}>
              {sideIndices.map(({ templateId, sideIndex }) => {
                if (!templatesSides || !templatesSides[templateId]) return null;

                const templateName = templatesSides[templateId].name;
                const templateSide = templatesSides[templateId].sides[sideIndex];
                const name = templateSide ? templateSide.name : t('deleted_side');

                return (
                  <div className={styles.item} key={templateId + sideIndex}>
                    <DragItem
                      fromSide={index}
                      templateId={templateId}
                      sideIndex={sideIndex}
                      templateName={templateName}
                      name={name}
                    >
                      <PackSideItem templateName={templateName} name={name} isDeleted={!templateSide} />
                    </DragItem>
                  </div>
                );
              })}
            </div>
          ) : (
            <span className={styles.dropText}>{t('drag_side')}</span>
          )
        ) : (
          <div className={classNames(styles.sidesCount, sideIndices.length ? styles.fill : null)}>
            {t('count_sides', { count: sideIndices.length })}
          </div>
        )}
      </div>
      <RemoveModal
        open={isShowModalRemove}
        title={t('do_you_want_delete_side')}
        onClose={() => setShowModalRemove(false)}
        onRemove={() => onRemove(index)}
      />
    </div>
  );
};

export default SideBlock;
