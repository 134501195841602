import { useEffect } from 'react';
import { ReactSVG } from 'react-svg';
import { Trans, useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Button, ButtonsContainer } from '@forma/forma-ui-kit';
import PageTitle from 'components/PageTitle';
import { MainLayout } from 'components/Layouts';
import CodeInput from 'components/CodeInput';
import Review from 'views/account/Review';
import useTimer from 'hooks/useTimer';
import { getCookie } from 'helpers/cookies';

import { useEasyRegisterUserMutation, useGetMailDomainQuery, useVerifyUserMutation } from 'store/auth/authApi';

import styles from './Account.module.css';

const RegisterCode = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { left, reset: resetTimer } = useTimer(60);
  const isAllowResend = left === 0;

  const [ registerUser, { isLoading, isError } ] = useEasyRegisterUserMutation();
  const [ sendCode ] = useVerifyUserMutation();

  const { data: mailDomains } = useGetMailDomainQuery({ email: state?.login }, { skip: !state?.login });
  const mailDomain = mailDomains?.length ? mailDomains[0] : undefined;

  useEffect(() => {
    if (!state?.login) navigate('/register');
    // eslint-disable-next-line
  }, [state]);

  const onSubmit = (code: string) => {
    const roistatVisit = getCookie('roistat_visit');

    registerUser({ login: state.login, code, roistatVisit }).then(result => {
      if ('data' in result) {
        localStorage.setItem('newRegister', 'true');
        setTimeout(() => navigate('/register/password', { state: { login: state.login, isEmailSubmitted: true } }));
      }
    });
  };

  const handleClickResend = () => {
    sendCode({ login: state.login });
    resetTimer();
  };

  return (
    <MainLayout
      title={<>
        <div style={{ textAlign: 'left' }}>
          <Button
            as={Link}
            to="/register"
            viewStyle="text"
            icon={<ReactSVG src="/icons/arrow-left-thin.svg" wrapper="span" />}
            className={styles.backButton}
            state={state}
          >
            {t('go_back')}
          </Button>
        </div>
        <Trans i18nKey="account.we_send_code_to_email" />
      </>}
      sideChildren={<Review />}
    >
      <PageTitle>{t('site_name') + ' – ' + t('register2')}</PageTitle>

      <form className={styles.form}>
        <CodeInput isLoading={isLoading} onComplete={onSubmit} isError={isError} />
        <ButtonsContainer className={styles.buttonsContainer}>
          {mailDomain && (
            <Button
              as="a"
              href={mailDomain?.url}
              viewStyle="secondary"
              className={styles.mailButton}
              iconClassName={styles.mailButtonIcon}
              icon={<img src={mailDomain?.logoUrl} alt={mailDomain?.name} />}
              target="_blank"
              fullWidth
              shadow
              data-testid="submit"
            >
              {t('open_in')} {mailDomain?.name}
            </Button>
          )}
        </ButtonsContainer>
        <div className={styles.checkCodeText}>
          {t('account.check_email_code', { email: state?.login })}
        </div>
      </form>

      <ButtonsContainer className={styles.buttonsContainer}>
        {isAllowResend ? (
          <Button
            className={styles.resendButton}
            viewStyle="textPrimary"
            onClick={handleClickResend}
          >
            {t('resend_code')}
          </Button>
        ) : (
          <div className={styles.resendText}>
            {t('resend_code')} {t('after_count_seconds', { count: left })}
          </div>
        )}
      </ButtonsContainer>
    </MainLayout>
  );
};

export default RegisterCode;
