import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Tags } from '@forma/forma-ui-kit';

import styles from './document-modal.module.css';

const DocumentSkelet = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.root}>
      <div className={styles.heading}>
        <span className={classNames(styles.fileIcon, 'skeleton-loader')} />
        <div className={classNames(styles.title, 'skeleton-loader')} />
        <div className={classNames(styles.edit, 'skeleton-loader')} />
      </div>
      <div className={styles.features}>
        <div className={styles.featuresItem}>
          <div className={styles.featureLabel}>{t('extension')}:</div>
          <div className={classNames(styles.featureValue, 'skeleton-loader')} />
        </div>
        <div className={styles.featuresItem}>
          <div className={styles.featureLabel}>{t('file_size')}:</div>
          <div className={classNames(styles.featureValue, 'skeleton-loader')} />
        </div>
        <div className={styles.featuresItem}>
          <div className={styles.featureLabel}>{t('file_upload_date')}:</div>
          <div className={classNames(styles.featureValue, 'skeleton-loader')} />
        </div>
        <div className={styles.featuresItem}>
          <div className={styles.featureLabel}>{t('validity')}:</div>
          <div className={classNames(styles.featureValue, 'skeleton-loader')} />
        </div>
      </div>
      <div className={styles.block}>
        <div className={styles.subtitle}>{t('file_description')}:</div>
        <div className={styles.description}>
          <div className={classNames(styles.descriptionText, 'skeleton-loader')} />
          <div className={classNames(styles.edit, 'skeleton-loader')} />
        </div>
      </div>
      <div className={styles.block}>
        <div className={styles.subtitle}>{t('categories')}:</div>
        <Tags
          className={styles.categories}
          size="small"
          items={[
            { name: '', className: 'skeleton-loader' },
            { name: '', className: 'skeleton-loader' },
            { name: '', className: 'skeleton-loader' },
            { name: '', className: 'skeleton-loader' }
          ]}
        />
      </div>
      {/* <Checkbox
        label={t('out_of_date')}
        containerClass={styles.checkbox}
        onClick={() => setShowModalRelevant(true)}
        checked={!!inactive}
      /> */}
    </div>
  );
};

export default DocumentSkelet;
