import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Select, DateSelect, SearchInput } from '@forma/forma-ui-kit';

import styles from './my-documents-header-table.module.css';

interface MyDocumentsHeaderTableProps {
  onChangeSearch: (value: string) => void,
  onChangePacksFilter: (value: string) => void,
  onChangeDate: (fromAt: string, toAt: string) => void
}

const MyDocumentsHeaderTable: FC<MyDocumentsHeaderTableProps> = ({ onChangeSearch, onChangePacksFilter, onChangeDate }) => {
  const { t } = useTranslation();
  const options = [
    { value: '', label: t('all_documents') },
    { value: 'true', label: t('packages') },
    { value: 'false', label: t('no_packages') }
  ];
  const handlePacksFilter = (e: { target: { name: string, value: string } }) => {
    onChangePacksFilter(e.target.value);
  };
  return (
    <div className={styles.root}>
      <SearchInput
        onSearch={onChangeSearch}
        className={styles.search}
        placeholder={t('enter_document_name')}
      />
      <div className={styles.select}>
        <DateSelect onChangeDate={onChangeDate} />
      </div>
      <div className={classNames(styles.select, styles.customSize)}>
        <Select
          name="filter_group"
          onChange={handlePacksFilter}
          options={options}
          placeholder={t('all_documents')}
        />
      </div>
    </div>
  );
};

export default MyDocumentsHeaderTable;