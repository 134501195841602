export const menu = [
  {
    id: 'homepage',
    icon: '/icons/menu/homepage.svg',
    lang: 'homepage',
    href: '/guide',
  },
  {
    id: 'templates',
    href: '/templates',
    lang: 'templates',
    icon: '/icons/menu/templates.svg',
    routes: [
      '/templates/:slug', '/fill-document/:slug', '/favourites', '/static-templates', '/static-templates/:id',
      '/templates-packs/', '/templates-packs/:id', '/templates-pack-create', '/templates-pack-sides', '/templates-pack-sides/:id', '/fill-templates-pack/:id'
    ]
  },
  {
    id: 'documents',
    href: '/completed-docs',
    lang: 'documents',
    icon: '/icons/menu/documents.svg',
    routes: [ '/external-fill' ]
  },
  {
    id: 'products',
    href: '/products',
    lang: 'products_and_services',
    icon: '/icons/menu/products.svg',
  },
  {
    id: 'contragents',
    href: '/contragents',
    lang: 'contragents',
    icon: '/icons/menu/counteragents.svg',
    // items: [
    //   {
    //     id: 'entity_persons',
    //     href: '/contragents?type=entity',
    //     lang: 'contragents_types.entity',
    //     icon: '/icons/menu/build.svg',
    //   },
    //   {
    //     id: 'individual_persons',
    //     href: '/contragents?type=individual',
    //     lang: 'contragents_types.individual',
    //     icon: '/icons/menu/business.svg',
    //   },
    //   {
    //     id: 'person_persons',
    //     href: '/contragents?type=person',
    //     lang: 'contragents_types.person',
    //     icon: '/icons/menu/user.svg',
    //   },
    // ]
  },
  {
    id: 'subscribe',
    href: '/select-tariff',
    lang: 'payment_and_tariffs',
    icon: '/icons/menu/payment.svg',
  },
  // {
  //   id: 'safe',
  //   href: '/safe',
  //   lang: 'safe',
  //   icon: '/icons/menu/safe.svg',
  //   routes: [ '/safe/:id' ]
  // },
  // {
  //   id: 'completed_docs',
  //   href: '/completed-docs',
  //   lang: 'my_documents',
  //   icon: '/icons/menu/documents.svg',
  //   disabled: false,
  // },
  // {
  //   id: 'external_fill',
  //   href: '/external-fill',
  //   lang: 'external_fill',
  //   icon: '/icons/menu/externalfill.svg',
  //   disabled: false,
  // },
  // {
  //   id: 'settings',
  //   href: '#',
  //   lang: 'settings',
  //   icon: '/icons/menu/settings.svg',
  //   items: [
  //     {
  //       id: 'profile',
  //       href: '/profile',
  //       lang: 'my_profile',
  //       icon: '/icons/menu/user.svg',
  //     },
  //     {
  //       id: 'users',
  //       href: '/users',
  //       lang: 'users',
  //       icon: '/icons/menu/users.svg',
  //       permissions: 'users',
  //       routes: [ 'users/:id', '/invite/:id', '/invite-success' ]
  //     },
  //     {
  //       id: 'users_groups',
  //       href: '/user-groups',
  //       lang: 'users_groups',
  //       icon: '/icons/menu/groups.svg',
  //       permissions: 'users'
  //     },
  //     {
  //       id: 'subscribtion',
  //       href: '/select-tariff',
  //       lang: 'subscribtion_and_payment',
  //       icon: '/icons/menu/card.svg'
  //     },
  //     // {
  //     //   id: 'my_integrations',
  //     //   href: '/my-integrations',
  //     //   lang: 'my_integrations',
  //     //   disabled: true,
  //     // },
  //     // {
  //     //   id: 'my_coworkers',
  //     //   href: '/users',
  //     //   lang: 'my_coworkers'
  //     // }
  //   ]
  // }
];

export const mobileMenu = [
  {
    id: 'templates',
    href: '/templates',
    lang: 'templates',
    icon: '/icons/menu/templates.svg',
    routes: [ '/templates/:slug', '/fill-document/:slug', '/favourites' ]
  },
  {
    id: 'docsuments',
    href: '/completed-docs',
    lang: 'documents',
    icon: '/icons/menu/documents.svg',
    disabled: false,
  },
  {
    id: 'settings',
    href: '/profile',
    lang: 'settings',
    icon: '/icons/menu/settings.svg',
  }
];

