import { FC, ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { format } from 'date-fns';
import { ReactSVG } from 'react-svg';
import classNames from 'classnames';
import { Checkbox, Modal, Input, Tags, Textarea } from '@forma/forma-ui-kit';
import { selectUserPermissions } from 'store/user/userSlice';
import { files_icons, categories_colors } from 'data/mock';
import CategoriesSelect from '../CategoriesSelect';
import EditButton from '../EditButton';
import DocumentSkelet from './DocumentSkelet';

import { IAttachmenItem, IAttachmentCategoriesItem } from 'interfaces/attachments.interface';

import styles from './document-modal.module.css';

interface DocumentModalProps extends IAttachmenItem {
  open: boolean,
  onClose: () => void,
  isLoading: boolean,
  onClickCopy: (id: string) => void,
  onChangeData: (data: { [key: string]: string|string[] }) => void,
  onClickDownload: (ids: string[], name: string) => void,
  isDownloadLoading: boolean,
  onRemove: (id: string) => void,
  onOutOfDate: (id: string) => void,
  categoriesAll: {
    items?: IAttachmentCategoriesItem[],
    onCreate: (data: { name: string, color: string }) => void,
    isLoading: boolean
  }
}

interface ModalButtonProps {
  children?: ReactNode,
  icon?: ReactNode,
  className: string,
  viewStyle: 'primary'|'text',
  isLoading?: boolean,
  onClick: () => void,
  closing?: false
}

const DocumentModal: FC<DocumentModalProps> = ({
  id, name, ext, description, size, categories, uploadedAt, dtTo, filename, inactive,
  open, onClose, isLoading, onClickCopy, onChangeData, onClickDownload, categoriesAll, isDownloadLoading,
  onRemove, onOutOfDate
}) => {
  const { t } = useTranslation();
  const userPermissions = useSelector(selectUserPermissions);
  const canRemoveFiles = userPermissions?.includes('attachments_delete');
  const kb = Number((size/1000).toFixed());
  const mb = Number((kb/1000).toFixed());
  const [ isEditName, setEditName ] = useState<boolean>(false);
  const [ isEditDescription, setEditDescription ] = useState<boolean>(false);
  const [ isEditCategories, setEditCategories ] = useState<boolean>(false);
  const [ tmpName, setTmpName ] = useState(name);
  const [ tmpDescription, setTmpDescription ] = useState<string>(description);
  const [ tmpCategories, setTmpCategories ] = useState<string[]>(categories?.map(({ id }) => id) ?? []);
  const [ isShowModalDelete, setShowModalDelete ] = useState<boolean>(false);
  const [ isShowModalRelevant, setShowModalRelevant ] = useState<boolean>(false);

  useEffect(() => {
    if (name) setTmpDescription(name);
  }, [name]);

  useEffect(() => {
    if (description) setTmpDescription(description);
  }, [description]);

  useEffect(() => {
    if (!open) {
      setEditName(false);
      setEditDescription(false);
      setEditCategories(false);
    }
  }, [open]);

  const handleClickEditName = () => {
    setTmpName(name);
    setEditName(true);
  };

  const handleClickSubmitName = () => {
    setEditName(false);
    onChangeData({ name: tmpName });
  };

  const handleClickEditDescription = () => {
    setTmpDescription(description);
    setEditDescription(true);
  };

  const handleClickSubmitDescription = () => {
    setEditDescription(false);
    onChangeData({ description: tmpDescription });
  };

  const handleClickEditCategories = () => {
    setTmpCategories(categories?.map(({ id }) => id));
    setEditCategories(true);
  };

  const handleSelectCategory = (id: string) => {
    setTmpCategories(prev => [...prev, id]);
  };

  const handleDeselectCategory = (id: string) => {
    setTmpCategories(prev => {
      const next = [...prev];
      next.splice(prev.findIndex(item => item === id), 1);
      return next;
    });
  };

  const handleClickSubmitCategories = () => {
    setEditCategories(false);
    onChangeData({ categories: tmpCategories });
  };

  const modalButtons: ModalButtonProps[] = [
    {
      children: t('download_file'),
      className: styles.downloadBtn,
      viewStyle: 'primary',
      isLoading: isDownloadLoading,
      onClick: () => !isLoading && onClickDownload([ id ], filename),
      closing: false
    },
    {
      children: t('copy_link'),
      className: styles.shareBtn,
      icon: <ReactSVG src="/icons/share.svg" wrapper="span" />,
      viewStyle: 'text',
      onClick: () => !isLoading && onClickCopy(id),
      closing: false
    }
  ];

  const removeButton: ModalButtonProps = {
    children: t('delete'),
    className: styles.deleteBtn,
    icon: <ReactSVG src="/icons/trash.svg" wrapper="span" />,
    viewStyle: 'text',
    onClick: () => !isLoading && setShowModalDelete(true),
    closing: false
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      viewStyle="right"
      buttons={canRemoveFiles ? [ ...modalButtons, removeButton ] : modalButtons}
    >
      {isLoading ? (
        <DocumentSkelet />
      ) : (
        <div className={styles.root}>
          <div className={styles.heading}>
            <img src={files_icons[ext] ? files_icons[ext] : files_icons.other} className={styles.fileIcon} alt="" />
            {isEditName ? (
              <Input name="name" className={styles.input} value={tmpName} onChange={e => setTmpName(e.target.value)} />
            ) : (
              <div className={styles.title}>{name}</div>
            )}
            <div className={styles.edit}>
              <EditButton
                onClickEdit={handleClickEditName}
                onClickSubmit={handleClickSubmitName}
                active={isEditName}
              />
            </div>
          </div>
          <div className={styles.features}>
            <div className={styles.featuresItem}>
              <div className={styles.featureLabel}>{t('extension')}:</div>
              <div className={styles.featureValue}>{ext}</div>
            </div>
            <div className={styles.featuresItem}>
              <div className={styles.featureLabel}>{t('file_size')}:</div>
              <div className={styles.featureValue}>{mb > 0 ? `${mb} MB` : `${kb} KB`}</div>
            </div>
            <div className={styles.featuresItem}>
              <div className={styles.featureLabel}>{t('file_upload_date')}:</div>
              <div className={styles.featureValue}>{uploadedAt && format(new Date(uploadedAt), 'dd.MM.yyyy')}</div>
            </div>
            <div className={styles.featuresItem}>
              <div className={styles.featureLabel}>{t('validity')}:</div>
              <div className={styles.featureValue}>{dtTo && format(new Date(dtTo), 'dd.MM.yyyy')}</div>
            </div>
          </div>
          <div className={styles.block}>
            <div className={styles.subtitle}>{t('file_description')}:</div>
            <div className={styles.description}>
              {isEditDescription ? (
                <Textarea
                  name="description"
                  className={styles.textarea}
                  value={tmpDescription}
                  onChange={e => setTmpDescription(e.target.value)}
                />
              ) : (
                <div className={styles.descriptionText}>{description}</div>
              )}
              <div className={styles.edit}>
                <EditButton
                  onClickEdit={handleClickEditDescription}
                  onClickSubmit={handleClickSubmitDescription}
                  active={isEditDescription}
                />
              </div>
            </div>
          </div>
          <div className={styles.block}>
            <div className={styles.subtitle}>{t('categories')}:</div>
            <Tags
              className={styles.categories}
              size="small"
              onClickAdd={handleClickEditCategories}
              items={categories?.map(({ name, color }) => (
                {
                  name: name,
                  color: categories_colors[color]?.color,
                  backgroundColor: categories_colors[color]?.background
                }
              ))}
            />
          </div>
          <Checkbox
            name="out_of_date"
            label={t('out_of_date')}
            containerClass={classNames(styles.checkbox, inactive && styles.selected)}
            onChange={() => !inactive && setShowModalRelevant(true)}
            checked={!!inactive}
          />
        </div>
      )}
      <Modal
        open={isEditCategories}
        onClose={() => setEditCategories(false)}
        title={t('select_cactegories_to_add')}
        viewStyle="right"
        buttons={[
          {
            children: t('save'),
            disabled: !tmpCategories.length,
            viewStyle: 'primary',
            onClick: handleClickSubmitCategories
          },
          {
            children: t('back_to_file_card'),
            disabled: !tmpCategories.length,
            viewStyle: 'tertiary'
          }
        ]}
      >
        <div className={styles.root}>
          <CategoriesSelect
            {...categoriesAll}
            selected={tmpCategories}
            onSelect={handleSelectCategory}
            onDeselect={handleDeselectCategory}
            inColumns
          />
        </div>
      </Modal>
      {canRemoveFiles &&
        <Modal
          size="small"
          open={isShowModalDelete}
          onClose={() => setShowModalDelete(false)}
          title={t('deleting_file')}
          buttons={[
            {
              children: t('delete'),
              viewStyle: 'danger',
              onClick: () => onRemove(id)
            },
            {
              children: t('cancel'),
              viewStyle: 'tertiary'
            }
          ]}
        >
          <div className={styles.confirmModal}>
            {t('do_yo_want_delete')} “{filename ?? name}”?
            <br />
            <br />
            {t('cancellation_notification')}
          </div>
        </Modal>
      }
      <Modal
        size="small"
        open={isShowModalRelevant}
        onClose={() => setShowModalRelevant(false)}
        buttons={[
          {
            children: t('yes'),
            viewStyle: 'tertiary',
            onClick: () => onOutOfDate(id)
          },
          {
            children: t('no'),
            viewStyle: 'tertiary'
          }
        ]}
      >
        <div className={styles.confirmModal}>
          {t('do_yo_want_out_of_date_file')}
          <br />
          <br />
          {t('cancellation_notification')}
        </div>
      </Modal>
    </Modal>
  );
};

export default DocumentModal;
