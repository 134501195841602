import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { DateSelect, SearchInput } from '@forma/forma-ui-kit';

import styles from './external-fill-header.module.css';

interface ExternalFillHeaderProps {
  onChangeFilter: (params: { [key: string]: string }) => void
}

const ExternalFillHeader: FC<ExternalFillHeaderProps> = ({ onChangeFilter }) => {
  const { t } = useTranslation();

  const handleChangePeriod = (fromCreatedAt: string, toCreatedAt: string) => {
    onChangeFilter({ fromCreatedAt: fromCreatedAt === 'allTime' ? '' : fromCreatedAt, toCreatedAt: toCreatedAt === 'allTime' ? '' : toCreatedAt });
  };

  return (
    <div className={styles.root}>
      <SearchInput
        onSearch={value => onChangeFilter({ name: value })}
        className={styles.search}
        placeholder={t('find_needed_document')}
      />
      <div className={styles.select}>
        <DateSelect onChangeDate={handleChangePeriod} />
      </div>
    </div>
  );
};

export default ExternalFillHeader;