import { useEffect, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { Button, getFromTo } from '@forma/forma-ui-kit';
import PageTitle from 'components/PageTitle';
import { AdminLayout } from 'components/Layouts';
import MyDocumentsTable from 'views/MyDocuments';

import { useGetMyDocumentsQuery } from 'store/templates/templatesApi';

const MyDocuments = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [ searchParams, setSearchParams ] = useSearchParams();
  const [ params, setParams ] = useState<{ [key: string]: string|number }>({});
  const page = searchParams.get('page');

  const { data: renders, error: pageError, isFetching } = useGetMyDocumentsQuery({ ...params, ...getFromTo(page) });

  const onChangePacksFilter = (pack: string) => {
    handleChangePage(1);
    if (!pack) {
      setParams(prev => {
        const { pack, ...rest } = prev;
        return { ...rest, ...getFromTo(1) };
      });
    } else {
      setParams(prev => ({ ...prev, ...getFromTo(1), pack }));
    }
  };
  const handleChangePage = (page: number) => {
    setSearchParams(prev => ({ ...Object.fromEntries(prev.entries()), page: String(page) }));
  };

  const handleChangeSearch = (value: string) => {
    handleChangePage(1);
    setParams(prev => ({ ...prev, ...getFromTo(1), name: value }));
  };
  const handleChangeDate = (fromAt: string, toAt: string) => {
    handleChangePage(1);
    if (fromAt === 'allTime') {
      setParams(prev => {
        const { fromAt, toAt, ...rest } = prev;
        return { ...rest, ...getFromTo(1) };
      });
    } else {
      setParams(prev => ({ ...prev, ...getFromTo(1), fromAt: format(new Date(fromAt), 'yyyy-MM-dd'), toAt: format(new Date(toAt), 'yyyy-MM-dd') }));
    }
  };

  useEffect(() => {
    if (pageError && typeof pageError === 'object' && 'status' in pageError && 'name' in pageError) {
      if (pageError.status === 402) navigate('/subscribe');
      else if (pageError.status !== 'FETCH_ERROR' && pageError.name !== 'AbortError') navigate('/404');
    }
    // eslint-disable-next-line
  }, [pageError]);

  return (
    <div className="styled-scrollbar">
      <AdminLayout title={t('my_documents')} >
        <PageTitle>{t('site_name') + ' – ' + t('my_documents')}</PageTitle>

        {/* temporary solution */}
        <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px', marginBottom: '20px' }}>
          <Button
            as={Link}
            viewStyle="primary"
            to="/completed-docs"
            size="small"
          >
            {t('my_documents')}
          </Button>
          <Button
            as={Link}
            viewStyle="secondary"
            to="/external-fill"
            size="small"
            shadow
          >
            {t('external_fill')}
          </Button>
        </div>

        <MyDocumentsTable
          items={renders?.renders}
          filters={params}
          onChangePacksFilter={onChangePacksFilter}
          onChangeSearch={handleChangeSearch}
          onChangeDate={handleChangeDate}
          pagination={{
            isLoading: isFetching,
            onChange: handleChangePage,
            count: renders?.count ?? 0,
            page: page
          }}
        />
      </AdminLayout>
    </div>
  );
};

export default MyDocuments;
