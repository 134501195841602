import { useState, MouseEvent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { LoadingButton, Input, ButtonsContainer } from '@forma/forma-ui-kit';
import { yupResolver } from '@hookform/resolvers/yup';
import PageTitle from 'components/PageTitle';
import { MainLayout } from 'components/Layouts';
import PasswordCheck from 'views/profile/PasswordCheck';
import PasswordBanner from 'views/account/PasswordBanner';
import getValidationSchema from 'data/validationSchema';

import { useAppSelector } from 'store/hooks';
import { selectUserData } from 'store/user/userSlice';
import { useSetProfilePasswordMutation } from 'store/user/userApi';

import styles from './Account.module.css';

interface FormValues {
  login: string,
  password: string
}

const RegisterPassword = () => {
  const { t } = useTranslation();
  const user = useAppSelector(selectUserData);
  const [ isShowPass, setShowPass ] = useState(false);
  const [ setPassword, { isLoading, isError } ] = useSetProfilePasswordMutation();

  const { register, handleSubmit, control, watch, setValue, formState: { errors, isValid } } = useForm<FormValues>({
    mode: 'onChange', resolver: yupResolver(getValidationSchema([ 'password' ]))
  });

  const password = watch('password');

  useEffect(() => {
    if (user?.login) setValue('login', user.login);
    // eslint-disable-next-line
  }, [user]);

  const onSubmit: SubmitHandler<FormValues> = (data) => {
    setPassword({ newPassword: data.password }).then(result => {
      if ('data' in result) {
        localStorage.removeItem('newRegister');
        window.location.href = '/guide';
      }
    });
  };

  const handleClickPasswordEye = (e: MouseEvent<HTMLImageElement>) => {
    e.preventDefault();
    setShowPass(!isShowPass);
  };

  return (
    <MainLayout
      title={t('account.remains_set_password')}
      subtitle={<>{t('account.you_registered_as')} {user?.login}</>}
      sideChildren={<PasswordBanner visible={isShowPass} /> }
    >
      <PageTitle>{t('site_name') + ' – ' + t('register2')}</PageTitle>

      <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.inputs}>
          <Controller
            control={control}
            name="login"
            render={({ field: { value = '', ...rest } }) => (
              <Input
                placeholder={t('placeholders.login')}
                label={t('account.input_your_working_email')}
                maxLength={255}
                error={!!errors?.login?.message || isError}
                errorMessage={!!errors?.login?.message && t('errors.' + errors.login.message)}
                {...rest}
                {...register}
                value={value}
                readOnly
                data-testid="login"
                hidden
              />
            )}
          />
          <Controller
            control={control}
            name="password"
            render={({ field: { value = '', ...rest } }) => (
              <Input
                id="password"
                type={isShowPass ? 'text' : 'password'}
                placeholder={t('placeholders.password_new')}
                label={t('password')}
                buttons={
                  <img
                    src={isShowPass ? '/icons/eye-closed.svg' : '/icons/eye.svg'}
                    alt=""
                    onMouseDown={handleClickPasswordEye}
                  />
                }
                error={!!errors?.password?.message || isError}
                errorMessage={errors?.password?.message && t('errors.' + errors.password.message)}
                autoFocus
                {...rest}
                {...register}
                value={value}
              />
            )}
          />
          <div className={styles.passwordCheck}>
            <PasswordCheck password={password} />
          </div>
        </div>
        <ButtonsContainer className={styles.buttonsContainer}>
          <LoadingButton type="submit" disabled={!isValid} isLoading={isLoading} fullWidth shadow>{t('continue')}</LoadingButton>
        </ButtonsContainer>
      </form>
    </MainLayout>
  );
};

export default RegisterPassword;
