import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { PaginationWrapper, PaginationProps } from '@forma/forma-ui-kit';
import NoItemsVideo from 'views/onboards/NoItemsVideo';
import ExternalFillHeader from './ExternalFillHeader';
import ExternalFillList from './ExternalFillList';

import { IExternalFillData } from 'store/externalfill/externalfillApi';
import { IExternalFillItem } from 'interfaces/externalfill.interface';

import styles from './external-fill.module.css';

interface ExternalFillProps {
  openedId?: string,
  items?: IExternalFillItem[],
  onChangeFilter: (params: { [key: string]: string }) => void,
  pagination: PaginationProps,
  onCopyLink: (key: string) => void,
  onEdit: (data: IExternalFillData) => void,
  onRemove: (id: string) => void,
  onSend: (id: string, data: { login: string }) => void,
  onDuplicate: (id: string) => void
}

const staticUrl = process.env.REACT_APP_STATIC_URL;

const ExternalFill: FC<ExternalFillProps> = ({
  openedId, items, onChangeFilter, pagination, onCopyLink, onEdit, onRemove, onSend, onDuplicate
}) => {
  const { t } = useTranslation();

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <ExternalFillHeader onChangeFilter={onChangeFilter} />
      </div>
      <div className={styles.content}>
        {(items && pagination.count === 0 && !pagination.isLoading) ? (
          <NoItemsVideo
            title={t('you_not_send_documents_to_external_fill')}
            src={`${staticUrl}/videos/onboarding/externalfill_no_items.mp4`}
          />
        ) : (
          <PaginationWrapper {...pagination}>
            <ExternalFillList
              openedId={openedId}
              items={items}
              onCopyLink={onCopyLink}
              onEdit={onEdit}
              onRemove={onRemove}
              onSend={onSend}
              onDuplicate={onDuplicate}
            />
          </PaginationWrapper>
        )}
      </div>
    </div>
  );
};

export default ExternalFill;
