import { useState, useEffect, lazy, Suspense, useContext } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Tabs, ThemeContext, getFromTo } from '@forma/forma-ui-kit';
import PageTitle from 'components/PageTitle';
import { AdminLayout } from 'components/Layouts';
import ContragentsList from 'views/contragents/Contragents';

import {
  useGetCounteragentsQuery,
  useRemoveCounteragentMutation
} from 'store/counteragents/counteragentsApi';

import { contragent_types } from 'data/mock';
import { ICounteragentType } from 'interfaces/counteragents.interface';

const AddDataModal = lazy(() => import('./AddDataModal'));

const Contragents = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { viewport } = useContext(ThemeContext);
  const [ searchParams, setSearchParams ] = useSearchParams();
  const [ params, setParams ] = useState<{ [key: string]: string }>({});
  const [ openAddDateModal, setOpenAddDateModal ] = useState<boolean>(false);
  const [ removeContragent ] = useRemoveCounteragentMutation();

  const page = searchParams.get('page');
  const type = searchParams.get('type');

  const { data: counteragents, error: pageError, isFetching } =
    useGetCounteragentsQuery({ ...params, ...getFromTo(Number(page)) }, { skip: !type });

  useEffect(() => {
    if (type) setParams(prev => ({ ...prev, type }));
    else handleChangeTab(Object.keys(contragent_types)[0]);
    // eslint-disable-next-line
  }, [type]);

  useEffect(() => {
    if (pageError && typeof pageError === 'object' && 'status' in pageError && 'name' in pageError) {
      if (pageError.status === 402) navigate('/subscribe');
      else if (pageError.status !== 'FETCH_ERROR' && pageError.name !== 'AbortError') navigate('/404');
    }
    // eslint-disable-next-line
  }, [pageError]);

  const handleChangeSearch = (value: string) => {
    setParams(prev => ({ ...prev, filter: value }));
    handleChangePage(1);
  };

  const handleChangePage = (page: number) => {
    setSearchParams(prev => ({ ...Object.fromEntries(prev.entries()), page: String(page) }));
  };

  const handleChangeTab = (id: string) => {
    const params = Object.fromEntries(searchParams.entries());
    delete(params.page);
    setSearchParams({ ...params, type: id });
  };

  const handleClickImport = () => {
    setOpenAddDateModal(true);
  };

  const handleRemove = (id: string) => {
    removeContragent(id);
  };

  return (
    <AdminLayout title={t('directory_contragents')}>
      <PageTitle>{t('site_name') + ' – ' + t('directory_contragents')}</PageTitle>
      <Tabs
        current={type}
        onChange={handleChangeTab}
        items={
          Object.keys(contragent_types).map((item) => {
            const cType = item as ICounteragentType;
            return {
              id: item,
              name: t(`contragents_types.${item}${viewport === 'phone' ? '_short' : ''}`),
              children: (
                <ContragentsList
                  onChangeSearch={handleChangeSearch}
                  items={counteragents?.items}
                  type={cType}
                  pagination={{
                    isLoading: isFetching,
                    onChange: handleChangePage,
                    count: counteragents?.count ?? 0,
                    page: page
                  }}
                  onClickImport={handleClickImport}
                  onRemove={handleRemove}
                  key={item}
                />
              )
            };
          })
        }
      />
      <Suspense>
        <AddDataModal open={openAddDateModal} onClose={setOpenAddDateModal} type={params.type}/>
      </Suspense>
    </AdminLayout>
  );
};

export default Contragents;
