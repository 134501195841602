import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ThemeContext } from '@forma/forma-ui-kit';
import classNames from 'classnames';

import styles from './telegram-button.module.css';

const TelegramButton = () => {
  const { t } = useTranslation();
  const [ isOver, setOver ] = useState<boolean>(false);
  const { viewport } = useContext(ThemeContext);

  if (viewport === 'phone') return (
    <div className={classNames(styles.root, styles.hover)}>
      <img className={styles.icon} src={'/icons/telegram-button.svg'} alt="" />
    </div>
  );

  return (
    <a
      className={classNames(styles.root, isOver && styles.hover)}
      onMouseEnter={() => setOver(true)}
      onMouseLeave={() => setOver(false)}
      href="https://t.me/FormaHelperBot"
      target="_blank"
      rel="noreferrer"
    >
      <div className={styles.text}>{t('answers_in_telegram')}</div>
      <img className={styles.icon} src={isOver ? '/icons/telegram-button.svg' : '/icons/question-button.svg'} alt="" />
    </a>
  );
};

export default TelegramButton;
