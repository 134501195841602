import { FC } from 'react';
import { DragOverlay, useDndContext } from '@dnd-kit/core';
import DragItem from '../DragItem';
import { PackSideItem, Placeholder } from '../PackSideItem';
import { ITemplatesListSides } from 'interfaces/templates.interface';

import styles from './grid.module.css';

interface SortSideItem {
  templateId: string,
  sideIndex: number
}

interface GridProps {
  sides?: SortSideItem[],
  templatesSides?: ITemplatesListSides,
  isLoading: boolean
}

const Grid: FC<GridProps> = ({ sides, templatesSides, isLoading }) => {
  const dndContext = useDndContext();
  const draggingItem = dndContext.active?.data.current;

  return (
    <div className={styles.root} id="templates_pack_fill_grid">
      {isLoading ? (
        [...Array(5).keys()].map(key => (
          <div className={styles.item} key={key}>
            <Placeholder />
          </div>
        ))
      ) : (
        !!(sides?.length) && sides.map(({ templateId, sideIndex }) => {
          if (!(templatesSides && templatesSides[templateId])) return null;

          const templateName = templatesSides[templateId].name;
          const name = templatesSides[templateId].sides[sideIndex].name;

          return (
            <div className={styles.item} key={templateId + sideIndex}>
              <DragItem templateId={templateId} sideIndex={sideIndex} templateName={templateName} name={name}>
                <PackSideItem templateName={templateName} name={name} />
              </DragItem>
            </div>
          );
        })
      )}

      {(templatesSides && draggingItem) && (
        <DragOverlay zIndex={10}>
          <PackSideItem
            templateName={templatesSides[draggingItem.templateId].name}
            name={templatesSides[draggingItem.templateId].sides[draggingItem.sideIndex].name}
            isDragging
          />
        </DragOverlay>
      )}
    </div>
  );
};

export default Grid;
