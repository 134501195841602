import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReactSVG } from 'react-svg';
import { Trans, useTranslation } from 'react-i18next';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Modal } from '@forma/forma-ui-kit';
import PageTitle from 'components/PageTitle';
import { AdminLayout } from 'components/Layouts';
import FillDocument from 'views/FillDocument';

import { ITemplateSide } from 'interfaces/templates.interface';
import { IVariableItem, IUserVariableItem } from 'interfaces/variables.interface';

import {
  useGetPackQuery,
  useRenderPackMutation
} from 'store/packs/packsApi';
import {
  ITemplateRenderData,
  useGetTemplatesListSidesQuery,
} from 'store/templates/templatesApi';
import { selectUserPermissions } from 'store/user/userSlice';
import { useGetCounteragentsQuery } from 'store/counteragents/counteragentsApi';
import {
  useAddProductMutation,
  useGetProductsCategoriesQuery,
  useGetProductsQuery
} from 'store/products/productsApi';
import { addNotification } from 'store/notifications/notificationsSlice';

import styles from './PackFilling.module.css';

interface DownloadParams {
  sideValues: ITemplateRenderData['sideValues']
}

const Filling = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { id } = useParams();
  const navigate = useNavigate();
  const userPermissions = useSelector(selectUserPermissions);
  const canChangeFolders = userPermissions?.includes('folders_templates');
  const [ isOpenEmpty, setOpenEmpty ] = useState(false);
  const [ sides, setSides ] = useState<ITemplateSide[]|undefined>();
  const [ isShowModalDownload, setShowModalDownload ] = useState(false);
  const [ downloadParams, setDownloadParams ] = useState<DownloadParams|null>(null);

  const { data: pack, error: pageError } = useGetPackQuery(id ?? '');
  const { data: templatesSides, isSuccess: sidesSucess } =
    useGetTemplatesListSidesQuery({ ids: pack?.templates?.map(({ id }) => id) ?? [] }, { skip: !pack });

  const { data: categories } = useGetProductsCategoriesQuery();
  const [ addProduct, { isLoading: isAddLoading } ] = useAddProductMutation();

  useEffect(() => {
    if (pageError && typeof pageError === 'object' && 'status' in pageError && 'name' in pageError) {
      if (pageError.status === 402) navigate('/subscribe');
      else if (pageError.status !== 'FETCH_ERROR' && pageError.name !== 'AbortError') navigate('/404');
    }
    // eslint-disable-next-line
  }, [pageError]);

  useEffect(() => {
    if (pack && templatesSides) {
      const sidesTattrsIds: { [key: string]: string[] } = pack.sides.reduce((acc, current, index) => ({ ...acc, [index+1+'']: [] }), {});
      const _sides: ITemplateSide[] = pack.sides.map(({ name }, index) => ({ id: index+1+'', name, tattrFolders: [], tables: [], carticles: [] }));
      pack.templates.forEach(({ id, sideIndices }) => {
        // сторона в документе - сторона в пакете
        sideIndices.forEach((packSideNumber, sideIndex) => {
          const side = templatesSides[id].sides[sideIndex];
          if (side?.tattrFolders) {
            side.tattrFolders.forEach(({ id, name, tattrs }) => {
              if (!_sides[packSideNumber-1].tattrFolders) return;
              const folderIndex = _sides[packSideNumber-1].tattrFolders.findIndex(item => id === item.id);

              const uniqueTattrs: (IVariableItem|IUserVariableItem)[] =
                tattrs.filter(({ id }) => (!sidesTattrsIds[packSideNumber] || !sidesTattrsIds[packSideNumber].includes(id)));

              sidesTattrsIds[packSideNumber] = [ ...sidesTattrsIds[packSideNumber], ...uniqueTattrs.map(({ id }) => id) ];

              if (folderIndex === -1) {
                _sides[packSideNumber-1].tattrFolders.push({ id, name, tattrs: [...uniqueTattrs] });
              } else {
                // uniqueTattrs.forEach(({ id, ...rest }) => {
                //   if (!_sides[packSideNumber-1].tattrFolders[folderIndex].tattrs) return;
                //   const tattrIndex = _sides[packSideNumber-1].tattrFolders[folderIndex].tattrs.findIndex(item => id === item.id);
                //   if (tattrIndex === -1) {
                //     _sides[packSideNumber-1].tattrFolders[folderIndex].tattrs.push({ id, ...rest });
                //   }
                // });
                _sides[packSideNumber-1].tattrFolders[folderIndex].tattrs =
                  [ ..._sides[packSideNumber-1].tattrFolders[folderIndex].tattrs, ...uniqueTattrs ];
              }
            });
          }
          if (side?.tables) {
            _sides[packSideNumber-1].tables = [ ..._sides[packSideNumber-1].tables, ...side.tables ];
          }
          if (side?.carticles) {
            _sides[packSideNumber-1].carticles = [ ..._sides[packSideNumber-1].carticles, ...side.carticles ];
          }
        });
      });
      setSides(_sides);
    }
  }, [pack, templatesSides]);

  useEffect(() => {
    if (sidesSucess && templatesSides && Object.values(templatesSides).findIndex(({ sides }) => !!sides.length) === -1) setOpenEmpty(true);
    // eslint-disable-next-line
  }, [sidesSucess]);

  const [ renderPack, { isLoading: isDownloadLoading } ] = useRenderPackMutation();

  const downloadTemplate = (type: 'pdf'|'zip'|'docx') => {
    if (!pack || !downloadParams) return;
    const data = {
      id: pack.id,
      name: pack.name,
      format: type,
      sideValues: downloadParams.sideValues
    };

    renderPack(data).unwrap().then(url => {
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = `${pack.name}.${type}`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    }).catch(e => {});
  };

  const handleClickDownload = (
    name: string,
    sideValues: ITemplateRenderData['sideValues']
  ) => {
    setDownloadParams({ sideValues });
    setShowModalDownload(true);
  };

  const handleAddProduct = async (data: { [key: string]: string }) => {
    const res = await addProduct(data);
    if ('data' in res) {
      dispatch(addNotification({ content: t('product_service_was_added'), type: 'SUCCESS' }));
      return res.data;
    }
    return null;
  };

  return (
    <AdminLayout
      title={t('fill_pack_by_template')}
      breadcrumbs={{ items: [ { name: t('templates_packs'), to: '/templates-packs', as: Link }, { name: t('fill_by_template') } ] }}
    >
      <PageTitle>{t('site_name') + ' – ' + t('fill_pack_by_template')}</PageTitle>
      <FillDocument
        name={pack?.name}
        sides={sides}
        onClickDownload={handleClickDownload}
        isDownloadLoading={isDownloadLoading}
        counteragentsQueryHook={useGetCounteragentsQuery}
        productsQueryHook={useGetProductsQuery}
        categories={categories}
        onAddProduct={handleAddProduct}
        isAddLoading={isAddLoading}
      />
      <Modal
        size="small"
        open={isOpenEmpty}
        onClose={() => setOpenEmpty(false)}
        title={t('empty_document_sides')}
        buttons={
          canChangeFolders ? [
            {
              viewStyle: 'primary',
              children: t('open_document_in_editor'),
              onClick: () => navigate(`/templates-pack/${id}`)
            }
          ] : [
            {
              viewStyle: 'primary',
              children: t('return_to_templates'),
              onClick: () => navigate('/templates')
            }
          ]
        }
      >
        <ReactSVG src="/images/empty-doc-animation.svg" />
        <p className={styles.emptyDescription}>
          <Trans i18nKey="empty_document_sides_description" />
        </p>
      </Modal>
      <Modal
        size="medium"
        open={isShowModalDownload}
        onClose={() => setShowModalDownload(false)}
        buttons={[
          {
            children: t('no_every_doc_separate'),
            icon: <ReactSVG src="/icons/document-many.svg" wrapper="span" />,
            viewStyle: 'tertiary',
            onClick: () => downloadTemplate('zip')
          },
          {
            children: t('yes_single_pdf'),
            icon: <ReactSVG src="/icons/document-one.svg" wrapper="span" />,
            viewStyle: 'tertiary',
            onClick: () => downloadTemplate('pdf')
          },
          {
            children: t('yes_single_word'),
            icon: <ReactSVG src="/icons/document-one.svg" wrapper="span" />,
            viewStyle: 'tertiary',
            onClick: () => downloadTemplate('docx')
          },
        ]}
      >
        <p className={styles.downloadDescription}>{t('generate_documents_one_pdf')}</p>
      </Modal>
    </AdminLayout>
  );
};

export default Filling;