import { useState, useEffect, FC } from 'react';
import { ReactSVG } from 'react-svg';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ButtonsContainer, Button, LoadingButton, FillFolder, FillingItemSkelet } from '@forma/forma-ui-kit';
import SelectVariableModal from 'views/FilesEditor/VariableSelectModal';

import { contragent_default_variables } from 'data/mock';
import { ICounteragent } from 'interfaces/counteragents.interface';
import { IVariableItem, IUserVariableItem, IVariablesHierarhyItem, IGroupWithSearchedTattrFolders } from 'interfaces/variables.interface';

import styles from './contragent-edit.module.css';

interface ContragentEditProps {
  type?: 'P'|'IP'|'E',
  counteragent?: ICounteragent,
  variablesGroups?: { [key: string]: IVariablesHierarhyItem },
  availableVariables?: { [key: string]: IVariableItem },
  userVariables?: { [key: string]: IUserVariableItem },
  isLoading: boolean,
  onSave: (values: { [key: string]: string }) => void,
  isSaveLoading: boolean,
  canDeleteVariables?: boolean
}

interface SelectedVariablesItem { id: string, required: boolean }

const ContragentEdit: FC<ContragentEditProps> = ({
  type, counteragent, variablesGroups, availableVariables, userVariables, isLoading, onSave, isSaveLoading, canDeleteVariables
}) => {
  const { t } = useTranslation();
  const [ variables, setVariables ] = useState<SelectedVariablesItem[]>([]);
  const [ isShowAll, setShowAll ] = useState(false);

  const mergeVariablesKeys = (prev: SelectedVariablesItem[], ids: string[], checked: boolean) => {
    if (!checked) return prev.filter(({ id }) => !ids.includes(id));
    return [ ...prev, ...ids.filter(id => !prev.find(item => item.id === id)).map(id => ({ id, required: false })) ];
  };

  const handleToggleVariable = (variable: IVariableItem, checked: boolean) => {
    setVariables(prev => mergeVariablesKeys(prev, [ variable.id ], checked));
  };

  const handleRemoveVariable = (variableId: string) => {
    setVariables(prev => prev.filter(item => item.id !== variableId));
    setValue(variableId, '');
    document.body.style.overflow = 'auto';
  };

  const onSubmit = (data: { [key: string]: string }) => {
    onSave(Object.keys(data).reduce((acc: { [key: string]: string }, current) => (
      data[current] ? { ...acc, [current]: data[current] } : acc
    ), {}));
  };

  const { register, control, reset, setValue, handleSubmit, formState: { errors, isValid } } = useForm({
    mode: 'onChange',
    defaultValues: counteragent?.attrValues
  });

  useEffect(() => {
    if (isLoading) return;

    if (type) setVariables(contragent_default_variables[type]);
    if (counteragent) {
      const attrs = Object.keys(counteragent.attrValues);
      setVariables(prev => mergeVariablesKeys(prev, attrs, true));

      const values: { [key: string]: string } = {};
      // TODO: проверить актуальность кода
      Object.keys(counteragent.attrValues).forEach(key => {
        const item = availableVariables?.[key] || userVariables?.[key];
        const value = counteragent.attrValues[key];
        if (item && value) values[key] = value;
      });
      reset(values);
    }
    // eslint-disable-next-line
  }, [isLoading]);

  const tattrs = (variables.length && availableVariables && Object.keys(availableVariables).length && userVariables) ? (
    variables.reduce((acc: (IVariableItem|IUserVariableItem)[], current) => {
      const item = availableVariables[current.id] ? availableVariables[current.id] : userVariables[current.id];
      return item ? [ ...acc, { ...item, required: current.required ?? false } ] : acc;
    }, [])
  ) : null;

  const groupsArray = variablesGroups &&
  Object.values(variablesGroups).reduce((acc: IVariablesHierarhyItem['groups'], { groups }) => [ ...acc, ...groups ], []);

  const foundTattrs = new Set();

  const groupTattr = groupsArray?.reduce((acc: IGroupWithSearchedTattrFolders[], group : Omit<IGroupWithSearchedTattrFolders, 'tattrFolder'>) => {
    if (tattrs) {
      const tattrInside = tattrs.filter((elem) => {
        const isIncluded = group.items.includes(elem.id) || group.groupId === (elem as IUserVariableItem).folderid;
        const isAlreadyFound = foundTattrs.has(elem.id);
        if (isIncluded && !isAlreadyFound) {
          foundTattrs.add(elem.id);
          return true;
        }
        return false;
      });

      if (tattrInside?.length) {
        acc.push({ ...group, tattrFolder: [...tattrInside] });
      }
    }
    return acc;
  }, []);

  return (
    <div className={styles.root}>
      <div className={styles.container}>
        {tattrs ? (
          groupTattr && groupTattr.map((elem) => (
            <FillFolder
              key={elem.name}
              name={elem.name}
              tattrs={elem.tattrFolder}
              register={register}
              errors={errors}
              control={control}
              setValue={setValue}
              values={counteragent?.attrValues}
              onRemove={canDeleteVariables ? handleRemoveVariable : undefined}
              showGroupName
            />
          ))
        ) : (
          <div className={styles.items}>
            {[...Array(10).keys()].map(key => (
              <FillingItemSkelet key={key} />
            ))}
          </div>
        )}
        <ButtonsContainer className={styles.itemsButton}>
          <Button
            onClick={() => setShowAll(!isShowAll)}
            viewStyle="secondary"
            shadow
            disabled={!(availableVariables && type)}
          >
            {t('add_data')}
          </Button>
        </ButtonsContainer>
      </div>

      <ButtonsContainer className={styles.buttons}>
        <LoadingButton
          type="submit"
          isLoading={isSaveLoading}
          // disabled={!(variables.length && availableVariables && isValid)}
          disabled={!isValid}
          onClick={handleSubmit(onSubmit)}
        >
          {counteragent ? t('save') : t('add_contragent')}
        </LoadingButton>
      </ButtonsContainer>

      <SelectVariableModal
        open={isShowAll}
        onClose={() => setShowAll(false)}
        variablesGroups={variablesGroups}
        availableVariables={availableVariables}
        userVariables={userVariables}
        onToggleVar={(id, checked) => handleToggleVariable(id, checked)}
        selectedVariables={variables && Object.values(variables).map(({ id }) => id)}
        icon={checked => checked && <ReactSVG src="/icons/checked.svg" wrapper="span" className={styles.checkedIcon} />}
        onChange={handleToggleVariable}
        onRemove={canDeleteVariables ? handleRemoveVariable : undefined}
      />
    </div>
  );
};

export default ContragentEdit;
