import { IExternalFillList, IExternalFillItem, IExternalFillOwner } from 'interfaces/externalfill.interface';
import { baseApi } from '../store';

export interface IExternalFillData {
  id: string,
  entityId: string,
  templateId: string,
  name: string,
  sideValues: IExternalFillItem['sideValues'],
  attachmentNames: string[]
}

export const externalfillApi = baseApi.enhanceEndpoints({ addTagTypes: ['ExternalFill', 'ExternalFillOwners'] }).injectEndpoints({
  endpoints: builder => ({
    getExternalFills: builder.query<IExternalFillList, {
      from: number, to: number, name?: string, fromCreatedAt?: string, toCreatedAt?: string
    }>({
      query: data => ({
        url: '/externalinfills',
        method: 'GET',
        params: data
      }),
      providesTags: ['ExternalFill']
    }),
    getExternalFillById: builder.query<IExternalFillItem, string>({
      query: id => ({
        url: `/externalinfills/${id}`,
        method: 'GET'
      }),
      providesTags: (result, error, id) => [{ type: 'ExternalFill', id }]
    }),
    createExternalFill: builder.mutation<IExternalFillItem, Omit<IExternalFillData, 'id'>>({
      query: data => ({
        url: '/externalinfills',
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['ExternalFill']
    }),
    duplicateExternalFill: builder.mutation<IExternalFillItem, { id: string }>({
      query: data => ({
        url: `/externalinfills/${data.id}/copy`,
        method: 'POST',
        // body: data
      }),
      invalidatesTags: ['ExternalFill']
    }),
    updateExternalFill: builder.mutation<IExternalFillItem, IExternalFillData>({
      query: ({ id, ...data }) => ({
        url: `/externalinfills/${id}`,
        method: 'PUT',
        body: data
      }),
      invalidatesTags: ['ExternalFill']
    }),
    removeExternalFill: builder.mutation<{ id: string }, string>({
      query: id => ({
        url: `/externalinfills/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['ExternalFill']
    }),
    sendExternalFill: builder.mutation<{ id: string }, { id: string, email: string }>({
      query: ({ id, ...data }) => ({
        url: `/externalinfills/${id}/send`,
        method: 'PUT',
        body: data
      }),
    }),
    downloadExternalFillAttachments: builder.query<string, string>({
      query: id => ({
        url: `/externalinfills/${id}/attachments`,
        method: 'GET',
        responseHandler: (response: Response) => {
          if (!response.ok) return response.text().then(text => JSON.parse(text));
          return response.blob().then(blob => URL.createObjectURL(blob));
        }
      })
    }),

    getExternalFillOwners: builder.query<IExternalFillOwner[], void>({
      query: () => ({
        url: '/externalinfillentities',
        method: 'GET'
      }),
      providesTags: ['ExternalFillOwners']
    }),
    createExternalFillOwner: builder.mutation<IExternalFillOwner, Omit<IExternalFillOwner, 'id'>>({
      query: data => ({
        url: '/externalinfillentities/',
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['ExternalFillOwners']
    }),
    updateExternalFillOwner: builder.mutation<IExternalFillOwner, IExternalFillOwner>({
      query: ({ id, ...data }) => ({
        url: `/externalinfillentities/${id}`,
        method: 'PUT',
        body: data
      }),
      invalidatesTags: ['ExternalFillOwners']
    }),
    removeExternalFillOwner: builder.mutation<{ id: string }, string>({
      query: id => ({
        url: `/externalinfillentities/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['ExternalFillOwners']
    })
  })
});

export const {
  useGetExternalFillsQuery,
  useGetExternalFillByIdQuery,
  useCreateExternalFillMutation,
  useDuplicateExternalFillMutation,
  useUpdateExternalFillMutation,
  useRemoveExternalFillMutation,
  useSendExternalFillMutation,
  useLazyDownloadExternalFillAttachmentsQuery,

  useGetExternalFillOwnersQuery,
  useCreateExternalFillOwnerMutation,
  useUpdateExternalFillOwnerMutation,
  useRemoveExternalFillOwnerMutation
} = externalfillApi;
