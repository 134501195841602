import { baseApi } from '../store';

export const attachmentsApi = baseApi.injectEndpoints({
  tagTypes: ['attachments', 'attachmentsTree', 'attachmentCategories'],
  endpoints: builder => ({
    getAttachments: builder.query({
      query: data => ({
        url: `/attachments/`,
        method: 'GET',
        params: data
      }),
      providesTags: ['attachments']
    }),
    getAttachmentsTree: builder.query({
      query: data => ({
        url: `/attachments/hierarchy`,
        method: 'GET',
        params: data
      }),
      providesTags: ['attachmentsTree']
    }),
    addAttachment: builder.mutation({
      query: data => ({
        url: `/attachments/file`,
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['attachmentsTree']
    }),
    removeAttachment: builder.mutation({
      query: id => ({
        url: `/attachments/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: (result, error, id) => ['attachmentsTree'],
    }),
    getAttachmentById: builder.query({
      query: id => ({
        url: `/attachments/${id}`,
        method: 'GET'
      }),
      providesTags: (result, error, id) => [{ type: 'attachments', id }],
    }),
    updateAttachment: builder.mutation({
      query: ({ id, ...data }) => ({
        url: `/attachments/file/${id}`,
        method: 'PUT',
        body: data
      }),
      onQueryStarted(data, { dispatch, queryFulfilled }) {
        if (data.categories) return;
        const putResult = dispatch(
          attachmentsApi.util.updateQueryData('getAttachmentById', data.id, (draft) => {
            return { ...draft, ...data };
          })
        );
        queryFulfilled.catch(putResult.undo);
      },
      invalidatesTags: (result, error, data) => {
        if (data.categories) return ['attachmentsTree', { type: 'attachments', id: data.id }];
        return ['attachmentsTree'];
      }
    }),
    downloadAttachment: builder.query({
      query: id => ({
        url: `/attachments/${id}/download`,
        method: 'GET',
        responseHandler: (response) => {
          if (!response.ok) return response.text().then(text => JSON.parse(text));
          return response.blob().then(blob => URL.createObjectURL(blob));
        }
      })
    }),
    downloadAttachmentsArchive: builder.query({
      query: data => ({
        url: `/attachments/downloadArchive`,
        method: 'GET',
        params: data,
        responseHandler: (response) => {
          if (!response.ok) return response.text().then(text => JSON.parse(text));
          return response.blob().then(blob => URL.createObjectURL(blob));
        }
      })
    }),
    getAttachmentCategories: builder.query({
      query: () => ({
        url: `/attachmentcategories/`,
        method: 'GET'
      }),
      providesTags: ['attachmentCategories']
    }),
    createAttachmentCategory: builder.mutation({
      query: data => ({
        url: `/attachmentcategories/`,
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['attachmentCategories']
    }),
  })
});

export const {
  useGetAttachmentsQuery,
  useGetAttachmentsTreeQuery,
  useAddAttachmentMutation,
  useRemoveAttachmentMutation,
  useGetAttachmentCategoriesQuery,
  useCreateAttachmentCategoryMutation,
  useGetAttachmentByIdQuery,
  useUpdateAttachmentMutation,
  useLazyDownloadAttachmentQuery,
  useLazyDownloadAttachmentsArchiveQuery
} = attachmentsApi;
