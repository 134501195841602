import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { Button, getFromTo, ThemeContext } from '@forma/forma-ui-kit';
import PageTitle from 'components/PageTitle';
import { AdminLayout } from 'components/Layouts';
import ExternalFillList from 'views/ExternalFill';
import SendExternalFillModal from 'views/ExternalFill/SendExternalFillModal';
import copyToClipboard from 'helpers/copyToClipboard';
import ExternalFillModal from './ExternalFillModal';

import { addNotification } from 'store/notifications/notificationsSlice';
import {
  IExternalFillData,
  useDuplicateExternalFillMutation,
  useGetExternalFillsQuery,
  useRemoveExternalFillMutation,
  useSendExternalFillMutation,
  useUpdateExternalFillMutation
} from 'store/externalfill/externalfillApi';

const landingUrl = process.env.REACT_APP_LANDING_URL;

const ExternalFill = () => {
  const { t } = useTranslation();
  const { state } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { lang } = useContext(ThemeContext);
  const [ searchParams, setSearchParams ] = useSearchParams();
  const [ params, setParams ] = useState<{ [key: string]: string }>({});
  const page = searchParams.get('page');
  const id = searchParams.get('id');

  const { data: fillings, isLoading } = useGetExternalFillsQuery({ ...params, ...getFromTo(page) }/*, { pollingInterval: 5000 }*/);
  const [ duplicateExternalFill ] = useDuplicateExternalFillMutation();
  const [ updateExternalFill ] = useUpdateExternalFillMutation();
  const [ removeFilling ] = useRemoveExternalFillMutation();
  const [ sendExternalFill ] = useSendExternalFillMutation();

  const handleChangeFilter = (params: { [key: string]: string }) => {
    setParams(prev => ({ ...prev, ...params }));
    handleChangePage(1);
  };

  const handleChangePage = (page: number) => {
    setSearchParams(prev => ({ ...Object.fromEntries(prev.entries()), page: String(page) }));
  };

  const handleCopyLink = (key: string) => {
    const res = copyToClipboard(`${landingUrl}/${lang}/filling/${key}`);
    if (res === 'success') dispatch(addNotification({ content: t('link_copied'), type: 'SUCCESS' }));
  };

  const handleEdit = async (data: IExternalFillData) => {
    const res = await updateExternalFill(data);
    if ('data' in res) {
      dispatch(addNotification({ content: t('data_successfully_changed'), type: 'SUCCESS' }));
    }
  };

  const handleRemove = (id: string) => {
    removeFilling(id);
  };

  const handleSend = async (id: string, data: { login: string }) => {
    const res = await sendExternalFill({ id, email: data.login });
    if ('data' in res) {
      dispatch(addNotification({ content: t('email_sended_to_your_counteragent'), type: 'SUCCESS' }));
    }
  };

  const handleDuplicate = async (id: string) => {
    const res = await duplicateExternalFill({ id });
    if ('data' in res) {
      dispatch(addNotification({ content: t('external_fill_created'), type: 'SUCCESS' }));
      navigate('/external-fill', { state: { sendingId: res.data.id } });
    }
  };

  const openedFillingItem = id && fillings?.items.find(item => item.id === id);
  const sendingFillingItem = state?.sendingId && fillings?.items.find(item => item.id === state.sendingId);

  return (
    <AdminLayout
      title={t('external_fill')}
      // breadcrumbs={{ items: [ { name: t('external_fill') } ] }}
    >
      <PageTitle>{t('site_name') + ' – ' + t('external_fill')}</PageTitle>

      {/* temporary solution */}
      <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px', marginBottom: '20px' }}>
        <Button
          as={Link}
          viewStyle="secondary"
          to="/completed-docs"
          size="small"
          shadow
        >
          {t('my_documents')}
        </Button>
        <Button
          as={Link}
          viewStyle="primary"
          to="/external-fill"
          size="small"
        >
          {t('external_fill')}
        </Button>
      </div>

      <ExternalFillList
        openedId={id ?? undefined}
        items={fillings?.items}
        onChangeFilter={handleChangeFilter}
        pagination={{
          isLoading: isLoading,
          onChange: handleChangePage,
          count: fillings?.count ?? 0,
          page: page
        }}
        onCopyLink={handleCopyLink}
        onEdit={handleEdit}
        onRemove={handleRemove}
        onDuplicate={handleDuplicate}
        onSend={handleSend}
      />

      {sendingFillingItem && (
        <SendExternalFillModal
          open={!!sendingFillingItem}
          onSubmit={data => handleSend(sendingFillingItem.id, data)}
          urlKey={sendingFillingItem.urlKey}
        />
      )}
      {openedFillingItem && (
        <ExternalFillModal
          open={!!id}
          data={openedFillingItem}
        />
      )}
    </AdminLayout>
  );
};

export default ExternalFill;
