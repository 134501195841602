import { parse, isMatch } from 'date-fns';

const getDateFromString = (dateString: string) => {
  const dateFormats = [
    // 'yyyy-MM-dd'T'HH:mm:ss.SSS'Z'',
    'yyyy-MM-dd',
    'dd/MM/yyyy HH:mm:ss',
    'MM/dd/yyyy HH:mm:ss',
    'dd-MM-yyyy',
    'MM-dd-yyyy',
    'MM.dd.yyyy',
    'dd.MM.yyyy',
  ];

  for (const dateFormat of dateFormats) {
    const isCorrect = isMatch(dateString, dateFormat);
    if (isCorrect) {
      return parse(dateString, dateFormat, new Date());
    }
  }

  return null;
};

export default getDateFromString;