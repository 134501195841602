import { useState, MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, LoadingButton, Input, ButtonsContainer } from '@forma/forma-ui-kit';
import PageTitle from 'components/PageTitle';
import { MainLayout } from 'components/Layouts';
import RegisterBanner from 'views/account/RegisterBanner';
import getValidationSchema from 'data/validationSchema';

import { useLoginUserMutation } from 'store/auth/authApi';

import styles from './Account.module.css';

interface FormValues {
  login: string,
  password: string
}

const Login = () => {
  const { t } = useTranslation();
  const [ isShowPass, setShowPass ] = useState(false);
  const [ loginUser, { isLoading, isError } ] = useLoginUserMutation();

  const { register, handleSubmit, control, formState: { errors, isValid } } = useForm<FormValues>({
    mode: 'onChange', resolver: yupResolver(getValidationSchema([ 'login', 'passwordLogin' ]))
  });
  const onSubmit: SubmitHandler<FormValues> = (data) => loginUser(data);

  const handleClickPasswordEye = (e: MouseEvent<HTMLImageElement>) => {
    e.preventDefault();
    setShowPass(!isShowPass);
  };

  return (
    <MainLayout
      title={t('login')}
      sideChildren={<RegisterBanner />}
    >
      <PageTitle>{t('site_name') + ' – ' + t('login')}</PageTitle>

      <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.inputs}>
          <Controller
            control={control}
            name="login"
            render={({ field: { value = '', ...rest } }) => (
              <Input
                placeholder={t('placeholders.login')}
                label={t('email_short')}
                showClearButton
                maxLength={255}
                error={!!errors?.login?.message || isError}
                errorMessage={errors?.login?.message && t('errors.' + errors.login.message)}
                {...rest}
                {...register}
                value={value}
                data-testid="email"
              />
            )}
          />
          <Controller
            control={control}
            name="password"
            render={({ field: { value = '', ...rest } }) => (
              <Input
                id="password"
                type={isShowPass ? 'text' : 'password'}
                placeholder="&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;"
                label={t('password')}
                buttons={
                  <img
                    src={isShowPass ? '/icons/eye-closed.svg' : '/icons/eye.svg'}
                    alt=""
                    onMouseDown={handleClickPasswordEye}
                  />
                }
                error={!!errors?.password?.message}
                errorMessage={errors?.password?.message && t('errors.' + errors.password.message)}
                {...rest}
                {...register}
                value={value}
                data-testid="password"
              />
            )}
          />
        </div>
        <ButtonsContainer className={styles.buttonsContainer}>
          <LoadingButton type="submit" disabled={!isValid} isLoading={isLoading} fullWidth shadow data-testid="submit">{t('sign_in')}</LoadingButton>
          <Button as={Link} to="/recover-password" viewStyle="textPrimary" data-testid="forgot">{t('forgot_password')}</Button>
        </ButtonsContainer>
      </form>

      <div className={styles.anotherAction}>
        {t('account.dont_have_account')} <Link to="/register" data-testid="goToRegister">{t('register2')}</Link>
      </div>
    </MainLayout>
  );
};

export default Login;
