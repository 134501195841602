import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { Modal, Input, DateInput, Textarea, FileDropzone } from '@forma/forma-ui-kit';
import ConteragentsSearch from 'views/contragents/ConteragentsSearch';
import CategoriesSelect from '../CategoriesSelect';

import { IAttachmentCategoriesItem } from 'interfaces/attachments.interface';

import styles from './document-add.module.css';

interface DocumentAddProps {
  open: boolean,
  onClose: () => void,
  parentId?: string|null,
  categories: {
    items?: IAttachmentCategoriesItem[],
    onCreate: (data: { name: string, color: string }) => void,
    isLoading: boolean
  },
  onAddDocument: (data: {
    file: File,
    name: string,
    counteragentid: string,
    categories: string[],
    parentid?: string|null,
    description: string,
    dtFrom?: string|null,
    dtTo?: string|null
  }) => void,
  isAddLoading: boolean
}

const DocumentAdd: FC<DocumentAddProps> = ({ open, onClose, parentId, categories, onAddDocument, isAddLoading }) => {
  const { t } = useTranslation();
  const [ file, setFile ] = useState<File|null>(null);
  const [ name, setName ] = useState<string>('');
  const [ contragent, setContragent ] = useState<string|null>(null);
  const [ description, setDescription ] = useState<string>('');
  const [ dtFrom, setDtFrom ] = useState<string|null>(null);
  const [ dtTo, setDtTo ] = useState<string|null>(null);
  const [ selectedCategories, setCategories ] = useState<string[]>([]);
  const [ isShowModalDesciption, setShowModalDescription ] = useState<boolean>(false);
  const [ isShowModalContragent, setShowModalContragent ] = useState<boolean>(false);
  const [ isShowModalCategories, setShowModalCategories ] = useState<boolean>(false);

  useEffect(() => {
    if (!open) {
      setShowModalDescription(false);
      setShowModalContragent(false);
      setShowModalCategories(false);
    }
  }, [open]);

  const handleChangeFiles = (files: File[]) => {
    if (files.length) {
      setFile(files[0]);
      if (!name.length) setName(files[0].name.replace(/\..*$/, ''));
    } else {
      setFile(null);
    }
  };

  const handleSelectCategory = (id: string) => {
    setCategories(prev => [...prev, id]);
  };

  const handleDeselectCategory = (id: string) => {
    setCategories(prev => {
      const next = [...prev];
      next.splice(prev.findIndex(item => item === id), 1);
      return next;
    });
  };

  const handleClickUpload = () => {
    if (!file || !contragent) return null;
    onAddDocument({
      file,
      name,
      counteragentid: contragent,
      categories: selectedCategories,
      parentid: parentId,
      description,
      dtFrom: dtFrom && format(new Date(dtFrom), 'yyyy-MM-dd'),
      dtTo: dtTo && format(new Date(dtTo), 'yyyy-MM-dd')
    });
  };

  return (
    <>
      <Modal
        size="large"
        title={t('adding_new_document')}
        open={open}
        onClose={() => { onClose(); setName(''); setFile(null); }}
        buttons={[
          {
            children: t('to_next'),
            disabled: !(file && name.length > 2),
            viewStyle: 'primary',
            className: styles.nextButton,
            closing: false,
            onClick: () => setShowModalDescription(true)
          }
        ]}
      >
        <Input
          name="document_name"
          containerClass={styles.nameInput}
          placeholder={t('enter_document_name')}
          value={name}
          onChange={e => setName(e.target.value)}
          showClearButton
          maxLength={128}
        />
        <FileDropzone
          className={styles.dropzone}
          // accept={{ 'application/*': [], 'image/*': [], 'text/*': [] }}
          onChange={handleChangeFiles}
        />
      </Modal>
      <Modal
        size="large"
        title={t('add_description_and_valid_date')}
        open={isShowModalDesciption}
        onClose={() => { setShowModalDescription(false); setDescription(''); setDtFrom(null); setDtTo(null); }}
        buttons={[
          {
            children: t('to_next'),
            viewStyle: 'primary',
            className: styles.nextButton,
            closing: false,
            onClick: () => setShowModalContragent(true)
          }
        ]}
      >
        <div className={styles.modalSubtitle}>{name}</div>
        <div className={styles.description}>
          <Textarea
            name="description"
            containerClass={styles.descriptionInput}
            placeholder={t('description')}
            value={description}
            onChange={e => setDescription(e.target.value)}
          />
          <div className={styles.valid}>
            <DateInput
              name="dtFrom"
              label={`${t('valid')} ${t('from')}`}
              containerClass={styles.validInput}
              labelClass={styles.validLabel}
              value={dtFrom}
              onChange={e => setDtFrom(e.target.value)}
              portalId="popup-root"
            />
            <DateInput
              name="dtTo"
              label={t('to')}
              containerClass={styles.validInput}
              labelClass={styles.validLabel}
              value={dtTo}
              onChange={e => setDtTo(e.target.value)}
              portalId="popup-root"
            />
          </div>
        </div>
      </Modal>
      <Modal
        size="large"
        open={isShowModalContragent}
        onClose={() => { setShowModalContragent(false); setContragent(null); }}
        title={t('select_contragent_document_associated')}
        buttons={[
          {
            children: t('to_next'),
            disabled: !contragent,
            viewStyle: 'primary',
            className: styles.nextButton,
            closing: false,
            onClick: () => setShowModalCategories(true)
          }
        ]}
      >
        <div className={styles.modalSubtitle}>{name}</div>
        <div className={styles.counteragents}>
          <ConteragentsSearch
            selected={contragent}
            onClickItem={(counteragent) => setContragent(counteragent.id)}
          />
        </div>
      </Modal>
      <Modal
        size="large"
        open={isShowModalCategories}
        onClose={() => { setShowModalCategories(false); setCategories([]); }}
        title={t('add_file_categories_for_find')}
        buttons={[
          {
            isLoading: isAddLoading,
            children: t('save'),
            disabled: !selectedCategories.length,
            viewStyle: 'primary',
            className: styles.nextButton,
            closing: false,
            onClick: handleClickUpload
          }
        ]}
      >
        <div className={styles.modalSubtitle}>{name}</div>
        <div className={styles.categories}>
          <CategoriesSelect
            {...categories}
            selected={selectedCategories}
            onSelect={handleSelectCategory}
            onDeselect={handleDeselectCategory}
          />
        </div>
      </Modal>
    </>
  );
};

export default DocumentAdd;
