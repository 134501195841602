import { useState, useEffect, FC, ChangeEvent } from 'react';
import { ReactSVG } from 'react-svg';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import classNames from 'classnames';
import { Button, Tooltip, Checkbox, Table, TableRow, TableCell, Switcher, Tags } from '@forma/forma-ui-kit';

import { files_icons, categories_colors } from 'data/mock';
import { IAttachmenHierarhyItem } from 'interfaces/attachments.interface';

import styles from './document-item.module.css';

const countChildsTotal = (items: IAttachmenHierarhyItem[]): number => {
  return items.reduce((total: number, { children }) => (children ? total + countChildsTotal(children) : total), items.length);
};

interface DocumentsItemProps extends IAttachmenHierarhyItem {
  active?: string,
  selected: string[],
  onClickCopy: (id: string) => void,
  onClickCategory?: (id: string) => void,
  onClickAdd: (id: string) => void,
  level?: number,
  onSelectItem: (id: string, offsetParentId?: string) => void,
  onDeselectItem: (id: string, offsetParentId?: string) => void,
  offsetParentId?: string,
  onSelectAll?: (id: string, offsetParentId?: string) => void,
  onDeselectAll?: (id: string, offsetParentId?: string) => void,
  hide?: boolean,
  canAddFiles?: boolean,
  canDownloadFiles?: boolean
}

const DocumentsItem: FC<DocumentsItemProps> = ({
  id, name, ext, counteragent, categories, dtFrom, dtTo, inactive, children,
  active, selected, onClickCopy, onClickCategory, onClickAdd, level = 1, onSelectItem, onDeselectItem, offsetParentId,
  onSelectAll, onDeselectAll, hide = false, canAddFiles, canDownloadFiles
}) => {
  const { t } = useTranslation();
  const [ isOpen, setIsOpen ] = useState<boolean>(false);
  const [ hidden, setHidden ] = useState<boolean>(hide);
  const childTotalCount = children && countChildsTotal(children);

  const handleChangeSelected = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) onSelectItem(id, offsetParentId);
    else onDeselectItem(id, offsetParentId);
  };

  const handleChangeSelectAll = (e: ChangeEvent<HTMLInputElement>) => {
    if (!onSelectAll || !onDeselectAll) return null;
    if (e.target.checked) onSelectAll(id, offsetParentId);
    else onDeselectAll(id, offsetParentId);
  };

  const isSelected = selected.includes(id);

  useEffect(() => {
    if (hide) setTimeout(() => setHidden(true), 500);
    else setHidden(false);
  }, [hide]);

  if (hidden) return null;

  return (
    <>
      <TableRow
        className={classNames(styles.row, inactive && styles.inActive, styles[`level${level}`], hide && styles.hidden)}
        isActive={active === id}
        isSelected={isSelected}
      >
        <TableCell className={styles.cellIcon}>
          <div className={classNames(styles.icons, isOpen && styles.open)}>
            {children &&
              <button className={styles.dropdownBtn} onClick={() => setIsOpen(!isOpen)}>
                <span className={styles.bage}>{childTotalCount}</span>
                <ReactSVG className={styles.dropdownIcon} src="/icons/arrow-right-thin.svg" wrapper="span" />
              </button>
            }
            <img src={files_icons[ext] ? files_icons[ext] : files_icons.other} className={styles.fileIcon} alt="" />
          </div>
        </TableCell>
        <TableCell className={styles.cellName} title={t('doc_name')}>
          <Link className={styles.name} to={`/safe/${id}`}>
            <span>{name}</span>
          </Link>
        </TableCell>
        <TableCell className={styles.cellCategory} title={t('file_category')}>
          <Tags
            className={styles.categories}
            size="small"
            items={categories.slice(0, 2).map(({ id, name, color }) => (
              {
                name: name,
                color: categories_colors[color]?.color,
                backgroundColor: categories_colors[color]?.background,
                onClick: () => onClickCategory && onClickCategory(id)
              }
            ))}
          />
          {categories?.length > 2 && (
            <div className={styles.more}>
              <Tooltip
                className={styles.tooltip}
                position={['bottom center', 'top center']}
                control={<div className={styles.moreLink}>{t('more')} {categories.length-2}</div>}
              >
                <input type="hidden" autoFocus />
                <Tags
                  size="small"
                  items={categories.slice(2, categories.length).map(({ id, name, color }) => (
                    {
                      name: name,
                      color: categories_colors[color]?.color,
                      backgroundColor: categories_colors[color]?.background,
                      onClick: () => onClickCategory && onClickCategory(id)
                    }
                  ))}
                />
              </Tooltip>
            </div>
          )}
        </TableCell>
        <TableCell className={styles.cellContragent} title={t('related_contragent')}>
          <Link to={`/contragents/${counteragent.id}`} className={styles.link}>{counteragent.name}</Link>
        </TableCell>
        <TableCell className={styles.cellValid} title={`${t('valid')} “${t('from')}” “${t('to')}”`}>
          {dtFrom && `${t('from')} ${format(new Date(dtFrom), 'dd.MM.yyyy')}`}
          {(dtFrom && dtTo) && <br />}
          {dtTo && `${t('to')} ${format(new Date(dtTo), 'dd.MM.yyyy')}`}
        </TableCell>
        <TableCell className={styles.cellButtons}>
          <div className={styles.buttons}>
            <Button
              title={t('copy_link')}
              className={styles.button}
              viewStyle="textLight"
              icon={<ReactSVG src="/icons/clip.svg" wrapper="span" />}
              onClick={() => onClickCopy(id)}
            />
            {canAddFiles &&
              <Button
                title={t('add_document')}
                className={styles.button}
                viewStyle="textLight"
                icon={<ReactSVG src="/icons/document-add.svg" wrapper="span" />}
                onClick={() => onClickAdd(id)}
              />
            }
            {canDownloadFiles &&
              <>
                <span className={styles.buttonsDivider} />
                <Tooltip
                  control={
                    <div>
                      <Checkbox
                        id={id}
                        name={id}
                        containerClass={styles.checkbox}
                        checked={isSelected}
                        onChange={handleChangeSelected}
                      />
                    </div>
                  }
                >
                  {isSelected ? t('deselect') : t('select')}
                </Tooltip>
              </>
            }
          </div>
        </TableCell>
      </TableRow>
      {(children && isSelected && onSelectAll && onDeselectAll) && (
        <tr>
          <td colSpan={10}>
            <div className={styles.controls}>
              <Switcher
                name="select_all"
                label={t('select_nested_files')}
                containerClass={styles.selectAll}
                iconClass={styles.selectAllIcon}
                onChange={handleChangeSelectAll}
              />
            </div>
          </td>
        </tr>
      )}
      {(children && isOpen) && (
        <tr>
          <td colSpan={10}>
            <div className={styles.subTable}>
              <Table>
                {children.map(item => (
                  <DocumentsItem
                    {...item}
                    active={active}
                    level={level+1}
                    offsetParentId={level === 1 ? id : offsetParentId}
                    selected={selected}
                    onClickCategory={onClickCategory}
                    onClickAdd={onClickAdd}
                    onSelectItem={onSelectItem}
                    onDeselectItem={onDeselectItem}

                    onClickCopy={onClickCopy}
                    key={item.id}
                  />
                ))}
              </Table>
            </div>
          </td>
        </tr>
      )}
    </>
  );
};

export default DocumentsItem;
