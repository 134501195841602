import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactSVG } from 'react-svg';
import classNames from 'classnames';
import { Button, LoadingButton } from '@forma/forma-ui-kit';
import SideBlock from '../SideBlock';

import { ITemplatesListSides } from 'interfaces/templates.interface';

import styles from './side-panel.module.css';

interface SortSideItem {
  templateId: string,
  sideIndex: number
}

interface SideProps {
  sides: { name: string, sideIndices: SortSideItem[] }[],
  templatesSides?: ITemplatesListSides,
  onSave: () => void,
  isSaveLoading: boolean,
  onAddSide: () => void,
  onRemoveSide: (index: number) => void,
  onRenameSide: (index: number, name: string) => void
}

const SidePanel: FC<SideProps> = ({ sides, templatesSides, onSave, isSaveLoading, onAddSide, onRemoveSide, onRenameSide }) => {
  const { t } = useTranslation();
  const sidesCount = sides ? sides.reduce((prev, current) => (prev + current.sideIndices.length), 0) : 0;
  const templatesSidesCount =
    templatesSides ? Object.values(templatesSides).reduce((prev, current) => (prev + current.sides.length), 0) : 0;

  return (
    <div className={styles.root}>
      <div className={styles.buttons}>
        <LoadingButton
          id="save_templates_pack_button"
          className={styles.saveButton}
          isLoading={isSaveLoading}
          onClick={onSave}
          viewStyle="secondary"
          shadow
          fullWidth
          disabled={sidesCount !== templatesSidesCount}
        >
          {t('save_templates_pack')}
        </LoadingButton>
      </div>
      <div id="templates_pack_fill_controls" className={classNames(styles.controls, 'styled-scrollbar')}>
        {sides?.map(({ name, sideIndices }, index) => (
          <SideBlock
            index={index}
            name={name}
            templatesSides={templatesSides}
            sideIndices={sideIndices}
            onRemove={onRemoveSide}
            onRename={onRenameSide}
            key={index}
          />
        ))}
        <div className={styles.controlsButtons}>
          <Button
            id="add_side_button"
            viewStyle="text"
            className={styles.addButton}
            iconClassName={styles.addButtonIcon}
            fullWidth
            icon={<ReactSVG src="/icons/plus.svg" wrapper="span" />}
            onClick={onAddSide}
          />
        </div>
      </div>
    </div>
  );
};

export default SidePanel;
