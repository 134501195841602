import { FC, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import { useDispatch } from 'react-redux';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { ButtonsContainer, LoadingButton, Modal } from '@forma/forma-ui-kit';
import FillDocument from 'views/FillDocument';

import { ITemplateRenderData, useGetTemplateSidesQuery, useRenderTemplateMutation } from 'store/templates/templatesApi';
import { useAddProductMutation, useGetProductsCategoriesQuery, useGetProductsQuery } from 'store/products/productsApi';
import { useDuplicateExternalFillMutation, useLazyDownloadExternalFillAttachmentsQuery } from 'store/externalfill/externalfillApi';
import { addNotification } from 'store/notifications/notificationsSlice';
import { IExternalFillItem } from 'interfaces/externalfill.interface';
import { TFormatDocument } from 'interfaces/templates.interface';

import styles from './ExternalFillModal.module.css';


interface ExternalFillModalProps {
  open: boolean,
  onClose?: () => void,
  data: IExternalFillItem
}

const InfoItem: FC<{ label: string, value?: string }> = ({ label, value }) => {
  return (
    <div className={styles.infoItem}>
      <div className={styles.infoLabel}>{label}:</div>
      <div className={styles.infoValue}>{value}</div>
    </div>
  );
};

const ExternalFillModal: FC<ExternalFillModalProps> = ({
  open, onClose, data
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id, name, createdAt, readAt, filledAt, templateId, sideValues, attachments, isReady } = data;
  const { data: templateSides } = useGetTemplateSidesQuery(templateId, { skip: !templateId || !open });
  const { data: categories } = useGetProductsCategoriesQuery(undefined, { skip: !open });
  const [ addProduct, { isLoading: isAddLoading } ] = useAddProductMutation();
  const [ duplicateExternalFill ] = useDuplicateExternalFillMutation();
  const [ downloadExternalFillAttachments, { isLoading: isAttachmentsLoading } ] = useLazyDownloadExternalFillAttachmentsQuery();
  const [ renderTemplate, { isLoading: isDownloading } ] = useRenderTemplateMutation();

  const [isFillingValid, setIsFillingValid] = useState<boolean>(false);

  useEffect(() => {
    setIsFillingValid(false);
  }, [open]);

  const handleAddProduct = async (data: { [key: string]: string }) => {
    const res = await addProduct(data);
    if (!('data' in res)) return null;
    dispatch(addNotification({ content: t('product_service_was_added'), type: 'SUCCESS' }));
    return res.data;
  };

  const handleClickRender = (
    name: string,
    sideValues: ITemplateRenderData['sideValues'],
    format: TFormatDocument
  ) => {
    renderTemplate({ id: templateId, name, sideValues, format: format }).unwrap().then(url => {
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = `${name}.${format}`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    }).catch(e => {});
  };

  const handleClickDuplicate = async () => {
    const res = await duplicateExternalFill({ id });
    if ('data' in res) {
      dispatch(addNotification({ content: t('external_fill_created'), type: 'SUCCESS' }));
      navigate('/external-fill', { state: { sendingId: res.data.id } });
      if (onClose) onClose();
    }
  };

  const handleClickDownload = () => {
    downloadExternalFillAttachments(id).unwrap().then(url => {
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = `${name}.zip`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    }).catch(e => {});
  };

  const sides = useMemo(() => templateSides?.sides.map((data, index) => ({ ...data, id: data?.id ?? index+1+'' })), [templateSides]);
  const valueSides = useMemo(() => templateSides && sideValues?.reduce((acc, data, index) => ({
    ...acc,
    [templateSides.sides[index]?.id ?? index+1+'']: {
      ...data,
      id: templateSides.sides[index]?.id ?? index+1+'',
      // tableValues: data.tableValues?.length > 0 ? data.tableValues : null,
      // isValid: true,
      tattrErrors: {}
    }
  }), {}), [sideValues, templateSides]);

  const [ sidesData, setSidesData ] = useState<ITemplateRenderData['sideValues']>(sideValues);
  const handleChangeData = (data: ITemplateRenderData['sideValues']) => {
    setSidesData(data);
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      title={name}
      size="large"
    >
      <div className={styles.info}>
        <InfoItem label={t('created')} value={format(new Date(createdAt), 'dd.MM.yyyy HH:mm')} />
        <InfoItem label={t('opened')} value={readAt && format(new Date(readAt), 'dd.MM.yyyy HH:mm')} />
        <InfoItem label={t('filled_and_sended')} value={filledAt && format(new Date(filledAt), 'dd.MM.yyyy HH:mm')} />
      </div>
      <ButtonsContainer className={styles.buttons}>
        <LoadingButton
          type="submit"
          className={styles.button}
          viewStyle="tertiary"
          size="small"
          onClick={() => handleClickRender(name, sidesData, 'pdf')}
          disabled={!isFillingValid}
          isLoading={isDownloading}
          icon={<ReactSVG src="/icons/download.svg" />}
        >
          {t('download_pdf')}
        </LoadingButton>
        <LoadingButton
          type="submit"
          className={styles.button}
          viewStyle="tertiary"
          size="small"
          onClick={() => handleClickRender(name, sidesData, 'docx')}
          disabled={!isFillingValid}
          isLoading={isDownloading}
          icon={<ReactSVG src="/icons/download.svg" />}
        >
          {t('download_docx')}
        </LoadingButton>
        <LoadingButton
          viewStyle="tertiary"
          size="small"
          className={styles.button}
          icon={<ReactSVG src="/icons/download-file.svg" />}
          onClick={handleClickDownload}
          disabled={!attachments.length || !attachments.every(({ size }) => !!size)}
          isLoading={isAttachmentsLoading}
        >
          {t('download_attachments')}
        </LoadingButton>
        <LoadingButton
          viewStyle="tertiary"
          size="small"
          className={styles.button}
          icon={<ReactSVG src="/icons/copy.svg" />}
          onClick={handleClickDuplicate}
          disabled={!!isReady}
        >
          {t('duplicate_to_filling')}
        </LoadingButton>
      </ButtonsContainer>

      {sides && (
        <FillDocument
          name={name}
          sides={sides}
          onClickDownload={handleClickRender}
          isDownloadLoading={isDownloading}
          valueSides={valueSides}
          categories={categories}
          onAddProduct={handleAddProduct}
          isAddLoading={isAddLoading}
          productsQueryHook={useGetProductsQuery}
          showHead={false}
          showFillMessage={false}
          setIsFillingValid={setIsFillingValid}
          setSidesDataForInnerRenderButton={handleChangeData}
        />
      )}
    </Modal>
  );
};

export default ExternalFillModal;
