import { FC, useEffect, useState } from 'react';
import { ReactSVG } from 'react-svg';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Button, LoadingButton, PaginationProps, Table, PaginationWrapper } from '@forma/forma-ui-kit';
import { selectUserPermissions } from 'store/user/userSlice';
import SafeDocumentsHeader from './SafeDocumentsHeader';
import DocumentAdd from './DocumentAdd';
import DocumentsItem from './DocumentsItem';

import { IAttachmenHierarhyItem, IAttachmentCategoriesItem } from 'interfaces/attachments.interface';

import styles from './safe-documents-list.module.css';

interface SafeDocumentsListProps {
  active?: string,
  items: IAttachmenHierarhyItem[],
  categories: {
    items: IAttachmentCategoriesItem[],
    onCreate: (data: { name: string, color: string }) => void,
    isLoading: boolean
  },
  onClickCopy: (id: string) => void,
  onClickCategory?: (id: string) => void,
  onAddDocument: (data: {
    file: File,
    name: string,
    counteragentid: string,
    categories: string[],
    parentid?: string|null,
    description: string,
    dtFrom?: string|null,
    dtTo?: string|null
  }) => void,
  isAddLoading: boolean,
  isAddSuccess: boolean,
  isDownloadLoading: boolean,
  onChangeSearch: (value: string) => void,
  onChangeFilter: (data: { dtToFrom?: string, dtToTo?: string, dtFilter?: string, categoriesid?: string }) => void,
  onClickDownload: (selectedIds: string[], parentName?: string|null) => void,
  pagination: PaginationProps
}

const findChildsIds = (items?: IAttachmenHierarhyItem[]): string[] => {
  if (!items) return [];
  return items.reduce((acc: string[], current) =>
    (current.children ? [...acc, current.id, ...findChildsIds(current.children)] : [...acc, current.id]), []);
};

const findFileName = (id: string, items: IAttachmenHierarhyItem[]): string|null => {
  for (const item of items) {
    if (item.id === id) return item.filename || item.name;
    else if (item.children) {
      const filename = findFileName(id, item.children);
      if (filename) return filename;
    }
    continue;
  }
  return null;
};

const SafeDocumentsList: FC<SafeDocumentsListProps> = ({
  active, items, categories, onClickCopy, onClickCategory, onAddDocument, isAddLoading, isAddSuccess, isDownloadLoading,
  onChangeSearch, onChangeFilter, onClickDownload, pagination
}) => {
  const { t } = useTranslation();
  const userPermissions = useSelector(selectUserPermissions);
  const canAddFiles = userPermissions?.includes('attachments_add');
  const canDownloadFiles = userPermissions?.includes('attachments_download');
  const [ isShowModalAdd, setShowModalAdd ] = useState<boolean>(false);
  const [ parentId, setParentId ] = useState<string|null>(null);
  const [ selectedIds, setSelectedIds ] = useState<string[]>([]);
  const [ selectedItems, setSelectedItems ] = useState<{ [key: string]: IAttachmenHierarhyItem }>({});
  const [ isHoverDownload, setHoverDownload ] = useState<boolean>(false);

  useEffect(() => {
    if (isAddSuccess) {
      setShowModalAdd(false);
      setParentId(null);
    }
  }, [isAddSuccess]);

  const handleClickAdd = (id: string) => {
    setParentId(id);
    setShowModalAdd(true);
  };

  const handleSelectItem = (id: string, offsetParentId?: string) => {
    if (selectedIds.findIndex(item => item === id) === -1) {
      setSelectedIds(prev => [ ...prev, id ]);

      const itemId = offsetParentId ?? id;
      const item = items.find(item => itemId === item.id);
      if (item && !selectedItems[item.id]) setSelectedItems(prev => ({ ...prev, [item.id]: item }));
    }
  };

  const handleDeselectItem = (id: string, offsetParentId?: string) => {
    const index = selectedIds.findIndex(item => item === id);
    if (index !== -1) {
      setSelectedIds(prev => {
        const next = [...prev];
        next.splice(index, 1);
        return next;
      });
    }

    const itemId = offsetParentId ?? id;
    if (selectedItems[itemId]) {
      const subitemsIds = selectedItems[itemId].children && findChildsIds(selectedItems[itemId].children);
      const hasSelectedChilds = subitemsIds?.reduce((val, current) => (current !== id && selectedIds.includes(current)) || val, false);
      if (!hasSelectedChilds) {
        setSelectedItems(prev => {
          const next = { ...prev };
          delete(next[itemId]);
          return next;
        });
      }
    }
  };

  const handleSelectAll = (id: string) => {
    if (!selectedItems[id].children) return null;
    const subitemsIds = findChildsIds(selectedItems[id].children);
    if (subitemsIds?.length) setSelectedIds(prev => [...new Set([ ...prev, ...subitemsIds ])]);
  };

  const handleDeselectAll = (id: string) => {
    if (!selectedItems[id].children) return null;
    const subitemsIds = findChildsIds(selectedItems[id].children);
    if (subitemsIds?.length) {
      setSelectedIds(prev => {
        const next = [...prev];
        for (const subitemId of subitemsIds) {
          const index = next.findIndex(id => subitemId === id);
          if (index !== -1) next.splice(index, 1);
        }
        return next;
      });
    }
  };

  const handleClickDownload = () => {
    onClickDownload(selectedIds, (selectedIds.length === 1) ? findFileName(selectedIds[0], Object.values(selectedItems)) : null);
  };

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <SafeDocumentsHeader
          categories={categories}
          onChangeSearch={onChangeSearch}
          onChangeFilter={onChangeFilter}
          onClickAdd={() => setShowModalAdd(true)}
          canAddFiles={canAddFiles}
        />
      </div>
      <div className={styles.content}>
        <PaginationWrapper {...pagination}>
          <Table columns={[
            { children: null },
            { children: t('doc_name') },
            { children: t('file_category') },
            { children: t('related_contragent') },
            { children: `${t('valid')} “${t('from')}” “${t('to')}”` }
          ]}>
            {!!Object.keys(selectedItems).length && (
              Object.values(selectedItems).map(item => (
                <DocumentsItem
                  {...item}
                  active={item.id}
                  selected={selectedIds}
                  onClickCopy={onClickCopy}
                  onClickCategory={onClickCategory}
                  onClickAdd={handleClickAdd}
                  onSelectItem={handleSelectItem}
                  onDeselectItem={handleDeselectItem}
                  onSelectAll={handleSelectAll}
                  onDeselectAll={handleDeselectAll}
                  canAddFiles={canAddFiles}
                  canDownloadFiles={canDownloadFiles}
                  key={item.id}
                />
              ))
            )}
            {!!items?.length && (
              items.map(item => {
                // if (selectedItems[item.id]) return null;
                return (
                  <DocumentsItem
                    {...item}
                    active={active}
                    selected={selectedIds}
                    onClickCopy={onClickCopy}
                    onClickCategory={onClickCategory}
                    onClickAdd={handleClickAdd}
                    onSelectItem={handleSelectItem}
                    onDeselectItem={handleDeselectItem}
                    hide={!!selectedItems[item.id]}
                    canAddFiles={canAddFiles}
                    canDownloadFiles={canDownloadFiles}
                    key={item.id}
                  />
                );
              })
            )}
          </Table>
        </PaginationWrapper>
      </div>
      {canAddFiles && (
        <DocumentAdd
          parentId={parentId}
          open={isShowModalAdd}
          onClose={() => { setParentId(null); setShowModalAdd(false); }}
          categories={categories}
          onAddDocument={onAddDocument}
          isAddLoading={isAddLoading}
        />
      )}
      {!!(canDownloadFiles && selectedIds.length) && (
        <div className={styles.topButtons}>
          <Button
            className={styles.resetBtn}
            viewStyle="secondary"
            onClick={() => { setSelectedIds([]); setSelectedItems({}); }}
            icon={<ReactSVG src="/icons/close.svg" wrapper="span" />}
            title={t('reset')}
            shadow
          />
          <LoadingButton
            isLoading={isDownloadLoading}
            className={styles.downloadBtn}
            viewStyle="primary"
            onClick={handleClickDownload}
            onMouseEnter={() => setHoverDownload(true)}
            onMouseLeave={() => setHoverDownload(false)}
            icon={
              <ReactSVG src={
                isDownloadLoading ? ('/icons/loading.svg') : (isHoverDownload ? '/icons/download_hover.svg' : '/icons/download.svg')
              } wrapper="span" />
            }
          >
            <div>{t('download_selected_docs')}</div>
            <div>{t('count_files', { count: selectedIds.length })}</div>
          </LoadingButton>
        </div>
      )}
    </div>
  );
};

export default SafeDocumentsList;
