import { FC, useRef, useState } from 'react';
import { ReactSVG } from 'react-svg';
import classNames from 'classnames';
import { LoadingIndicator } from '@forma/forma-ui-kit';

import styles from './video.module.css';

interface VideoProps {
  src: string,
  className?: string,
  playClassName?: string,
  showControls?: boolean,
  autoPlay?: boolean,
  loop?: boolean
  onPlay?: () => void
}

const Video: FC<VideoProps> = ({ className, playClassName, showControls = true, autoPlay, src, loop, onPlay }) => {
  const ref = useRef<HTMLDivElement>(null);
  const videoRef = useRef<HTMLVideoElement>(null);
  const [ canPlay, setCanPlay ] = useState(false);
  const [ isPlaying, setIsPlaying ] = useState<boolean>(false);

  const handleClickPlay = () => {
    if (!videoRef.current) return;
    videoRef.current.play();
    if (onPlay) onPlay();
  };

  return (
    <div className={classNames(styles.root, className)} ref={ref}>
      {!canPlay ? (
        <div className={styles.loader}>
          <LoadingIndicator color="var(--primary-color)" />
        </div>
      ) : (
        (showControls && !isPlaying) && (
          <button className={classNames(styles.playButton, playClassName)} onClick={handleClickPlay}>
            <ReactSVG src="/icons/play.svg" wrapper="span" />
          </button>
        )
      )}
      <video
        className={styles.video} src={src}
        controls={showControls && isPlaying}
        onPlay={() => setIsPlaying(true)}
        onCanPlay={() => setCanPlay(true)}
        onPause={(e) => {
          const target = e.target as HTMLVideoElement;
          if (target.readyState === 1) return;
          setIsPlaying(false);
        }}
        autoPlay={autoPlay}
        muted={autoPlay}
        loop={loop}
        ref={videoRef}
      />
    </div>
  );
};

export default Video;
