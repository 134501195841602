import { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AdminLayout } from 'components/Layouts';
import { LoadingButton } from '@forma/forma-ui-kit';
import ProductsImportTable from 'views/products/ProductsImportTable';

import { useImportProductsMutation } from 'store/products/productsApi';

import styles from './ProductsImport.module.css';

const ProductsImport = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { state: { header, data } } = useLocation();
  const [ settedHeader, setSettedHeader ] = useState<{ id?: string, name: string, index: number }[]|null>(null);

  const [ importProducts, { isLoading } ] = useImportProductsMutation();

  const onSubmitImport = async () => {
    if (!settedHeader) return null;
    const sendData = data.map((row: string[]) => {
      const next = settedHeader.reduce((acc: { [key: string]: string }, { id, index }: { id?: string, name: string, index: number }) => {
        if (!id) return acc;
        return { ...acc, [id]: row[index] };
      }, {});
      return next;
    });

    const res = await importProducts(sendData);
    if ('data' in res) navigate(`/products`);
  };

  return (
    <div className="styled-scrollbar">
      <AdminLayout
        title={t('will_add_from_file')}
        breadcrumbs={{ items: [{ name: t('catalog_products_and_services'), to: '/products', as: Link }, { name: t('will_add_from_file') } ] }}
      >
        <h2 className={styles.header}>{t('products_and_services')}</h2>
        <ProductsImportTable
          header={header}
          data={data}
          onSetHeader={setSettedHeader}
        />
        <div className={styles.wrapperAddButton}>
          <LoadingButton
            viewStyle="primary"
            onClick={onSubmitImport}
            size="large"
            isLoading={isLoading}
            disabled={!settedHeader}
          >
            {t('add_product_services_count', { count: data.length })}
          </LoadingButton>
        </div>
      </AdminLayout>
    </div>
  );
};

export default ProductsImport;
