import { FC, useState, useRef, useEffect } from 'react';
import update from 'immutability-helper';
import { nanoid } from '@reduxjs/toolkit';
import { DndContext, DragOverlay, DragStartEvent, DragEndEvent } from '@dnd-kit/core';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { restrictToFirstScrollableAncestor } from '@dnd-kit/modifiers';
import { useTranslation } from 'react-i18next';
import { ReactSVG } from 'react-svg';
import classNames from 'classnames';
import { Button, useDebounce } from '@forma/forma-ui-kit';
import SideBlock from '../SideBlock';

import { IUserVariableItem, IVariableItem, IVariablesHierarhyItem } from 'interfaces/variables.interface';
import { ITemplateSide, ITemplateSideGroup } from 'interfaces/templates.interface';

import styles from './side.module.css';

interface SideProps {
  fullScreen?: true,
  sides: ITemplateSide[],
  variablesGroups?: { [key: string]: IVariablesHierarhyItem },
  availableVariables?: { [key: string]: IVariableItem },
  userVariables?: { [key: string]: IUserVariableItem },
  onChange: (sides: ITemplateSide[]) => void,
  onAddVariable: (sideId: string, sideName: string, tattrId: string, tattrName: string, tattrType: string) => void,
  onChangeVariable: (tattrId: string, tattrName: string, tattrType: string) => void,
  onClickAddVariations: (sideId: string) => void
}

const Side: FC<SideProps> = ({
  sides, variablesGroups, availableVariables, userVariables, onClickAddVariations,
  onChange, onAddVariable, onChangeVariable, fullScreen
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  const [ dragIndex, setDragIndex ] = useState<number|null>(null);

  const updateControlsHeight = () => {
    if (!containerRef?.current || fullScreen) return;
    const containerTop = containerRef.current.getBoundingClientRect().top;
    containerRef.current.style.height = window.innerHeight - containerTop + 'px';
  };

  useEffect(() => {
    updateControlsHeight();
    document.addEventListener('scroll', updateControlsHeight);
    return () => document.removeEventListener('scroll', updateControlsHeight);
    // eslint-disable-next-line
  }, [containerRef]);

  const changeTemplateSideName = useDebounce((sideId: string, sideName: string) => {
    window.editor?.execute('changeSideName', { sideId, sideName });
    window.editor?.execute('changeProductsTableSideName', { sideId, sideName });
  }, 300);

  const handleDragStart = ({ active }: DragStartEvent) => {
    setDragIndex(active.data.current?.index);
  };

  const handleDragEnd = ({ active, over }: DragEndEvent) => {
    setDragIndex(null);
    if (over && active.id !== over.id) {
      const oldIndex = active.data.current?.index;
      const newIndex = over.data.current?.index;

      onChange(
        update(sides, {
          $splice: [
            [oldIndex, 1],
            [newIndex, 0, sides[oldIndex]]
          ]
        })
      );
    }
  };

  const handleAddSide = () => {
    onChange([ ...sides, { id: nanoid(8), name: `${t('side')} ${sides.length+1}`, tattrFolders: [], tables: [], carticles: [] }]);
  };

  const handleChangeSide = (data: { id: string, name: string, tattrFolders: ITemplateSideGroup[] }) => {
    const sideIndex = sides.findIndex(({ id }) => data.id === id);
    onChange(
      update(sides, {
        [sideIndex]: {
          $merge: data
        }
      })
    );
    changeTemplateSideName(data.id, data.name);
  };

  const handleRemoveSide = (sideId: string) => {
    const sideIndex = sides.findIndex(({ id }) => sideId === id);
    onChange(
      update(sides, {
        $splice: [[ sideIndex, 1 ]]
      })
    );
    window.editor?.execute('removeVariables', { sideId });
  };

  return (
    <div className={classNames(styles.controls, 'styled-scrollbar')} ref={containerRef}>
      <DndContext onDragStart={handleDragStart} onDragEnd={handleDragEnd}>
        <SortableContext items={sides} strategy={verticalListSortingStrategy}>
          {sides.map(({ id, name, tattrFolders }, index) => (
            <SideBlock
              id={id}
              index={index}
              name={name}
              folders={tattrFolders}
              opacity={dragIndex === index ? 0 : 1}
              variablesGroups={variablesGroups}
              availableVariables={availableVariables}
              userVariables={userVariables}
              onAddVariable={onAddVariable}
              onChangeVariable={onChangeVariable}
              onClickAddVariations={onClickAddVariations}
              onRemove={handleRemoveSide}
              onChange={handleChangeSide}
              fullScreen={fullScreen}
              key={id}
            />
          ))}
        </SortableContext>
        {(dragIndex || dragIndex === 0) && (
          <DragOverlay zIndex={10} modifiers={[ restrictToFirstScrollableAncestor ]}>
            <SideBlock
              id={sides[dragIndex].id}
              index={dragIndex}
              name={sides[dragIndex].name}
              folders={sides[dragIndex].tattrFolders}
              availableVariables={availableVariables}
              onAddVariable={onAddVariable}
              onChangeVariable={onChangeVariable}
              onClickAddVariations={onClickAddVariations}
              onRemove={handleRemoveSide}
              onChange={handleChangeSide}
              fullScreen={fullScreen}
              isDragging
            />
          </DragOverlay>
        )}
      </DndContext>
      <div className={styles.controlsButtons}>
        <Button
          id="add_side_button"
          viewStyle={fullScreen ? 'tertiary' : 'secondary'}
          className={styles.buttonAdd}
          icon={<ReactSVG src="/icons/plus.svg" className={styles.iconAdd} />}
          onClick={handleAddSide}
          fullWidth
          shadow
        >
          {t('add_side')}
        </Button>
      </div>
    </div>
  );
};

export default Side;
