import { FC, SyntheticEvent, useContext, useState } from 'react';
import { ReactSVG } from 'react-svg';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { format } from 'date-fns';
import { Button, ContextMenu, Input, RemoveModal, TableCell, TableRow, ThemeContext } from '@forma/forma-ui-kit';
import SendExternalFillModal from '../SendExternalFillModal';
import ExternalFillModal from '../../../pages/ExternalFill/ExternalFillModal';

import { IExternalFillItem } from 'interfaces/externalfill.interface';
import { ITemplateRenderData, useRenderTemplateMutation } from 'store/templates/templatesApi';

import styles from './external-fill-item.module.css';

interface ExternalFillItemProps {
  defaultOpen?: boolean,
  data: IExternalFillItem
  onCopyLink: (key: string) => void,
  onEditName: (name: string) => void,
  onRemove: (id: string) => void,
  onSend: (id: string, data: { login: string }) => void,
  onClickDuplicate: () => void,
}

const ExternalFillItem: FC<ExternalFillItemProps> = ({
  defaultOpen, data, onCopyLink, onClickDuplicate, onEditName, onRemove, onSend
}) => {
  const { id, name, createdAt, readAt, filledAt, isReady, urlKey, templateId, sideValues } = data;
  const { t } = useTranslation();
  const { viewport } = useContext(ThemeContext);
  const [ isShowEditModal, setShowEditModal ] = useState<boolean>(!!defaultOpen);
  const [ isShowRemove, setShowRemove ] = useState<boolean>(false);
  const [ isShowSend, setShowSend ] = useState<boolean>(false);
  const [ isEditName, setEditName ] = useState<boolean>(false);
  const [ tmpName, setTmpName ] = useState<string>(name);

  const [ renderTemplate ] = useRenderTemplateMutation();

  const handleClickInput = (e: SyntheticEvent<HTMLInputElement>) => {
    e.stopPropagation();
  };

  const handleClickEditName = (e: SyntheticEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setEditName(true);
  };

  const handleClickSubmitName = (e: SyntheticEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    onEditName(tmpName);
    setEditName(false);
  };

  const handleRenderTemplate = (data: { id: string, name: string, sideValues: ITemplateRenderData['sideValues'] }) => {
    renderTemplate(data).unwrap().then(url => {
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = `${name}.pdf`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    }).catch(e => {});
  };

  return (
    <TableRow className={styles.item}>
      <TableCell className={styles.icon} onClick={() => setShowEditModal(true)}>
        <ReactSVG src={`/icons/external-fill/${filledAt ? 'ready' : (readAt ? 'sended' : 'new')}.svg`} wrapper="span" />
      </TableCell>
      <TableCell className={styles.name} onClick={() => setShowEditModal(true)}>
        <div className={styles.nameInner}>
          {isEditName ? (
            <>
              <Input
                name={`fill_name_${id}`}
                onChange={e => setTmpName(e.target.value)}
                value={tmpName}
                className={styles.editInput}
                onClick={handleClickInput}
              />
              <Button
                viewStyle="primary"
                className={styles.submitEdit}
                icon={<ReactSVG src="/icons/tick.svg" wrapper="span" />}
                iconClassName={styles.submitEditIcon}
                title={t('submit')}
                onClick={handleClickSubmitName}
                disabled={tmpName.length < 3}
              />
            </>
          ) : (
            <>
              {name}
              {viewport !== 'phone' && (
                <Button
                  viewStyle="textLight"
                  className={styles.edit}
                  icon={<ReactSVG src="/icons/edit.svg" wrapper="span" />}
                  title={t('edit')}
                  onClick={handleClickEditName}
                />
              )}
            </>
          )}
        </div>
      </TableCell>
      <TableCell className={styles.created} onClick={() => setShowEditModal(true)}>
        {viewport === 'phone' && <span className={styles.label}>{t('created')}:</span>} {format(new Date(createdAt), 'dd.MM.yyyy HH:mm')}
      </TableCell>
      <TableCell className={styles.readed} onClick={() => setShowEditModal(true)}>
        {viewport === 'phone' && <span className={styles.label}>{t('readed')}:</span>}  {readAt && format(new Date(readAt), 'dd.MM.yyyy HH:mm')}
      </TableCell>
      <TableCell className={styles.filled} onClick={() => setShowEditModal(true)}>
        {viewport === 'phone' && <span className={styles.label}>{t('filled')}:</span>}  {filledAt && format(new Date(filledAt), 'dd.MM.yyyy HH:mm')}
      </TableCell>
      <TableCell className={styles.buttons}>
        <div className={styles.buttonsInner}>
          <ContextMenu
            offsetY={8}
            control={open => (
              <button className={classNames(styles.optionsButton, open && styles.active)}>
                <ReactSVG src="/icons/more.svg" />
              </button>
            )}
            items={[
              {
                text: t('download'),
                disabled: !isReady,
                onClick: () => handleRenderTemplate({ id: templateId, name, sideValues })
              },
              {
                text: t('copy_link'),
                onClick: () => onCopyLink(urlKey),
                disabled: !urlKey
              },
              {
                text: t('duplicate'),
                onClick: () => onClickDuplicate(),
                disabled: !!isReady
              },
              {
                text: t('delete'),
                onClick: () => setShowRemove(true)
              },
              {
                text: t('send_to_email'),
                onClick: () => setShowSend(true),
                disabled: !urlKey
              }
            ]}
          />
        </div>
        <RemoveModal
          open={isShowRemove}
          onClose={() => setShowRemove(false)}
          onRemove={() => onRemove(id)}
          itemName={name}
        />
        <SendExternalFillModal
          open={isShowSend}
          onClose={() => setShowSend(false)}
          onSubmit={data => onSend(id, data)}
          urlKey={urlKey}
        />
        <ExternalFillModal
          open={isShowEditModal}
          onClose={() => setShowEditModal(false)}
          data={data}
        />
      </TableCell>
    </TableRow>
  );
};

export default ExternalFillItem;
