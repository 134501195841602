import { FC, useEffect, useState } from 'react';
import { ReactSVG } from 'react-svg';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { Button, Modal, Table, TableCell, TableRow } from '@forma/forma-ui-kit';
import SelectSwitcher from 'views/FilesEditor/SelectSwitcher';

import styles from './products-import-table.module.css';

import { productTattrs } from 'data/mock';

interface ProductsImportTableProps {
  data: (string[])[],
  header: { name: string, id?: string, index: number }[],
  onSetHeader: (data: { id?: string, name: string, index: number }[]|null) => void,
}

const ProductsImportTable: FC<ProductsImportTableProps> = ({ data: defaultData, header: defaultHeader, onSetHeader }) => {
  const { t } = useTranslation();
  const [ header, setHeader ] = useState(defaultHeader);
  const [ data, setData ] = useState(defaultData);
  const [ changingColIndex, setChangingColIndex ] = useState<number|null>(null);
  const [ selectedVariables, setSelectedVariables ] = useState<string[]>([]);

  useEffect(() => {
    if (header?.filter(item => !item.id).length === 0) onSetHeader(header);
    else onSetHeader(null);
    // eslint-disable-next-line
  }, [header]);

  const onToggleVar = ({ id, name }: { id: string, name: string }, checked: boolean) => {
    if (changingColIndex === null) return;

    setSelectedVariables(prev => [ ...prev, id ]);
    setHeader(prev => {
      const next = [...prev];
      const oldIndex = prev.findIndex(item => id === item.id);
      if (oldIndex !== -1) delete(next[oldIndex].id);
      next[changingColIndex].id = id;
      next[changingColIndex].name = name;
      return next;
    });
    setChangingColIndex(null);
  };

  const handleClickChange = (index: number) => {
    setChangingColIndex(index);
  };

  const handleDeleteColumn = (index: number) => {
    setData(prev => (
      [...prev].map(row => {
        const next = [...row];
        next.splice(index, 1);
        return next;
      })
    ));
    setHeader(prev => {
      const next = [...prev];
      next.splice(index, 1);
      return next;
    });
  };

  const headers = !!header?.length && header.map((head, index) => {
    return {
      className: styles.column,
      children: (
        <div className={styles.headerItem}>
          <div className={classnames(styles.nameVariable, head.id && styles.selected) }>{head.name}</div>
          <Button
            viewStyle={head.id ? 'secondary' : 'primary'}
            onClick={() => { handleClickChange(index); }}
            size="extraSmall"
          >
            {t('assign_variable')}
          </Button>
          <Button
            viewStyle="secondary"
            onClick={() => handleDeleteColumn(index)}
            size="extraSmall"
          >
            {t('delete_column')}
          </Button>
        </div>
      )
    };
  });

  return (
    <div className={classnames(styles.root, 'styled-scrollbar')}>
      <Table columns={headers}>
        {data.slice(0, 5).map((row, index) => (
          <TableRow
            className={classnames(
              index === 3 && styles.opacityRowPreLast,
              index === 4 && styles.opacityRowLast
            )}
            key={index}
          >
            {row.map((cell, cellIndex) => (
              <TableCell className={styles.wrapperSaveTableCell} key={`${index}_${cellIndex}`}>
                <div className={styles.saveTableCell}>{cell}</div>
              </TableCell>
            ))}
          </TableRow>
        ))}
      </Table>
      <Modal
        size="medium"
        open={!!changingColIndex || changingColIndex === 0}
        onClose={() => setChangingColIndex(null)}
        title={t('add_variable')}
      >
        <div className={styles.dropdownSwitches}>
          {productTattrs.map(({ id, name }) => (
            <div className={styles.dropdownSwitch} key={id}>
              <SelectSwitcher
                label={name}
                onChange={checked => onToggleVar({ id, name }, checked)}
                checked={!!selectedVariables?.includes(id)}
                icon={checked => checked &&
                  <ReactSVG src="/icons/checked.svg" wrapper="span" className={styles.checkedIcon} />
                }
              />
            </div>
          ))}
        </div>
      </Modal>
    </div>
  );
};

export default ProductsImportTable;
