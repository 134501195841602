import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { PaginationWrapper, PaginationProps } from '@forma/forma-ui-kit';
import NoItemsVideo from 'views/onboards/NoItemsVideo';
import ContragentsHeader from './ContragentsHeader';
import { ContragentsListPhysics, ContragentsListJuristic } from './ContragentsList';

import { ICounteragentsItem, ICounteragentType } from 'interfaces/counteragents.interface';

import styles from './contragents.module.css';

interface ContragentsProps {
  items?: ICounteragentsItem[],
  type: ICounteragentType,
  onChangeSearch: (value: string) => void,
  pagination: PaginationProps,
  onClickImport: () => void,
  onRemove: (id: string) => void
}

const staticUrl = process.env.REACT_APP_STATIC_URL;

const Contragents: FC<ContragentsProps> = ({ items, type, onChangeSearch, pagination, onClickImport, onRemove }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <ContragentsHeader type={type} onChangeSearch={onChangeSearch} onClickImport={onClickImport} />
      </div>
      <div className={styles.content}>
        {(items && pagination.count === 0 && !pagination.isLoading) ? (
          <NoItemsVideo
            title={t('you_not_nave_contragents')}
            src={`${staticUrl}/videos/onboarding/counteragents_no_items.mp4`}
          />
        ) : (
          <PaginationWrapper {...pagination}>
            {type === 'person' ? (
              <ContragentsListPhysics items={items} onRemove={onRemove} />
            ) : (
              <ContragentsListJuristic items={items} onRemove={onRemove} />
            )}
          </PaginationWrapper>
        )}
      </div>
    </div>
  );
};

export default Contragents;
