import { FC } from 'react';
import { ReactSVG } from 'react-svg';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { Button, RemoveModal, Table, TableCell, TableRow } from '@forma/forma-ui-kit';
import { ICounteragentsItem } from 'interfaces/counteragents.interface';

import styles from './contragents-list.module.css';

interface ContragentsListProps {
  items?: ICounteragentsItem[],
  onRemove: (id: string) => void
}

const ContragentsListJuristic: FC<ContragentsListProps> = ({ items, onRemove }) => {
  const { t } = useTranslation();

  return (
    <div className={classNames(styles.root, 'styled-scrollbar')}>
      <Table columns={[ { children: t('juristic_name') }, { children: t('inn') } ]}>
        {!!(items?.length) && (
          items.map(({ id, fullName, inn }) => (
            <TableRow key={id}>
              <TableCell className={styles.juristicName} title={t('juristic_name')}>
                <Link to={`/contragents/${id}`} className={styles.link}>{fullName}</Link>
              </TableCell>
              <TableCell className={styles.inn} title={t('inn')}>
                <Link to={`/contragents/${id}`} className={styles.link}>{inn}</Link>
              </TableCell>
              <TableCell className={styles.buttons}>
                <RemoveModal
                  control={
                    <Button
                      title={t('delete')}
                      className={styles.remove}
                      viewStyle="textLight"
                      icon={<ReactSVG src="/icons/trash.svg" wrapper="span" />}
                    />
                  }
                  onRemove={() => onRemove(id)}
                  title={t('do_yo_want_delete_contragent')}
                />
              </TableCell>
            </TableRow>
          ))
        )}
      </Table>
    </div>
  );
};

export default ContragentsListJuristic;
