import { FC, useState } from 'react';
import { ReactSVG } from 'react-svg';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, Modal } from '@forma/forma-ui-kit';
import classNames from 'classnames';

import { ISharedTemplatesItem } from 'interfaces/sharedTemplates.interface';

import styles from './static-templates-list-item.module.css';

interface StaticTemplatesListItemProps extends ISharedTemplatesItem {
  searchValue?: string,
  onClickAdd: (id: string) => Promise<string|null>
}

const StaticTemplatesListItem: FC<StaticTemplatesListItemProps> = ({ id, name, previews, searchValue, onClickAdd }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [ addedName, setAddedName ] = useState<string|null>(null);
  const [ isOver, setIsOver ] = useState<boolean>(false);

  const handleClickAdd = async () => {
    if (addedName) return;
    setIsOver(false);
    const result = await onClickAdd(id);
    setAddedName(result);
  };

  const handleClickUse = async () => {
    setIsOver(false);
    if (!addedName) {
      const result = await onClickAdd(id);
      navigate(`/fill-document/${result}`);
    } else {
      navigate(`/fill-document/${addedName}`);
    }
  };

  const searchIndex = searchValue ? name.toLowerCase().indexOf(searchValue.toLowerCase()) : -1;
  const endIndex = searchValue ? searchIndex + searchValue.length : -1;

  return (
    <div
      className={classNames(styles.root, isOver && styles.hover)}
      onMouseEnter={() => setIsOver(true)}
      onMouseLeave={() => setIsOver(false)}
    >
      <img className={styles.icon} src="/icons/explorer/file_forma.svg" alt="" />
      <div className={styles.name}>
        {searchValue && searchIndex !== -1 ? <>
          {name.slice(0, searchIndex)}<span className="accent-text">{name.slice(searchIndex, endIndex)}</span>{name.slice(endIndex)}
        </> : name}
      </div>
      {addedName && <div className={styles.label}>{t('added_to_my_templates')}</div>}
      <div className={classNames(styles.buttons)}>
        <Button
          viewStyle="primary"
          size="extraSmall"
          className={styles.button}
          onClick={handleClickUse}
          shadow
        >
          {t('use_template')}
        </Button>
        <Modal
          control={
            <Button
              viewStyle="tertiary"
              size="extraSmall"
              className={styles.button}
            >
              {t('preview')}
            </Button>
          }
          titleClassName={styles.modalTitle}
          title={
            <>
              {name}
              <Button
                viewStyle="primary"
                onClick={handleClickUse}
              >
                {t('use_this_template')}
              </Button>
            </>
          }
        >
          <div>
            {previews.map(({ url }) => (
              <img className={styles.modalImage} width="100%" src={url} alt={name} key={url} />
            ))}
          </div>
        </Modal>
        <Button
          viewStyle="tertiary"
          size="extraSmall"
          className={classNames(styles.button, styles.addButton)}
          onClick={handleClickAdd}
          icon={<ReactSVG src="/icons/plus.svg" wrapper="span" />}
          title={t('add_to_my_templates')}
        />
      </div>
    </div>
  );
};

export default StaticTemplatesListItem;
