import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { ButtonsContainer, Button, SearchInput, useDebounce, PaginationWrapper, getFromTo } from '@forma/forma-ui-kit';

import { ICounteragentType, ICounteragentsItem } from 'interfaces/counteragents.interface';

import { useGetCounteragentsQuery } from 'store/counteragents/counteragentsApi';

import styles from './conteragents-search.module.css';

interface ContragentsSearchProps {
  selected?: string|null,
  onClickItem: (counteragent: ICounteragentsItem) => void,
}

const contragentTypes: ICounteragentType[] = [ 'entity', 'individual', 'person' ];

const ConteragentsSearch: FC<ContragentsSearchProps> = ({
  selected, onClickItem
}) => {
  const { t } = useTranslation();
  const [ searchValue, setSearchValue ] = useState<string>('');
  const [ page, setPage ] = useState<number>(1);
  const [ params, setParams ] =
    useState<{ from: number, to: number, type: ICounteragentType, filter?: string }>({ ...getFromTo(1), type: contragentTypes[0] });

  const { data: counteragents, isFetching } = useGetCounteragentsQuery(params, { skip: !params.type });

  const changeSearchDebounce = useDebounce((value: string) => {
    setParams(prev => ({ ...prev, filter: value }));
  }, 500);

  const handleChangeSearch = (value: string) => {
    setSearchValue(value);
    changeSearchDebounce(value);
  };

  const handleChangeFilter = (values: { type: ICounteragentType }) => {
    setParams(prev => ({ ...prev, ...values }));
  };

  const handleChangePage = (page: number) => {
    setPage(page);
    setParams(prev => ({ ...prev, ...getFromTo(page) }));
  };

  return (
    <div className={styles.root}>
      <ButtonsContainer className={styles.head}>
        {contragentTypes.map(item => (
          <Button
            onClick={() => handleChangeFilter({ type: item })}
            viewStyle={params.type === item ? 'primary' : 'secondary'}
            size="small"
            shadow
            key={item}
          >
            {t(`types_${item}`)}
          </Button>
        ))}
      </ButtonsContainer>

      <div className={classNames(styles.container, searchValue && styles.active)}>
        <SearchInput
          onSearch={handleChangeSearch}
          className={styles.search}
          placeholder={params.type === 'entity' ? t('enter_inn_or_organization_name') : t('name_surname_lastname')}
        />
        <div className={styles.items}>
          <PaginationWrapper
            isLoading={isFetching}
            page={page}
            onChange={handleChangePage}
            count={counteragents?.count ?? 0}
          >
            {!!(counteragents?.items.length) && (
              counteragents?.items.map(item => (
                <div
                  className={classNames(styles.item, (selected && item.id === selected) && styles.selected)}
                  onClick={() => onClickItem(item)}
                  key={item.id}
                >
                  <span>{item.fullName || item.name || item.legalName}</span>
                  <span>{item.inn}</span>
                </div>
              ))
            )}
          </PaginationWrapper>
        </div>
      </div>
    </div>
  );
};

export default ConteragentsSearch;
