import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Table } from '@forma/forma-ui-kit';
import ExternalFillItem from '../ExternalFillItem';

import { IExternalFillData } from 'store/externalfill/externalfillApi';
import { IExternalFillItem } from 'interfaces/externalfill.interface';

import styles from './external-fill-list.module.css';

interface ExternalFillListProps {
  openedId?: string,
  items?: IExternalFillItem[],
  onCopyLink: (key: string) => void,
  onEdit: (data: IExternalFillData) => void,
  onRemove: (id: string) => void,
  onSend: (id: string, data: { login: string }) => void,
  onDuplicate: (id: string) => void,
}

const ExternalFillList: FC<ExternalFillListProps> = ({ openedId, items, onCopyLink, onEdit, onRemove, onSend, onDuplicate }) => {
  const { t } = useTranslation();

  return (
    <div className={classNames(styles.root, 'styled-scrollbar')}>
      <Table columns={[ { children: '' }, { children: t('name') }, { children: t('created') }, { children: t('readed') }, { children: t('filled') } ]}>
        {!!(items?.length) && (
          items.map(item => (
            <ExternalFillItem
              defaultOpen={openedId === item.id}
              data={item}
              onCopyLink={onCopyLink}
              onRemove={onRemove}
              onSend={onSend}
              onEditName={name => onEdit({
                ...item,
                name,
                attachmentNames: item.attachments.map(({ name }) => name),
                entityId: item.entity.id
              })}
              onClickDuplicate={() => onDuplicate(item.id)}
              key={item.id}
            />
          ))
        )}
      </Table>
    </div>
  );
};

export default ExternalFillList;
