import classNames from 'classnames';
import { SideBlockPlaceholder } from '../SideBlock';

import styles from './side-panel.module.css';

const SidePanelPlaceholder = () => {
  return (
    <div className={styles.root}>
      <div className={styles.buttons}>
        <div className={classNames(styles.placeholderButton, 'skeleton-loader')} />
      </div>
      <div className={styles.controls}>
        <SideBlockPlaceholder />
        <div className={styles.controlsButtons}>
          <div className={classNames(styles.placeholderButton, 'skeleton-loader')} />
        </div>
      </div>
    </div>
  );
};

export default SidePanelPlaceholder;
