import { FC, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import {
  ButtonsContainer,
  Button,
  LoadingButton,
  Tabs,
  DocumentName,
  FillForm,
  FillingItemSkelet,
  FillVariations,
  FillProducts,
  calculateTotalValues,
  ThemeContext,
  mapTotalForCalc,
  mapSidesToFilling
} from '@forma/forma-ui-kit';
import DocumentsRequest from './DocumentsRequest';
import SelectOwnerModal from './SelectOwnerModal';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import { selectOnboarding } from 'store/user/userSlice';
import { setOnboardingModal } from 'store/common/commonSlice';
import { ITemplateRenderData } from 'store/templates/templatesApi';
import { useLazyGetCounteragentByIdQuery } from 'store/counteragents/counteragentsApi';
import { ISidesListEditData, ITemplateSide, TFormatDocument } from 'interfaces/templates.interface';
import { IProductsCategoriesItem, IProductsItem } from 'interfaces/products.interface';
import { IExternalFillItem } from 'interfaces/externalfill.interface';

import styles from './fill-document.module.css';
import { ReactSVG } from 'react-svg';
import { analytics } from 'helpers/analytics';

interface ISidesSendingData {
  [sideId: string]: {
    tattrs: string[],
    tables: string[],
    articles: string[]
  }
}

interface FillDocumentProps {
  name?: string,
  sides?: ITemplateSide[],
  onClickDownload: (
    name: string,
    sideValues: ITemplateRenderData['sideValues'],
    formatDocument: TFormatDocument,
    isChanged: boolean
  ) => void,
  onClickRender?: (
    name: string,
    sideValues: ITemplateRenderData['sideValues']
  ) => void,
  onExternalFill?: (
    name: string,
    sideValues: IExternalFillItem['sideValues'],
    attachmentNames: string[],
    ownerId: string
  ) => void,
  isExternalFillLoading?: boolean,
  isDownloadLoading?: boolean,
  valueSides?: ISidesListEditData,
  showNameInput?: boolean,
  counteragentsQueryHook?: any,
  productsQueryHook: any,
  onAddProduct: (data: { [key: string]: string }) => Promise<IProductsItem|null>,
  isAddLoading: boolean,
  categories?: IProductsCategoriesItem[],
  showHead?: boolean,
  showFillMessage?: boolean,
  setIsFillingValid?: (statusValid : boolean) => void;
  setSidesDataForInnerRenderButton?: (data: ITemplateRenderData['sideValues']) => void,
  setNameFileForInnerRender? : (name: string) => void,
  showWordButton?: boolean
}

const FillDocument: FC<FillDocumentProps> = ({
  name: defaultName = '', sides, onClickDownload, isDownloadLoading, onClickRender, onExternalFill, isExternalFillLoading,
  valueSides, showNameInput = false, counteragentsQueryHook, productsQueryHook, categories, onAddProduct, isAddLoading,
  showHead = true, showFillMessage = true, setIsFillingValid, setSidesDataForInnerRenderButton, setNameFileForInnerRender,
  showWordButton = true,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const onboarding = useAppSelector(selectOnboarding);
  const [ currentSide, setCurrentSide ] = useState('');
  const [ sidesData, setSidesData ] = useState<ISidesListEditData|undefined>(valueSides);
  const [ isChanged, setIsChanged ] = useState<boolean>(false);
  const [ name, setName ] = useState<string>(defaultName);
  const [ isShowSelect, setIsShowSelect ] = useState<boolean>(false);
  const [ sendingData, setSendingData ] = useState<ISidesSendingData|null>(null);
  const [ requestDocuments, setRequestDocuments ] = useState<string[]>([]);
  const [ isOwnersOpen, setOwnersOpen ] = useState<boolean>(false);

  const [ getCounteragent ] = useLazyGetCounteragentByIdQuery();

  const { currencies, lang } = useContext(ThemeContext);
  const currency = currencies[lang];

  useEffect(() => {
    if (onboarding && !onboarding.fill_template_modal) dispatch(setOnboardingModal('fill_template_modal'));
    // eslint-disable-next-line
  }, [onboarding]);

  useEffect(() => {
    if (defaultName) setName(defaultName);
  }, [defaultName]);

  useEffect(() => {
    if (valueSides)
      setSidesData(valueSides);
    else if (sides)
      setSidesData(
        sides.reduce((acc, { id, tables, tattrFolders, carticles }) => ({
          ...acc,
          [id]: {
            id,
            tattrErrors: {},
            tattrValues: {},
            tableValues: !!tables?.length ? (
              tables.map(({ id, total, showTotalRow }) => ({
                tableId: id,
                products: {},
                total: calculateTotalValues([], mapTotalForCalc(total, showTotalRow), currency.code),
                customProductsIds: []
              }))
            ) : null,
            articleValues: !!carticles?.length ? carticles.map(({ id, variants }) => (
              { id, value: variants.filter(({ default: byDefault }) => byDefault).map(({ id }) => id) }
            )) : null,
            isValid: !tattrFolders.length && !carticles.length,
          },
        }), {})
      );

    if (sides) setCurrentSide(sides[0].id);
  }, [sides, valueSides, currency]);

  const handleChangeForm = (sideId: string, tattrValues: { [key: string]: string }) => {
    setSidesData(prev => prev && ({ ...prev, [sideId]: { ...prev[sideId], tattrValues } }));
    setIsChanged(true);
  };
  const handleErrorForm = (sideId: string, tattrErrors: { [key: string]: string }) => {
    setSidesData(prev => prev && ({ ...prev, [sideId]: { ...prev[sideId], tattrErrors } }));
  };

  const handleSetProducts = (
    sideId: string,
    tableId: string,
    products: { [id: string]: { [key: string]: string } }, total: { [key: string]: string },
    customProductsIds: string[]
  ) => {
    setSidesData(prev => {
      if (!prev) return prev;
      const tableValues = [ ...prev[sideId].tableValues ];
      const index = tableValues?.findIndex(table => table.tableId === tableId);
      if (!tableValues || index === -1) tableValues.push({ tableId, products, total, customProductsIds });
      else tableValues[index] = { tableId, products, total, customProductsIds };
      return ({ ...prev, [sideId]: { ...prev[sideId], tableValues } });
    });
    setIsChanged(true);
  };

  const handleChangeValid = (sideId: string, valid: boolean) => {
    setSidesData(prev => prev && ({ ...prev, [sideId]: { ...prev[sideId], isValid: valid } }));
  };

  const handleSetCounteragent = async (sideId: string, id: string) => {
    const { data } = await getCounteragent(id);
    if (data) {
      setSidesData(prev => prev && ({ ...prev, [sideId]: { ...prev[sideId], counteragentid: id } }));
      return data;
    } return null;
  };

  const handleSetUpdate = (sideId: string, checked: boolean) => {
    setSidesData(prev => prev && ({ ...prev, [sideId]: { ...prev[sideId], updateCounteragent: checked } }));
  };

  const handleChangeName = (name: string) => {
    setName(name);
    if (setNameFileForInnerRender) {
      setNameFileForInnerRender(name);
    }
  };

  const handleClickDownload = (formatDocument : TFormatDocument = 'pdf') => {
    analytics.dowloadDocument();
    if (!sidesData) return;
    onClickDownload(
      name,
      Object.values(sidesData).map(({ tableValues, tattrErrors, isValid, ...rest }) => ({
        tableValues: tableValues?.map(table => ({ ...table, products: Object.values(table.products) })),
        ...rest
      })),
      formatDocument,
      isChanged
    );
  };

  useEffect(() => {
    if (setSidesDataForInnerRenderButton === undefined || !sidesData) return;
    setSidesDataForInnerRenderButton(Object.values(sidesData).map(({ tableValues, tattrErrors, isValid, ...rest }) => ({
      tableValues: tableValues?.map(table => ({ ...table, products: Object.values(table.products) })),
      ...rest
    })));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sidesData]);

  const handleClickRender = () => {
    if (!sidesData || !onClickRender) return;
    onClickRender(
      name,
      Object.values(sidesData).map(({ tableValues, tattrErrors, isValid, ...rest }) => ({
        tableValues: tableValues?.map(table => ({ ...table, products: Object.values(table.products) })),
        ...rest
      }))
    );
  };

  const handleSetVariations = (sideId: string, id: string, ids: string[]) => {
    if (!sidesData) return;

    setSidesData(prev => {
      if (!prev) return prev;

      const articleValues = [ ...prev[sideId].articleValues ];
      const index = articleValues.findIndex(item => item.id === id);
      if (index === -1) articleValues.push({ id, value: ids });
      else articleValues[index] = { id, value: ids };

      return ({ ...prev, [sideId]: { ...prev[sideId], articleValues } });
    });
  };

  const handleShowSelect = () => {
    if (onboarding && !onboarding.select_external_filling_modal) dispatch(setOnboardingModal('select_external_filling_modal'));

    setIsShowSelect(true);
    if (!sides) return;

    setSendingData(sides.reduce((acc, side) => {
      if (side.id !== currentSide)
        return ({ ...acc, [side.id]: { tattrs: [], tables: [], articles: [] } });

      const preSelectedTattrs = side?.tattrFolders.reduce((acc: string[], { tattrs }) => ([
        ...acc,
        ...tattrs.reduce((acc: string[], { id }) => ((sidesData && sidesData[side.id].tattrValues[id]) ? acc : [ ...acc, id ]), [])
      ]), []);

      const preSelectedTables = side?.tables.reduce((acc: string[], { id }) => {
        if (!sidesData) return acc;
        const table = sidesData[side.id].tableValues.find(table => id === table.tableId);
        if (!table || Object.keys(table.products).length) return acc;
        return [ ...acc, table.tableId ];
      }, []);

      const preSelectedArticles = side?.carticles.reduce((acc: string[], { id }) => {
        if (!sidesData) return acc;
        const carticle = sidesData[side.id].articleValues.find(article => id === article.id);
        if (!carticle || carticle.value.length) return acc;
        return [ ...acc, carticle.id ];
      }, []);

      return ({ ...acc, [side.id]: { tattrs: preSelectedTattrs, tables: preSelectedTables, articles: preSelectedArticles } });
    }, {}));
  };

  const handleHideSelect = () => {
    setIsShowSelect(false);
  };

  const handleSelectFolders = (sideId: string, tattrs: string[]) => {
    setSendingData(prev => {
      if (!prev) return prev;
      return ({ ...prev, [sideId]: { ...prev[sideId], tattrs } });
    });
  };

  const handleSelectTable = (sideId: string, id: string, checked: boolean) => {
    setSendingData(prev => {
      if (!prev) return prev;

      const tables = [ ...prev[sideId].tables ];
      if (checked) tables.push(id);
      else {
        const index = tables.indexOf(id);
        if (index !== -1) tables.splice(index, 1);
      }

      return ({ ...prev, [sideId]: { ...prev[sideId], tables } });
    });
  };

  const handleSelectVariation = (sideId: string, id: string, checked: boolean) => {
    setSendingData(prev => {
      if (!prev) return prev;

      const articles = [ ...prev[sideId].articles ];
      if (checked) {
        articles.push(id);
        // TODO: сделать reset значения вариации и выделение переменных и таблиц внутри вариации

        setSidesData(prev => {
          if (!prev) return prev;
          const articles = [ ...prev[sideId].articleValues ];
          const articleIndex = articles.findIndex(article => article.id === id);
          if (articleIndex !== -1) articles[articleIndex].value = [];
          return {
            ...prev,
            [sideId]: { ...prev[sideId], articleValues: articles, tattrValues: {} }
          };
        });
      } else {
        const index = articles.indexOf(id);
        if (index !== -1) articles.splice(index, 1);
      }

      return ({ ...prev, [sideId]: { ...prev[sideId], articles } });
    });
  };

  const handleSubmitOwner = (ownerId: string) => {
    if (!sidesData || !onExternalFill || !sendingData) return;

    setOwnersOpen(false);

    onExternalFill(
      name,
      Object.values(sidesData).map(({ id, tableValues, articleValues, tattrErrors, tattrValues, isValid, ...rest }) => {
        const sideData = sidesData[id];
        return ({
          id: id,
          // tattrValues: tattrValues,
          tattrValues: Object.keys(sideData.tattrValues)
            .reduce((acc, tattrId) => (
              sendingData[id].tattrs.includes(tattrId) ? acc : { ...acc, [tattrId]: sideData.tattrValues[tattrId] }
            ), {}),
          tableValues: sideData.tableValues?.filter(table => !sendingData[id].tables?.includes(table.tableId))
            .map(table => ({ ...table, products: Object.values(table.products) })),
          articleValues: sideData.articleValues?.filter(article => !sendingData[id].articles?.includes(article.id)),
          ...rest
        });
      }),
      requestDocuments,
      ownerId
    );
  };

  const isFillingValid = !!(name.length >= 3 && sidesData && sides && Object.keys(sidesData).length === sides.length) &&
    Object.values(sidesData).every(({ id, isValid, articleValues, tableValues }) => {
      if (!isValid) return false;
      const side = sides.find(side => String(side.id) === String(id));
      if (!side) return false;
      // if (side.tables?.length && tableValues.length !== side.tables.length) return false;
      if (side.carticles?.length) {
        if (articleValues.length !== side.carticles.length) return false;
        if (!articleValues.every(({ value }) => !!value.length)) return false;
      }
      if (tableValues?.length) {
        if (!tableValues.every(({ products }) => Object.keys(products).length)) return false;
      }
      return true;
    });

  if (setIsFillingValid) setIsFillingValid(isFillingValid);

  const selectedVariants = sidesData && Object.values(sidesData)?.reduce((acc: string[], { articleValues }) => {
    if (!articleValues) return acc;
    return [ ...acc, ...articleValues.reduce((acc: string[], { value }) => ([ ...acc, ...value ]), []) ];
  }, []);

  const sidesToFilling = sides && mapSidesToFilling(sides, selectedVariants);
  const sidesFillingCount = sidesToFilling?.map(({ id, tattrFolders, carticles, tables }) => {
    const sideValues = sidesData && sidesData[id];
    const sendingTattrs = sendingData?.[id].tattrs;
    const sendingArticles = sendingData?.[id].articles;
    const sendingTables = sendingData?.[id].tables;

    let tattrsCount = 0;
    let filledCount = 0;

    tattrFolders.forEach(({ tattrs }) => {
      tattrs.forEach(({ id: tattrId }) => {
        tattrsCount += 1;

        if (sideValues && (
          (!!sideValues.tattrValues[tattrId] && !sideValues.tattrErrors[tattrId])
          || (isShowSelect && sendingTattrs?.includes(tattrId))
        )) filledCount += 1;
      });
    });

    tables?.forEach(({ id }) => {
      tattrsCount += 1;
      const tableProducts = sideValues && sideValues.tableValues.find(table => id === table.tableId)?.products;
      if (
        (tableProducts && Object.keys(tableProducts).length)
        || (isShowSelect && sendingTables?.includes(id))
      ) filledCount += 1;
    });

    carticles?.forEach(({ id }) => {
      tattrsCount += 1;
      if (sideValues && (
        sideValues.articleValues.find(article => id === article.id)?.value?.length
        || (isShowSelect && sendingArticles?.includes(id))
      )) filledCount += 1;
    });

    return { count: filledCount, total: tattrsCount };
  });

  const emptyFieldsCount = sidesFillingCount?.reduce((acc, { count, total }) => acc+total-count, 0);

  return (
    <div className={styles.root}>
      {showHead && (
        <div className={styles.head}>
          <div>
            <DocumentName
              name={name}
              showNameInput={showNameInput}
              onSubmit={handleChangeName}
            />
            {isShowSelect && (
              <Button
                viewStyle="textPrimary"
                className={styles.headButton}
                onClick={() => dispatch(setOnboardingModal('send_external_filling_modal'))}
              >
                {t('guide.what_is_external_fill')}
              </Button>
            )}
          </div>
          {isShowSelect ? (
            <div className={styles.sendingTitle}>
              {t('sending_to_filling_side', { side: sides?.find(({ id }) => currentSide === id)?.name ?? '' })}
            </div>
          ) : (
            <Button
              viewStyle="textPrimary"
              className={styles.headButton}
              onClick={() => dispatch(setOnboardingModal('filling_modal'))}
            >
              {t('guide.howto_create_document')}
            </Button>
          )}
        </div>
      )}
      <Tabs
        contentClassName="fill-document-content"
        dontRemoveContent
        current={currentSide}
        onChange={setCurrentSide}
        items={sidesToFilling?.length ? (
          sidesToFilling.map(({ id, name, tattrFolders, tables, carticles }, index) => {
            const articleValues = sidesData?.[id]?.articleValues;

            return {
              id,
              name,
              className: styles.tabButton,
              testId: `filling_tab_${index}`,
              bage: {
                content: sidesFillingCount && `${sidesFillingCount[index].count}/${sidesFillingCount[index].total}`
              },
              children: (
                <div key={id}>
                  {!!(sidesData && carticles?.length) && (
                    <div className={styles.variations}>
                      {sidesData && carticles?.map((item) => (
                        <FillVariations
                          {...item}
                          value={articleValues?.find(({ id }) => id === item.id)?.value ?? []}
                          onChange={(ids) => handleSetVariations(id, item.id, ids)}
                          selected={sendingData ? sendingData[id].articles.includes(item.id) : false}
                          onSelect={checked => handleSelectVariation(id, item.id, checked)}
                          showSelect={isShowSelect}
                          key={item.id}
                        />
                      ))}
                    </div>
                  )}
                  <div className={styles.fillingFields}>
                    <FillForm
                      values={sidesData ? sidesData[id].tattrValues : {}}
                      folders={tattrFolders}
                      onChange={values => handleChangeForm(id, values)}
                      onErrors={errors => handleErrorForm(id, errors)}
                      onChangeValid={valid => handleChangeValid(id, valid)}
                      showSelect={isShowSelect}
                      selected={sendingData && sendingData[id].tattrs}
                      onChangeSelected={tattrs => handleSelectFolders(id, tattrs)}
                      showGroupName
                      showCount
                      counteragents={(!isShowSelect && counteragentsQueryHook) ? {
                        selectedId: sidesData && sidesData[id].counteragentid,
                        onSelect: counteragentId => handleSetCounteragent(id, counteragentId),
                        onSetUpdate: checked => handleSetUpdate(id, checked),
                        queryHook: counteragentsQueryHook
                      } : undefined}
                    />
                    {!!tables?.length && tables.map((table, index) => (
                      <FillProducts
                        data={table}
                        queryHook={productsQueryHook}
                        categories={categories}
                        products={(sidesData?.[id].tableValues[index]?.products) ? sidesData[id].tableValues[index].products : {}}
                        productsTotal={(sidesData?.[id].tableValues[index]?.total) ? sidesData[id].tableValues[index].total : {}}
                        customProductsIds={
                          (sidesData && sidesData[id].tableValues[index]?.customProductsIds) ?
                            sidesData[id].tableValues[index].customProductsIds : []
                        }
                        onChangeTable={(products, total, customProductsIds) => {
                          handleSetProducts(id, table.id, products, total, customProductsIds);
                        }}
                        onAddProduct={onAddProduct}
                        isAddLoading={isAddLoading}
                        selected={sendingData ? sendingData[id].tables.includes(table.id) : false}
                        onSelect={checked => handleSelectTable(id, table.id, checked)}
                        showSelect={isShowSelect}
                        key={table.id}
                      />
                    ))}
                  </div>
                </div>
              )
            };
          })
        ) : (
          [
            {
              id: '0',
              name: '',
              children: (
                <div className={styles.items}>
                  {[...Array(10)].map((key, index) => (
                    <FillingItemSkelet key={index} />
                  ))}
                </div>
              )
            }
          ]
        )}
      />
      {isShowSelect && (
        <div id="add_sending_documents" className={styles.documents}> {/* class for onboarding */}
          <DocumentsRequest
            items={requestDocuments}
            onChange={setRequestDocuments}
          />
        </div>
      )}
      {!!(sides && emptyFieldsCount) && (
        <div className={styles.notice}>
          <div className={styles.emptyFields}>
            {isShowSelect
              ? `${t('count_fields', { count: emptyFieldsCount })} ${t('not_selected_not_filled')}`
              : `${t('not_filled')} ${t('count_fields', { count: emptyFieldsCount })}`
            }
          </div>
          {showFillMessage && <div>{t('fill_its_or_select_for_external_fill')}</div>}
        </div>
      )}
      {sides && (
        isShowSelect ? (
          <ButtonsContainer className={classNames(styles.buttons, styles.inline, 'fill-document-buttons')}> {/* class for onboarding */}
            <Button
              className={styles.button}
              viewStyle="secondary"
              onClick={handleHideSelect}
              shadow
            >
              {t('go_back')}
            </Button>
            <Button
              id="send_filling"
              className={styles.button}
              viewStyle="primary"
              disabled={!!emptyFieldsCount}
              onClick={() => setOwnersOpen(true)}
              shadow
            >
              {t('next')}
            </Button>
            <SelectOwnerModal
              open={isOwnersOpen}
              onClose={() => setOwnersOpen(false)}
              onSubmit={handleSubmitOwner}
              isLoading={isExternalFillLoading}
            />
          </ButtonsContainer>
        ) : (
          <ButtonsContainer className={classNames(styles.buttons, 'fill-document-buttons')}> {/* class for onboarding */}
            <LoadingButton
              id="save_filled_button_pdf"
              type="submit"
              className={styles.button}
              viewStyle="secondary"
              onClick={() => handleClickDownload('pdf')}
              disabled={!isFillingValid}
              isLoading={isDownloadLoading}
              shadow
              reversed
              data-testid="save_filled_button_pdf"
              icon={<ReactSVG src="/icons/file_types/pdf.svg" wrapper="span" />}
            >
              {t('download_pdf')}
            </LoadingButton>
            {showWordButton && <LoadingButton
              id="save_filled_button_docx"
              type="submit"
              className={styles.button}
              viewStyle="secondary"
              onClick={() => handleClickDownload('docx')}
              disabled={!isFillingValid}
              isLoading={isDownloadLoading}
              shadow
              reversed
              data-testid="save_filled_button_docx"
              icon={<ReactSVG src="/icons/file_types/docx.svg" wrapper="span" />}
            >
              {t('download_docx')}
            </LoadingButton>}
            {onClickRender && (
              <Button
                type="submit"
                className={styles.button}
                viewStyle="primary"
                onClick={handleClickRender}
                disabled={!isFillingValid}
                shadow
              >
                {t('test_render')}
              </Button>
            )}
            {/* <Checkbox
              viewStyle={butStyles.secondary}
              shadow
            >
              {t('save_to_my_documents')}
            </Checkbox> */}
            {onExternalFill && (
              <Button
                className={styles.button}
                viewStyle="secondary"
                onClick={handleShowSelect}
                disabled={isFillingValid}
                shadow
              >
                {t('send_to_fiiling')}
              </Button>
            )}
          </ButtonsContainer>
        )
      )}
    </div>
  );
};

export default FillDocument;
