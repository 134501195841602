import { lazy, Suspense, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { getFromTo } from '@forma/forma-ui-kit';
import { AdminLayout } from 'components/Layouts';
import PageTitle from 'components/PageTitle';
import ProductsServicesList from 'views/products/ProductsServicesList';

import { useGetProductsCategoriesQuery, useGetProductsQuery, useRemoveProductMutation } from 'store/products/productsApi';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { setOnboardingModal } from 'store/common/commonSlice';
import { selectOnboarding } from 'store/user/userSlice';

const ProductsImportModal = lazy(() => import('views/products/ProductsImportModal'));

const ProductsAndServices = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const onboarding = useAppSelector(selectOnboarding);
  const [ params, setParams ] = useState({});
  const [ searchParams, setSearchParams ] = useSearchParams();
  const page = searchParams.get('page');
  const [ isImportOpen, setImportOpen ] = useState<boolean>(false);

  const { data: products, isFetching } = useGetProductsQuery({ ...params, ...getFromTo(page) });
  const { data: categories } = useGetProductsCategoriesQuery();
  const [ removeProduct ] = useRemoveProductMutation();

  useEffect(() => {
    if (onboarding && !onboarding.products_list_tooltip && products) dispatch(setOnboardingModal('products_list_tooltip'));
    // eslint-disable-next-line
  }, [onboarding, products]);

  const handleChangePage = (page: number) => {
    setSearchParams(prev => ({ ...Object.fromEntries(prev.entries()), page: String(page) }));
  };

  const handleChangeSearch = (value: string) => {
    setParams(prev => ({ ...prev, nameOrVendorCode: value }));
    handleChangePage(1);
  };

  const handleChangeFilter = (params: { category: string }) => {
    setParams(prev => ({ ...prev, ...params }));
    handleChangePage(1);
  };

  const handleRemoveItem = (id: string) => {
    removeProduct(id);
  };

  return (
    <AdminLayout
      title={t('catalog_products_and_services')}
    >
      <PageTitle>{t('site_name') + ' – ' + t('catalog_products_and_services')}</PageTitle>
      <ProductsServicesList
        onChangeSearch={handleChangeSearch}
        onChangeFilter={handleChangeFilter}
        items={products?.products}
        filters={params}
        categories={categories}
        onClickImport={() => setImportOpen(true)}
        onRemoveItem={handleRemoveItem}
        pagination={{
          isLoading: isFetching,
          count: products?.count ?? 0,
          page: page,
          onChange: handleChangePage
        }}
      />

      <Suspense>
        <ProductsImportModal open={isImportOpen} onClose={setImportOpen} />
      </Suspense>
    </AdminLayout>
  );
};

export default ProductsAndServices;
