import { FC, useState } from 'react';
import { ReactSVG } from 'react-svg';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { format } from 'date-fns';
import { Tooltip, Checkbox, Table, TableCell, TableRow } from '@forma/forma-ui-kit';
import ModalChangeDocument from 'pages/MyDocuments/ModalChangeDocument';
import ModalSignedDocument from '../ModalSignedDocument';

import { useConfirmSignMutation } from 'store/templates/templatesApi';
import { IRendersItem } from 'interfaces/templates.interface';

import styles from './my-documents-list-table.module.css';

interface MyDocumentsListTableProps {
  items?: IRendersItem[]
}

const MyDocumentsListTable: FC<MyDocumentsListTableProps> = ({ items }) => {
  const { t } = useTranslation();
  const [ confirmSign ] = useConfirmSignMutation();
  const [ isModalDocOpen, setIsModalDocOpen ] = useState<boolean>(false);
  const [ isModalCheckOpen, setIsModalCheckOpen ] = useState<boolean>(false);
  const [ template, setTemplate ] = useState<IRendersItem|null>(null);

  const handleClickItem = (item: IRendersItem) => {
    setTemplate(item);
    setIsModalDocOpen(true);
  };

  const handleClickSigned = (item: IRendersItem) => {
    setTemplate(item);
    setIsModalCheckOpen(true);
  };

  const handleSignDocument = (id: string) => {
    confirmSign(id);
    setIsModalCheckOpen(false);
  };

  const handleModalClose = () => {
    setTemplate(null);
    setIsModalCheckOpen(false);
    setIsModalDocOpen(false);
  };

  return (
    <div className={classNames(styles.root, 'styled-scrollbar')}>
      <Table columns={[
        { children: t('my_documents_name'), className: styles.headerName },
        { children: t('my_documents_signed') },
        { children: t('my_documents_package'), className: styles.headerPack },
        { children: t('my_documents_date') }
      ]}>
        {!!items?.length && (
          items.map((item, index) => {
            const { id, name, at, pack, confirmed, owner, confirmer, confirmedAt, sides } = item;
            return <TableRow key={id} className={styles.list}>
              <TableCell className={styles.cellDocumentName} title={t('my_documents_name')} onClick={() => handleClickItem(item)}>
                <div className={styles.documentName}>
                  <div className={styles.wrapperFileI}>
                    <ReactSVG className={styles.fileI} src="/icons/explorer/file_i_s.svg" wrapper="span" />
                  </div>
                  <div>
                    <div>{name}</div>
                    <div className={styles.thinText}>{sides.length > 1 ? `${sides[0].name} ${t('and')} ${sides[1].name}`:`${sides[0].name}`}</div>
                  </div>
                </div>
              </TableCell>
              <TableCell className={styles.cellDocumentSigned} title={t('my_documents_signed')} onClick={() => handleClickSigned(item)}>
                <Tooltip position={['top center', 'bottom center']} control={<div><Checkbox id={id} name={'signed_' + id} checked={confirmed}/></div>}>
                  {(confirmed && confirmer && confirmedAt) ?
                    `${t('signed')} ${confirmer.name} ${format(new Date(confirmedAt), 'dd.MM.yyyy')}`
                    : `${t('responsible')} ${owner.name} ${format(new Date(at), 'dd.MM.yyyy')}`
                  }
                </Tooltip>
              </TableCell>
              <TableCell className={styles.cellPackage} title={t('my_documents_package')} onClick ={() => handleClickItem(item)}>
                {pack ? (
                  <div>
                    <ReactSVG className={styles.pack} src="/icons/explorer/templates_pack.svg" wrapper="span" />
                    {pack.name}
                  </div>
                ) : null}
              </TableCell>
              <TableCell className={styles.cellDate} title={t('my_documents_date')} onClick={() => handleClickItem(item)}>
                {format(new Date(at), 'dd.MM.yyyy HH:mm')}
              </TableCell>
            </TableRow>;
          })
        )}
      </Table>
      {!!template && (
        <ModalSignedDocument
          open={isModalCheckOpen}
          onClose={handleModalClose}
          item={template}
          onSigned={handleSignDocument}
        />
      )}
      {!!template && (
        <ModalChangeDocument
          open={isModalDocOpen}
          selectedTemplate={template}
          onClose={handleModalClose}
        />
      )}
    </div>
  );
};

export default MyDocumentsListTable;
