import { FC } from 'react';

import styles from './pack-side-item.module.css';
import classNames from 'classnames';

interface PackSideItemProps {
  name: string,
  templateName: string,
  isDeleted?: boolean,
  isDragging?: boolean
}

const PackSideItem: FC<PackSideItemProps> = ({ name, templateName, isDeleted, isDragging }) => {
  return (
    <div className={classNames(styles.root, isDragging && styles.dragging)}>
      <div className={styles.name}>
        {!isDeleted ? name : <span className={styles.deleted}>{name}</span>}
      </div>
      <div className={styles.templateName}>{templateName}</div>
    </div>
  );
};

export default PackSideItem;
